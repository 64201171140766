import { ComponentProps, forwardRef } from "react";
import { Avatar as MuiAvatar } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { setLightness } from "polished";
import styled from "styled-components";
import * as Colors from "src/colors";

type Props = ComponentProps<typeof MuiAvatar> & {
  color?: Colors.MainColors | Colors.MessageColors;
  hash?: string;
};

const StyledAvatar = styled(MuiAvatar)`
  &&& {
    background: ${(p) => p.$palette.light};
    color: ${(p) => p.$palette.main};
  }
`;

const Avatar = forwardRef((props: Props, ref) => {
  const { color, hash, ...baseProps } = props;
  const theme: any = useTheme();
  let palette = !color
    ? {
        main: Colors.neutral10,
        light: Colors.neutral60,
      }
    : theme.palette[color];

  if (hash) {
    const hashColor = Colors.colorHash(hash, Colors.PASTEL_COLORS);

    palette = {
      main: setLightness(0.4, hashColor),
      light: hashColor,
    };
  }

  return <StyledAvatar {...baseProps} $palette={palette} ref={ref} />;
});

export default Avatar;
