import { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import { Typography } from ".";

type Props = {
  children: ReactNode;
};

const MuiDialogTitleRoot = styled.div`
  flex: 0 0 auto;
  margin: 0;
  padding: 1.5rem 1.5rem 1rem;
`;

const DialogTitle = forwardRef((props: Props, ref) => (
  <MuiDialogTitleRoot className="MuiDialogTitle-root">
    <Typography variant="display2" ref={ref}>
      {props.children}
    </Typography>
  </MuiDialogTitleRoot>
));

export default DialogTitle;
