import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import packageJson from "src/../../package.json";

// Initialize datadog
export function init(config) {
  const env = process.env.LOOPUP_ENVIRONMENT?.toLowerCase();

  // Do not send logs or RUM sessions to Datadog in the test environment (CI pipeline) or for
  // environments not built on the CI pipeline
  if (env === "test" || !process.env.COMMIT_ID) {
    return;
  }

  // Datadog logs initialization
  datadogLogs.init({
    clientToken: config.datadogApi.clientToken,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });

  datadogLogs.addLoggerGlobalContext("service", "themis");
  datadogLogs.addLoggerGlobalContext(
    "environment",
    process.env.LOOPUP_ENVIRONMENT
  );

  // Datadog RUM (Real User Monitoring) initialization
  datadogRum.init({
    applicationId: config.datadogApi.rum.applicationId,
    clientToken: config.datadogApi.rum.clientToken,
    site: "datadoghq.com",
    service: "themis",
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: packageJson.version,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "allow",
  });

  datadogRum.startSessionReplayRecording();
}

/**
 * Identify the current user and tag them with data to help identify the user sessions.
 * @param currentUser User object returned from useAuth().
 */
export function identify(currentUser) {
  const env = process.env.LOOPUP_ENVIRONMENT?.toLowerCase();

  // Do not call setUser in the test environment (CI pipeline) or for environments not built in
  // the CI pipeline. Also do not call set user for any calls where currentUser is empty.
  if (env === "test" || !process.env.COMMIT_ID || !currentUser) {
    return;
  }

  datadogRum.setUser({
    id: currentUser.id,
    name: currentUser.name,
    email: currentUser.email,

    customer: {
      id: currentUser.customer.id,
      name: currentUser.customer.name,
    },
  });
}

// Call to trigger a page load event
export function page(path, currentUser) {
  const { name, customerId, roles } = currentUser || {};

  datadogLogs.logger.info(`page ${path}`, {
    loopup: {
      sherwood: {
        account: {
          guid: customerId,
        },
        user: {
          name,
          roles,
        },
      },
      app_version: process.env.COMMIT_ID,
    },
  });
}

/**
 * Logs a fatal error event.
 * @param msg Message string.
 * @param attrs Attributes to attach to the logged event.
 */
export function fatal(msg, attrs = {}) {
  datadogLogs.logger.fatal(msg, attrs);
}

/**
 * Log an error event.
 * @param msg Message string.
 * @param attrs Attributes to attach to the logged event.
 */
export function error(msg, attrs = {}) {
  datadogLogs.logger.error(msg, attrs);
}

/**
 * Log a warning event.
 * @param msg Message string.
 * @param attrs Attributes to attach to the logged event.
 */
export function warn(msg, attrs = {}) {
  datadogLogs.logger.warn(msg, attrs);
}

/**
 * Log an info event.
 * @param msg Message string.
 * @param attrs Attributes to attach to the logged event.
 */
export function info(msg, attrs = {}) {
  datadogLogs.logger.info(msg, attrs);
}

/**
 * Log a debug event.
 * @param msg Message string.
 * @param attrs Attributes to attach to the logged event.
 */
export function debug(msg, attrs = {}) {
  datadogLogs.logger.debug(msg, attrs);
}
