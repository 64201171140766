import styled from "styled-components";
import * as Colors from "src/colors";
import { hoverBackgroundWipe, hoverMenuFadeIn } from "./Keyframes";

const HoverItem = styled.div`
  align-items: center;
  background-color: ${Colors.hoverActivityColor};
  border-radius: 0 0.25rem 0.25rem 0;
  box-shadow: 0.25rem 0 0.25rem -0.1rem ${Colors.dropShadowColor},
    0 0.25rem 0.25rem -0.25rem ${Colors.dropShadowColor},
    0 -0.25rem 0.25rem -0.25rem ${Colors.dropShadowColor};
  padding: 0.75rem 1rem;
  position: absolute;
  left: 3.5rem;
  top: 0;
  bottom: 0;
  width: 11rem;
  display: none;
  animation: 350ms ${hoverMenuFadeIn} ease-out;

  &::after {
    background-color: ${Colors.neutral100};
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    right: 0;
    z-index: 1;
    animation: 350ms ${hoverBackgroundWipe} cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  & > * {
    z-index: 2;
  }

  &.highlighted,
  .text:hover {
    color: ${Colors.activityColor};
  }
`;

export default HoverItem;
