import { ComponentProps, forwardRef } from "react";
import { TextField as MuiTextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { MainColors } from "src/colors";

type Props = ComponentProps<typeof MuiTextField> & {
  color?: MainColors;
  fontSize?: string;
  padding?: string;
};

const StyledTextField = styled(MuiTextField)`
  fieldset {
    border-color: ${(p) => p.$palette.main};
  }

  .MuiInputBase-input {
    font-size: ${(p) => p.$fontSize};
    padding-top: ${(p) => p.$padding};
    padding-bottom: ${(p) => p.$padding};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => p.$palette.dark};
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => p.$errorColor};
  }

  .MuiOutlinedInput-root:not(.Mui-disabled):hover
    .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => !p.disabled && p.$palette.dark};
  }
`;

const TextField = forwardRef((props: Props, ref) => {
  const { color, fontSize, padding, ...baseProps } = props;
  const theme: any = useTheme();
  const palette = !color ? theme.palette.primary : theme.palette[color];
  const errorColor = theme.palette.error.main;

  return (
    <StyledTextField
      {...baseProps}
      ref={ref}
      $palette={palette}
      $errorColor={errorColor}
      $fontSize={fontSize}
      $padding={padding}
    />
  );
});

export default TextField;
