import { ComponentProps } from "react";
import { Tab as MuiTab } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import * as Colors from "src/colors";
import { Theme } from "src/theme";
import { lighten } from ".";

type Props = ComponentProps<typeof MuiTab>;

const CtapTab = styled(MuiTab)`
  && {
    background-color: ${(props) =>
      props.$selected
        ? Colors.neutral100
        : lighten(Colors.lightenedActivityColor, 0.7)};
    border: 1px solid ${Colors.neutral60};
    border-bottom: 0;
    opacity: 1;
  }
`;

const InternalTab = styled(MuiTab)`
  && {
    /* padding: 0; */
    /* border-right: 1px solid ${Colors.neutral80}; */
  }
`;

export default function Tab(props: Props) {
  const theme = useTheme<Theme>();
  const { children, selected, ...baseProps } = props;

  if (theme.variant === "internal") {
    return <InternalTab {...baseProps} label={children} $selected={selected} />;
  }

  return <CtapTab {...baseProps} label={children} $selected={selected} />;
}
