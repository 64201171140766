import { ComponentProps } from "react";
import { Button as MuiButton } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { MainColors, MessageColors, neutral100, neutral90 } from "src/colors";
import * as Colors from "src/colors";

export type Variant = "contained" | "outlined" | "text";

type Props = Omit<ComponentProps<typeof MuiButton>, "color"> & {
  color?: MainColors | MessageColors | "default";
};

const ContainedButton = styled(MuiButton)`
  &&& {
    background-color: ${(p) => p.$palette.main};
    border: 1px solid ${(p) => p.$palette.main};
    color: ${(p) => p.$palette.contrastText};
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: ${(p) => p.$palette.light};
    }

    &:focus {
      background-color: ${(p) => p.$palette.light};
    }

    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
      border-color: ${Colors.neutral60};
    }
  }
`;

const OutlinedButton = styled(MuiButton)`
  &&& {
    background-color: ${neutral90};

    &:not(.Mui-disabled) {
      background-color: ${neutral100};
      color: ${(p) => p.$palette.main};
      border-color: ${(p) => p.$palette.main};

      &:hover {
        color: ${(p) => p.$palette.light};
        border-color: ${(p) => p.$palette.light};

        svg {
          fill: ${(p) => p.$palette.light};
        }
      }

      &:focus {
        color: ${(p) => p.$palette.light};
        border-color: ${(p) => p.$palette.light};
      }
    }
  }
`;

const TextButton = styled(MuiButton)`
  &&&:not(.Mui-disabled) {
    color: ${(p) => p.$palette.main};

    &:focus {
      text-decoration: none;
      background-color: rgba(51, 51, 51, 0.04);
    }
  }
`;

export default function Button(props: Props) {
  const theme = useTheme();
  const { color, ...baseProps } = props;

  const palette =
    color === "default" || !color
      ? theme.palette.primary
      : theme.palette[color];

  switch (baseProps.variant) {
    case "contained":
      return (
        <ContainedButton {...baseProps} $palette={palette} disableFocusRipple />
      );
    case "outlined":
      return (
        <OutlinedButton {...baseProps} $palette={palette} disableFocusRipple />
      );
    case "text":
    default:
      return (
        <TextButton {...baseProps} $palette={palette} disableFocusRipple />
      );
  }
}
