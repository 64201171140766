import assignment from "assignment";
import { useEffectOnce } from "usehooks-ts";
import { createTheme } from "@material-ui/core/styles";
import { useAuth } from "@redwoodjs/auth";
import { Link, routes } from "@redwoodjs/router";
import { Toaster } from "@redwoodjs/web/toast";
import { DeepPartial } from "lib/web/types";
import {
  Box,
  Container,
  Divider,
  Grid,
  ThemeProvider,
} from "src/components/material-ui";
import * as Colors from "src/colors";
import { Theme } from "src/theme";
import * as CustomersCache from "src/utils/contexts/customersCache";
import SessionTimeoutModal from "src/components/SessionTimeoutModal";

const internalTheme: DeepPartial<Theme> = {
  variant: "internal",
  palette: {
    text: {
      secondary: Colors.neutral50,
    },
  },
};

const DataCacheInit = () => {
  const customersInfo = CustomersCache.useCustomersInfo();

  useEffectOnce(() => {
    customersInfo.init();
  });

  return null;
};

const InternalLayout = ({ children }) => {
  const { currentUser, hasRole } = useAuth();

  return (
    <ThemeProvider
      theme={(theme) => createTheme(assignment(theme, internalTheme))}
    >
      <CustomersCache.Provider>
        {currentUser && <DataCacheInit />}

        <Toaster
          position="top-right"
          toastOptions={{
            custom: { duration: 5000 },
            error: { duration: 20000 },
            success: { duration: 5000 },
          }}
        />

        <Container maxWidth="lg">
          <Box m={4}>
            <Grid container spacing={4}>
              <Grid item>
                <Link to={routes.home()}>Back to CTAP</Link>
              </Grid>
              {(hasRole("ctap:numbers-tool") ||
                hasRole("role:internal_support")) && (
                <Grid item>
                  <Link to={routes.numbersToolHome()}>Numbers Tool</Link>
                </Grid>
              )}
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Link to={routes.debug()}>Debug</Link>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Link to={routes.internalFeatures()}>Features</Link>
              </Grid>
              <Grid item>
                <Link to={routes.internalRoles()}>Roles</Link>
              </Grid>
              <Grid item>
                <Link to={routes.internalCustomers()}>Customers</Link>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Link to={routes.internalMasquerade()}>Masquerade</Link>
              </Grid>
            </Grid>
          </Box>
          <Box m={4}>
            <Divider />
          </Box>
          <SessionTimeoutModal />
          {children}
        </Container>
      </CustomersCache.Provider>
    </ThemeProvider>
  );
};

export default InternalLayout;
