import { ComponentProps, ReactNode, forwardRef } from "react";
import { MenuItem as MuiMenuItem } from "@material-ui/core";
import styled from "styled-components";

type Props = ComponentProps<typeof MuiMenuItem> & {
  children: ReactNode;
  disabled?: boolean;
};

const StyledMenuItem = styled(MuiMenuItem)`
  &&& {
    &.MuiMenuItem-root {
      font-weight: 400;
      line-height: 1.125rem;
      font-size: 0.875rem;
    }
  }
`;

const MenuItem = forwardRef((props: Props, ref) => {
  const { children, ...baseProps } = props;

  return (
    <StyledMenuItem {...baseProps} ref={ref}>
      {children}
    </StyledMenuItem>
  );
});

export default MenuItem;
