import { ComponentProps } from "react";
import { Divider as MuiDivider } from "@material-ui/core";
import styled from "styled-components";

type Props = ComponentProps<typeof MuiDivider> & {
  marginTop?: string;
};

const StyledDivider = styled(MuiDivider)`
  &&& {
    margin-top: ${(p) => p.$marginTop};
  }
`;

export default function Divider(props: Props) {
  const { marginTop, ...baseProps } = props;

  return <StyledDivider {...baseProps} $marginTop={marginTop} />;
}
