import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as idb from "idb-keyval";
import { nanoid } from "nanoid";
import { useEffectOnce } from "usehooks-ts";

type Data = {
  data: Record<string, any>;
  remove: (id: string) => void;
  setFactory: (id: string) => (value: any) => void;
};

const globalContext = createContext<Data>({
  data: {},
  remove: () => {},
  setFactory: () => () => {},
});

const idbStore = idb.createStore("hooks-session-state", "data-store");

export const Provider = ({ children }) => {
  const [data, setData] = useState<Record<string, any>>({});

  useEffectOnce(() => {
    idb.entries(idbStore).then((values) => {
      setData(Object.fromEntries(values || []));
    });
  });

  const remove = useCallback(
    (id: string) => {
      idb.del(id, idbStore);
      setData(({ [id]: _, ...state }) => ({ ...state }));
    },
    [setData]
  );

  const setFactory = useCallback(
    (id: string) => (input: any) => {
      const value = input instanceof Function ? input(data[id]) : input;

      idb.set(id, value, idbStore);

      setData((state) => ({ ...state, [id]: value }));
    },
    [setData, data]
  );

  const value = useMemo(
    () => ({
      data,
      setFactory,
      remove,
    }),
    [data, remove, setFactory]
  );

  return (
    <globalContext.Provider value={value}>{children}</globalContext.Provider>
  );
};

/**
 * Functions much the same as useState however state is persisted between SPA page loads. This
 * is useful for passing data between pages that don't have a hard reload.
 */
export const useSessionState = ({
  id: inputId,
  prefix = "",
}: { id?: string; prefix?: string } = {}) => {
  const context = useContext(globalContext);
  const [id, setId] = useState(inputId);

  if (context === undefined) {
    throw new Error(
      "Session State: useContext was used outside of its provider"
    );
  }

  // Automatically generate an Id if none is provided, makes it convenient to use as Id generation
  // only has to be done once here if the Id is not important.
  useEffect(() => {
    if (!id) {
      setId(`${prefix}${nanoid(6)}`);
    }
  }, [id]);

  return [context.data[id], context.setFactory(id), id, context.remove];
};

export const useAllSessionState = () => {
  const context = useContext(globalContext);

  if (context === undefined) {
    throw new Error(
      "Session State: useContext was used outside of its provider"
    );
  }

  return context.data;
};

/** Returns all the available session state ids that are stored */
export const useSessionStateIds = () => {
  const context = useContext(globalContext);

  if (context === undefined) {
    throw new Error(
      "Session State: useContext was used outside of its provider"
    );
  }

  return Object.keys(context.data);
};
