import { FormControlLabel as MuiFormControlLabel } from "@material-ui/core";
import styled from "styled-components";
import * as Colors from "src/colors";

const FormControlLabel = styled(MuiFormControlLabel)`
  &&& {
    color: ${Colors.neutral10};
    margin: 0.25rem 0;
  }
`;

export default FormControlLabel;
