import * as Gateway from "./gateway";
function customerHasEntitlement(customer) {
  if (!customer || !customer.entitlements || customer.entitlements.length < 1) {
    return false;
  }
  return customer.entitlements.reduce((acc, entitlement) => acc || Boolean(entitlement.product && entitlement.product.type === "CloudTelephony"), false);
}
function findAuthenticationMethod(record, authType) {
  if (!record) {
    return null;
  }
  if ("email" in record) {
    return record.authenticationMethods.find((a) => a.authenticationMethodType === authType);
  }
  return record.authenticationMethods.find((a) => a.type === authType);
}
function findCustomerAuthenticationMethod(customer, authType) {
  var _a;
  if (!customer) {
    return null;
  }
  return (_a = customer == null ? void 0 : customer.authenticationMethods) == null ? void 0 : _a.find((a) => a.authenticationMethodType === authType);
}
function findLoginAuthenticationMethod(login, authType) {
  var _a;
  if (!login) {
    return null;
  }
  return (_a = login == null ? void 0 : login.authenticationMethods) == null ? void 0 : _a.find((a) => a.authenticationMethodType === authType);
}
function defaultInviteAuthMethod(customer) {
  const passwordAuth = findCustomerAuthenticationMethod(customer, "Password");
  const azureAuth = findCustomerAuthenticationMethod(customer, "MicrosoftAzureAd");
  if (passwordAuth && azureAuth) {
    return Gateway.AuthenticationMethod.MicrosoftAzureAd;
  } else if (azureAuth) {
    return Gateway.AuthenticationMethod.MicrosoftAzureAd;
  } else if (passwordAuth) {
    return Gateway.AuthenticationMethod.Password;
  }
  return Gateway.AuthenticationMethod.None;
}
export {
  customerHasEntitlement,
  defaultInviteAuthMethod,
  findAuthenticationMethod,
  findCustomerAuthenticationMethod,
  findLoginAuthenticationMethod
};
