import GatewayClient from "lib/web/gateway";
import { t } from "src/i18n";
import {
  buildInsight,
  buildView,
  createDataCache,
} from "src/utils/contexts/dataCache";
import { hasSiteWriteAccess } from "../hooks/scope";

export type Address = {
  street1?: string;
  street2?: string;
  city?: string;
  stateProvince?: string;
  zipPostalCode?: string;
  /** 3 letter country code */
  country?: string;
  /** Localized country name derived from the country field. */
  countryName?: string;
};

export type SiteRow = {
  id: string;
  name?: string;
  address: Address;
  emergencyAddress: Address;
  numbers: {
    assigned: number;
    unassigned: number;
    total: number;
  };
  disabled: boolean;
};

export async function fetchData(
  { gateway }: { gateway: GatewayClient },
  pageSize: number,
  pageNumber: number
): Promise<{ hasNextPage: boolean; rows: SiteRow[] }> {
  const results = await gateway.listSites(
    pageNumber - 1,
    pageSize,
    undefined,
    undefined,
    {}
  );

  const login = await gateway.getV2Login();
  const scopedRoles = login.login?.accessControlMap;

  return {
    hasNextPage: results.paginationInfo?.hasNextPage,
    rows: results.sitesList.map((site) => ({
      id: site.id,
      name: site.name,
      address: {
        street1: site.address1?.value,
        street2: site.address2?.value,
        city: site.city?.value,
        stateProvice: site.stateProvince?.value,
        zipPostalCode: site.zipPostalCode?.value,
        country: site.countryCode?.value,
      },
      emergencyAddress: {
        street1: site.emergencyAddress1?.value,
        street2: site.emergencyAddress2?.value,
        city: site.emergencyCity?.value,
        stateProvice: site.emergencyStateProvince?.value,
        zipPostalCode: site.emergencyZipPostalCode?.value,
        country: site.emergencyCountryCode?.value,
      },
      numbers: {
        assigned: site.assignedNumbers,
        unassigned: site.availableNumbers,
        total: site.totalNumbers,
      },
      disabled: !hasSiteWriteAccess(scopedRoles, site.id),
    })),
  };
}

const sites = createDataCache<SiteRow>("sites", fetchData, {
  first: 15,
  all: 25,
});

sites.registerComputed("address.countryName", (row) => {
  row.address.countryName = t(`country_${row.address.country}`);
});

sites.registerInsight(
  "city_options",
  buildInsight<SiteRow>("property_enumeration")("address.city")
);
sites.registerInsight(
  "country_options",
  buildInsight<SiteRow>("property_enumeration")("address.country")
);
sites.registerInsight(
  "country_countryName_options",
  buildInsight<SiteRow>("property_pairs")(
    "address.country",
    "address.countryName"
  )
);

sites.registerView(
  "orderby_name",
  buildView<SiteRow>("single_property_ordering")("name")
);
sites.registerView(
  "orderby_city",
  buildView<SiteRow>("single_property_ordering")("address.city")
);
sites.registerView(
  "orderby_country",
  buildView<SiteRow>("single_property_ordering")("address.countryName")
);

export const __cache__ = sites;
export const Provider = sites.provider;

export const registerSitesInsight = sites.registerInsight;
export const registerSitesView = sites.registerView;
export const useSites = sites.useData;
export const useSitesInfo = sites.useInfo;
export const useSitesInsight = sites.useInsight;
