var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import assignment from "assignment";
const DEFAULT_CONFIG = {
  boxApi: {
    templateFolderName: "Provisioning Template",
    templateFileName: "Provisioning.xlsx"
  },
  datadogApi: {
    rum: {
      applicationId: "eef1f550-3edc-4c45-a747-305dcee8ff99",
      clientToken: "pubbdc5f72f2a0488c4d89d28c58f7c7780"
    }
  },
  teamsConnectionApp: {
    appId: "ef6bca39-c49f-4d46-9f41-b2404d80c94c"
  }
};
const DOCKER_CONFIG = {
  domain: ".loopup.dev",
  ctmpUrl: "https://ctmp.loopup.dev",
  adminUrl: "https://admin.loopup.dev",
  gateway: {
    url: "https://ct-gateway.loopup.dev"
  },
  hecateUrl: "https://identity.loopup.dev",
  numbers: {
    url: "https://numbers.loopup.dev"
  },
  docusign: {
    oAuthUrl: "account-d.docusign.com",
    baseUrl: "https://demo.docusign.net/restapi",
    appUrl: "https://appdemo.docusign.com",
    template: {
      GBR: "1899d345-f8dd-4de9-a95a-76790c3d0f39",
      USA: "dd2f4dd7-ec94-4241-b79e-8a330fb1d281",
      CAN: "2952d19f-cebe-4747-8243-a5ee4cf91c9a",
      FRA: "0ef97eab-58b7-47e5-b879-4f175d8836b8",
      DEU: "79fba372-2c3f-4cc8-8731-83fd4a383e55",
      ESP: "1ed9b30f-dfb9-43d2-8f3c-172aedd815f8"
    }
  },
  downloadInvoice: {
    baseUrl: "https://ct-invoice.engr.loopup.com/api/v1/invoiceArtifacts/"
  },
  operatorConnect: {
    url: "https://usazw-ewappctocs01.azurewebsites.net"
  }
};
const ENGR_CONFIG = {
  domain: ".engr.loopup.com",
  ctmpUrl: "https://ctmp.engr.loopup.com",
  adminUrl: "https://admin.engr.loopup.com",
  gateway: {
    url: "https://ct-gateway.engr.loopup.com"
  },
  hecateUrl: "https://identity.engr.loopup.com",
  numbers: {
    url: "https://usazw-ewappnapi01.azurewebsites.net"
  },
  docusign: {
    oAuthUrl: "account-d.docusign.com",
    baseUrl: "https://demo.docusign.net/restapi",
    appUrl: "https://appdemo.docusign.com",
    template: {
      GBR: "1899d345-f8dd-4de9-a95a-76790c3d0f39",
      USA: "dd2f4dd7-ec94-4241-b79e-8a330fb1d281",
      CAN: "2952d19f-cebe-4747-8243-a5ee4cf91c9a",
      FRA: "0ef97eab-58b7-47e5-b879-4f175d8836b8",
      DEU: "79fba372-2c3f-4cc8-8731-83fd4a383e55",
      ESP: "1ed9b30f-dfb9-43d2-8f3c-172aedd815f8"
    }
  },
  downloadInvoice: {
    baseUrl: "https://ct-invoice.engr.loopup.com/api/v1/invoiceArtifacts/"
  },
  operatorConnect: {
    url: "https://usazw-ewappctocs01.azurewebsites.net"
  }
};
const TEST_CONFIG = __spreadValues({}, ENGR_CONFIG);
const LAB_CONFIG = {
  domain: ".lab.loopup.com",
  ctmpUrl: "https://ctmp.lab.loopup.com",
  adminUrl: "https://admin.lab.loopup.com",
  gateway: {
    url: "https://ct-gateway.lab.loopup.com"
  },
  hecateUrl: "https://identity.lab.loopup.com",
  numbers: {
    url: "https://numbers.lab.loopup.com"
  },
  docusign: {
    oAuthUrl: "account-d.docusign.com",
    baseUrl: "https://demo.docusign.net/restapi",
    appUrl: "https://appdemo.docusign.com",
    template: {
      GBR: "1899d345-f8dd-4de9-a95a-76790c3d0f39",
      USA: "dd2f4dd7-ec94-4241-b79e-8a330fb1d281",
      CAN: "2952d19f-cebe-4747-8243-a5ee4cf91c9a",
      FRA: "0ef97eab-58b7-47e5-b879-4f175d8836b8",
      DEU: "79fba372-2c3f-4cc8-8731-83fd4a383e55",
      ESP: "1ed9b30f-dfb9-43d2-8f3c-172aedd815f8"
    }
  },
  downloadInvoice: {
    baseUrl: "https://ct-invoice.lab.loopup.com/api/v1/invoiceArtifacts/"
  },
  operatorConnect: {
    url: "https://ct-ocservice.lab.loopup.com"
  }
};
const PROD_CONFIG = {
  domain: ".loopup.com",
  ctmpUrl: "https://ctmp.loopup.com",
  adminUrl: "https://admin.loopup.com",
  gateway: {
    url: "https://ct-gateway.loopup.com"
  },
  hecateUrl: "https://identity.loopup.com",
  numbers: {
    url: "https://numbers.loopup.com"
  },
  docusign: {
    oAuthUrl: "account.docusign.com",
    baseUrl: "https://www.docusign.net/restapi",
    appUrl: "https://app.docusign.com",
    template: {
      GBR: "cef71963-f193-41b5-a2e9-7a5b5d7040b8",
      USA: "99a7c746-697f-4add-a7ce-629f3b03a01f",
      CAN: "399853ba-7cea-4386-b137-7b65466a2cb2",
      FRA: "2be92908-7772-4f0b-8621-4c2e1da3abbb",
      DEU: "13ea8132-cafe-422d-b1bb-682d13f1ea45",
      ESP: "7a8915df-7924-49bc-9bbf-26f161ec34c8"
    }
  },
  downloadInvoice: {
    baseUrl: "https://ct-invoice.loopup.com/api/v1/invoiceArtifacts/"
  },
  operatorConnect: {
    url: "https://ct-ocservice.loopup.com"
  }
};
function getConfig() {
  var _a;
  const environmentConfig = {
    blobStorage: {
      accountName: process.env.STORAGE_ACCOUNT_NAME,
      accountKey: process.env.STORAGE_ACCOUNT_KEY,
      connectionString: process.env.STORAGE_ACCOUNT_CONNECTION_STRING,
      profilePhotoContainerName: process.env.STORAGE_ACCOUNT_CONTAINER_NAME_PROFILE_PHOTOS,
      documentsContainerName: process.env.STORAGE_ACCOUNT_CONTAINER_NAME_DOCUMENTS,
      expirationTime: process.env.STORAGE_ACCOUNT_DOWNLOAD_LINK_EXPIRATION_TIME_IN_SECONDS
    },
    boxApi: {
      boxAppSettings: {
        clientID: process.env.CLIENT_ID,
        clientSecret: process.env.CLIENT_SECRET,
        appAuth: {
          publicKeyID: process.env.PUBLIC_KEY_ID,
          privateKey: (_a = process.env.PRIVATE_KEY) == null ? void 0 : _a.replace(/\\n/g, "\n"),
          passphrase: process.env.PASSPHRASE
        }
      },
      enterpriseID: process.env.ENTERPRISE_ID,
      userID: process.env.USER_ID,
      rootFolder: process.env.ROOT_FOLDER
    },
    datadogApi: {
      apiKey: process.env.DATADOG_API_KEY,
      clientToken: process.env.DATADOG_CLIENT_TOKEN
    },
    hecateAccessToken: process.env.HECATE_ACCESS_TOKEN,
    hecateClientId: process.env.HECATE_CLIENT_ID,
    hecateClientSecret: process.env.HECATE_CLIENT_SECRET,
    numbers: {
      accessToken: process.env.NUMBERS_ACCESS_TOKEN
    },
    downloadInvoice: {
      accessToken: process.env.KANSYS_DOWNLOAD_INVOICE_ACCESS_TOKEN
    },
    operatorConnect: {
      accessToken: process.env.OPERATOR_CONNECT_SERVICE_ACCESS_TOKEN
    },
    mixpanelApi: {
      appToken: process.env.MIXPANEL_APP_TOKEN
    },
    awsAccountId: process.env.AWS_ACCOUNT_ID,
    awsAccessKeyId: process.env.AWS_ACCESS_KEY_ID,
    awsSecretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
    awsDefaultRegion: process.env.AWS_DEFAULT_REGION,
    awsQsSessionTtlInMinutes: process.env.AWS_QS_SESSION_TTL_IN_MINUTES,
    awsQsDashboardId: process.env.AWS_QS_DASHBOARD_ID,
    awsQsDemoDashboardId: process.env.AWS_QS_DEMO_DASHBOARD_ID,
    awsQsNamespace: process.env.AWS_QS_NAMESPACE,
    docusign: {
      accountId: process.env.DOCUSIGN_ACCOUNT_ID,
      clientId: process.env.DOCUSIGN_CLIENT_ID,
      userId: process.env.DOCUSIGN_USER_ID,
      rsaKey: process.env.DOCUSIGN_RSA_KEY
    },
    demoAccountCustomerId: process.env.DEMO_ACCOUNT_CUSTOMER_ID,
    awsKansysDashboardId: process.env.AWS_QS_KANSYS_REPORT_DASHBOARD_ID,
    teamsConnectionApp: {
      appId: process.env.TEAMS_CONNECTION_APP_ID,
      redirectUrl: process.env.TEAMS_CONNECTION_APP_REDIRECT_URL
    },
    sessionTimeout: process.env.SESSION_TIMEOUT_IN_SECONDS
  };
  switch (process.env.LOOPUP_ENVIRONMENT) {
    case "Docker": {
      return assignment({}, DEFAULT_CONFIG, DOCKER_CONFIG, environmentConfig);
    }
    case "Engineering": {
      if (false) {
        return assignment({}, DEFAULT_CONFIG, ENGR_CONFIG, environmentConfig, {
          adminUrl: "https://admin-dev.engr.loopup.com"
        });
      }
      return assignment({}, DEFAULT_CONFIG, ENGR_CONFIG, environmentConfig, {});
    }
    case "Lab": {
      if (false) {
        return assignment({}, DEFAULT_CONFIG, LAB_CONFIG, environmentConfig, {
          adminUrl: "https://admin-dev.lab.loopup.com"
        });
      }
      return assignment({}, DEFAULT_CONFIG, LAB_CONFIG, environmentConfig, {});
    }
    case "Production":
      return assignment({}, DEFAULT_CONFIG, PROD_CONFIG, environmentConfig, {});
    case "Test":
      return assignment({}, DEFAULT_CONFIG, TEST_CONFIG, environmentConfig, {});
    default:
      throw new Error(`Invalid environment "${process.env.LOOPUP_ENVIRONMENT}"`);
  }
}
export {
  DEFAULT_CONFIG,
  DOCKER_CONFIG,
  ENGR_CONFIG,
  LAB_CONFIG,
  PROD_CONFIG,
  TEST_CONFIG,
  getConfig
};
