import { createTheme } from "@material-ui/core/styles";
import * as Colors from "src/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primaryColor,
      light: Colors.primaryLightColor,
      dark: Colors.primaryDarkColor,
      contrastText: Colors.neutral100,
    },
    secondary: {
      main: Colors.secondaryColor,
      light: Colors.secondaryLightColor,
      dark: Colors.secondaryDarkColor,
      contrastText: Colors.neutral100,
    },
    tertiary: {
      main: Colors.tertiaryColor,
      light: Colors.tertiaryLightColor,
      dark: Colors.tertiaryDarkColor,
      contrastText: Colors.neutral100,
    },
    quaternary: {
      main: Colors.quaternaryColor,
      light: Colors.quaternaryLightColor,
      dark: Colors.quaternaryDarkColor,
      contrastText: Colors.neutral100,
    },
    neutral: {
      main: Colors.neutral30,
      light: Colors.neutral60,
      dark: Colors.neutral10,
      contrastText: Colors.neutral100,
    },
    error: {
      main: Colors.errorColor,
    },
    warning: {
      main: Colors.warningColor,
    },
    info: {
      main: Colors.activityColor,
    },
    success: {
      main: Colors.successColor,
    },
    text: {
      primary: Colors.neutral10,
      secondary: Colors.neutral100,
      disabled: Colors.neutral50,
    },
    background: {
      default: Colors.secondaryColor,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  props: {
    MuiInputBase: {
      margin: "dense",
    },
    MuiTypography: {
      variantMapping: {},
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: "proxima-nova, sans-serif",
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    lineHeight: 1.2,
    // Titles
    display1: { fontWeight: 300, fontSize: "2rem", lineHeight: "2.25rem" },
    display2: { fontWeight: 400, fontSize: "1.75rem", lineHeight: "2rem" },
    display3: { fontWeight: 500, fontSize: "1.25rem", lineHeight: "1.5rem" },
    // Body
    body1: { fontWeight: 400, fontSize: "1rem", lineHeight: "1.25rem" },
    body2: { fontWeight: 400, fontSize: "0.875rem", lineHeight: "1.125rem" },
    body3: { fontWeight: 400, fontSize: "0.75rem", lineHeight: "1rem" },
    button: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0, //    extra-small
      sm: 820, //  small
      md: 1150, // medium
      lg: 1280, // large
      xl: 1920, // extra-large
    },
  },
  overrides: {
    MuiMenu: {
      paper: {
        maxHeight: "18rem",
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: Colors.primaryColor,
      },
    },
    MuiTypography: {
      root: {
        "&.error-message": {
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
        },
      },
    },
    // Table Styles
    MuiTableContainer: {
      root: {
        overflowX: "hidden",
      },
    },
    MuiTableRow: {
      root: {
        "&$hover": {
          "&:hover": {
            backgroundColor: Colors.neutral70,
            "& .MuiSvgIcon-root": {
              fill: Colors.neutral70,
            },
          },
        },
      },
    },
    MuiTablePagination: {
      caption: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
      root: {
        overflow: "hidden",
      },
      menuItem: {
        fontSize: "0.875rem",
        fontWeight: 400,
        color: Colors.primaryColor,
        "&.Mui-selected": {
          backgroundColor: Colors.primaryColor,
          color: Colors.neutral100,
          "&:hover": {
            backgroundColor: Colors.primaryColor,
            color: Colors.neutral100,
          },
        },
      },
      selectIcon: {
        fontSize: "1.1rem",
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          color: Colors.secondaryColor,
        },
      },
    },
    MuiButton: {
      root: {
        height: "2.25rem",
        padding: "1rem",
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: Colors.primaryColor,
          opacity: 0.9,
        },
      },
      containedSecondary: {
        backgroundColor: Colors.neutral30,
        color: Colors.neutral100,
        "&:hover": {
          backgroundColor: Colors.neutral30,
          opacity: 0.9,
        },
      },
    },
    MuiSelect: {
      root: {
        fontSize: "0.875rem",
        fontWeight: 600,
      },
      select: {
        "&:focus": {
          backgroundColor: Colors.neutral100,
        },
      },
    },
    MuiLink: {
      root: {
        color: Colors.activityColor,
        cursor: "pointer",
      },
    },
    // Form styles
    MuiFormLabel: {
      root: {
        color: Colors.neutral40,
        fontSize: "0.875rem",
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: "0",
        alignItems: "flex-end",
      },
      label: {
        fontSize: "1rem",
        fontWeight: 400,
      },
    },
    MuiInput: {
      root: {
        "&$error": {
          "& .MuiSelect-icon": {
            color: Colors.errorColor,
          },
        },
        "&$focused": {
          "& .MuiSelect-icon": {
            color: Colors.tertiaryColor,
          },
        },
      },
    },
    MuiRadio: {
      root: {
        color: Colors.neutral50,
        padding: 0,
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem",
      },
    },
    MuiGrid: {
      root: {
        "& .MuiSelect-root": {
          fontSize: "1rem",
          fontWeight: 400,
        },
        "& .MuiSelect-selectMenu": {
          minHeight: "1.25rem",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "1.25rem",
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
        },
      },
      root: {
        "&.placeholder": {
          "& .MuiSelect-root": {
            opacity: 0.42,
          },
        },
        "&.Mui-error": {
          "&.Mui-focused": {
            "& .MuiSelect-icon": {
              color: Colors.errorColor,
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      root: {
        cursor: "pointer",
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        "& .MuiSvgIcon-root": {
          fill: Colors.neutral100,
          transition: "none",
          fontSize: 18,
        },
        "&$checked,&.MuiCheckbox-indeterminate .MuiIconButton-label": {
          position: "relative",
          zIndex: 0,
          border: `1px solid ${Colors.neutral30}`,
          width: 14,
          height: 14,
          borderRadius: 0,
          "&:after": {
            content: '""',
            left: 1,
            top: 1,
            height: 12,
            width: 12,
            position: "absolute",
            backgroundColor: Colors.primaryColor,
            zIndex: -1,
            borderColor: "transparent",
          },
        },
        "&:not($checked):not(.MuiCheckbox-indeterminate) .MuiIconButton-label":
          {
            position: "relative",
            zIndex: 0,
            border: `1px solid ${Colors.neutral30}`,
            width: 14,
            height: 14,
            borderRadius: 0,
            "&:after": {
              content: '""',
              left: 1,
              top: 1,
              height: 12,
              width: 12,
              position: "absolute",
              backgroundColor: "transparent",
              zIndex: -1,
              borderColor: "transparent",
            },
          },
      },
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          color: Colors.primaryColor,
        },
        '&[aria-disabled="true"]': {
          backgroundColor: Colors.neutral100,
        },
      },
    },
  },
});

export default theme;
