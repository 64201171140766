import { useState } from "react";
import styled from "styled-components";
import { Box, ClickAwayListener } from "src/components/material-ui";
import * as Colors from "src/colors";
import Hamburger from "./Hamburger";
import { SideBarContext, SideBarContextType } from "./SideBarContext";

const SideBarContainer = styled.div`
  background-color: ${Colors.neutral100};
  box-shadow: 0.1rem 0 0.25rem rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 150ms cubic-bezier(0.075, 0.82, 0.165, 1) 0ms;
  z-index: 1000;
`;

const SideBar = ({ children }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const context: SideBarContextType = {
    expanded,
  };

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <SideBarContainer style={{ width: expanded ? "16rem" : "3.5rem" }}>
        <SideBarContext.Provider value={context}>
          <Box px={4} py={5}>
            <Hamburger onClick={() => setExpanded(!expanded)} />
          </Box>

          {children}
        </SideBarContext.Provider>
      </SideBarContainer>
    </ClickAwayListener>
  );
};

export default SideBar;
