import React, { ReactNode } from "react";
import ExpandMoreRounded from "@material-ui/icons/ExpandMoreRounded";
import { useAuth } from "@redwoodjs/auth";
import { routes, Link } from "@redwoodjs/router";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  Hidden,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "src/components/material-ui";
import LoopupLogo from "src/components/LoopupLogo";
import * as Colors from "src/colors";
import { redirect } from "src/utils/location";

const TopBarContainer = styled.div`
  border-bottom: 1px solid ${Colors.neutral60};
  background-color: ${Colors.neutral100};
  color: ${Colors.neutral0};
  display: flex;
  justify-content: space-between;
  height: 3rem;
  padding: 0.5rem 0rem 0.25rem 0rem;
  position: fixed;
  width: 100%;
  z-index: 800;

  @media (min-width: 820px) {
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0.25rem 1.5rem 0.25rem 5rem;
  }
`;

const UserPicture = styled.img`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin: 0.5rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.052rem;
  }

  @media (min-width: 820px) {
    width: 7.542rem;
    margin: 0;
  }
`;

const UserIcon = styled.i`
  font-size: 24px;
  margin: 0.5rem;
`;

const UserContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledExpandMoreRounded = styled(ExpandMoreRounded)`
  margin: 0.5rem;
  color: ${Colors.neutral50};
`;

type Props = {
  children?: ReactNode;
};

const TopBar = ({ children }: Props) => {
  const { currentUser, hasRole, logOut } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [brokenImage, setBrokenImage] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [brokenProfilePicture, setBrokenProfilePicture] = React.useState(false);

  const handleToggle = () => {
    setOpen((x) => !x);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onImageError = () => {
    setBrokenImage(true);
  };

  const onProfilePictureError = () => {
    setBrokenProfilePicture(true);
  };

  const userImage = !brokenProfilePicture ? (
    <UserPicture
      src={currentUser?.pictureUrl}
      onError={onProfilePictureError}
    />
  ) : (
    <UserIcon className="icon-user" />
  );

  const logo =
    currentUser?.customer.logoUrl && !brokenImage ? (
      <img src={currentUser?.customer.logoUrl} onError={onImageError} />
    ) : (
      <LoopupLogo />
    );

  return (
    <TopBarContainer>
      <LogoContainer>{logo}</LogoContainer>
      <Hidden xsDown implementation="css">
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          style={{ gap: "0.5rem" }}
        >
          {hasRole("ctap:internal") && (
            <>
              <Box mx={2}>
                <Link to={routes.debug()}>Internal Management</Link>
              </Box>
              <Divider orientation="vertical" flexItem />
            </>
          )}

          {children}

          <Box ml={1} mr={1}>
            {userImage}
          </Box>

          <UserContainer
            ref={anchorRef}
            onClick={handleToggle}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
          >
            <Typography variant="body1">{currentUser?.name}</Typography>
            <StyledExpandMoreRounded />
          </UserContainer>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            transition
            style={{ zIndex: 900 }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: "center top" }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box width="11rem">
                      <MenuList
                        autoFocusItem={open}
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={() => redirect("/profile")}>
                          <Box py={1.5} px={0.5}>
                            <Typography variant="body1">
                              <FormattedMessage id="editProfile" />
                            </Typography>
                          </Box>
                        </MenuItem>
                        <MenuItem onClick={logOut}>
                          <Box py={1.5} px={0.5}>
                            <Typography variant="body1">
                              <FormattedMessage id="signOut" />
                            </Typography>
                          </Box>
                        </MenuItem>
                      </MenuList>
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Hidden>
    </TopBarContainer>
  );
};

export default TopBar;
