import { Roles } from "../roles";

export const hasScopedRole = (
  rolesMap,
  role: string | string[],
  groupId?: string
) => {
  const roleList = Array.isArray(role) ? role : [role];

  return (
    rolesMap &&
    rolesMap.some(([key, { rolesList }]) => {
      return (
        (key === groupId || key === "*") &&
        roleList.some((r) => rolesList.includes(r))
      );
    })
  );
};

export const hasSiteWriteAccess = (
  scopedRoles: [string, any][],
  groupId?: string
) =>
  hasScopedRole(
    scopedRoles,
    [Roles.TelephonyAdminWrite, Roles.TelephonyAdmin, Roles.GlobalAdmin],
    groupId
  );
