import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import assignment from "assignment";
import * as idb from "idb-keyval";
import moment from "moment";
import { nanoid } from "nanoid";
import objectPath from "object-path";
import { CurrentUser, useAuth } from "@redwoodjs/auth";
import GraphqlClient from "lib/web/graphql-client";
import GatewayClient from "lib/web/gateway";
import * as Datadog from "src/utils/datadog";
import { useGateway } from "src/utils/hooks/gateway";
import { Leaves } from "src/utils/typescript";

/** All row types must include at least an ID property. Id's will usually be GUIDs in the form of:
 * 00000000-0000-0000-0000-000000000000.
 */
export type Id = string;

/** Row properties can only have values that are strings, numbers, or booleans. */
export type Property =
  | string
  | number
  | boolean
  | Record<string, string | number | boolean>;

export type BasicRow = {
  id: Id;
} & Record<string, Property>;

export type Order = "asc" | "desc";

/** A "View" allows for different ways to view the underlying data via a precomputed list of row
 * Id's. Helpful as some common operations such as sorting or separation of rows into categories is
 * an operation we want to optimize for.
 */
export type View<TRow extends BasicRow> = {
  indexAsc: Id[];
  indexDesc: Id[];
  rebuild: (view: View<TRow>, data: Record<Id, TRow>) => void;
};

/** An "Insight" is a flexible derivation from the data. Use cases of insights are:
 *  - field value enumerations (example: Set of all possible field values)
 *  - summations (example: how many )
 *
 * Insights are not intended to include custom queries. */
export type Insight<TRow extends BasicRow> = {
  value?: any;
  rebuild: (data: Record<Id, TRow>, ids?: Id[]) => any;
  get: () => any;
};

/** An "Orderer" is just a predefined sorting method to allow easy reuse of common sorting
 * patterns. Things like sorting on properties can be tricky to implement correctly (see single
 * property orderer). */
export type Orderer<TRow extends BasicRow> = {
  sort: (input: Id[], data: Record<Id, TRow>, order: Order) => Id[];
};

type BuildPropertyEnumeration<TRow extends BasicRow> = (
  property: Leaves<TRow>
) => Insight<TRow>;

type BuildPropertyPairs<TRow extends BasicRow> = (
  propertyA: Leaves<TRow>,
  propertyB: Leaves<TRow>
) => Insight<TRow>;

export function buildInsight<TRow extends BasicRow>(
  type: "property_enumeration"
): BuildPropertyEnumeration<TRow>;

export function buildInsight<TRow extends BasicRow>(
  type: "property_pairs"
): BuildPropertyPairs<TRow>;

/** Factory function to build commonly used insights. This is helpful as commonly used insights
 * may not have very simple implementations but this lets us reuse code for these insights.
 *
 * @param type - Predefined type of insight to build. */
export function buildInsight<TRow extends BasicRow>(
  type: "property_enumeration" | "property_pairs"
) {
  switch (type) {
    /** Creates a property enumeration insight. This insight is used to enumerate all possible
     * options that a property in the data set may have. Useful for things like <Select> components
     * that display all possible values for a property. */
    case "property_enumeration": {
      return (property: Leaves<TRow>): Insight<TRow> => ({
        get: function () {
          return this.value;
        },
        rebuild: (data, ids = Object.keys(data)) => {
          const unique = new Set<Property>();
          const values: Property[] = [];

          const path = property.split(".");
          ids.forEach((id) => {
            unique.add(path.reduce((a: any, prop) => a[prop], data[id]));
          });

          unique.forEach((u) => {
            // Do not count "empty" values, however do allow boolean false values.
            if (u !== undefined && u !== null && u !== "") {
              values.push(u);
            }
          });

          return values;
        },
      });
    }

    case "property_pairs": {
      return (propertyA: Leaves<TRow>, propertyB: Leaves<TRow>) => ({
        get: function () {
          return this.value;
        },
        rebuild: (data, ids = Object.keys(data)) => {
          const unique = new Set<Property>();
          const values: [Property, Property][] = [];

          const pathA = propertyA.split(".");
          const pathB = propertyB.split(".");

          ids.forEach((id) => {
            const valueA = pathA.reduce((a: any, prop) => a[prop], data[id]);
            const valueB = pathB.reduce((a: any, prop) => a[prop], data[id]);

            if (!unique.has(`${valueA},${valueB}`)) {
              // Preserve order of A and B in the return values list.
              values.push([valueA, valueB]);

              // Add both permutations of tuples with both A and B in them. This should cover
              // cases where we want combinations but not duplicates. This means our check will
              // not fail for swapped values.
              unique.add(`${valueA},${valueB}`);
              unique.add(`${valueB},${valueA}`);
            }
          });

          return values;
        },
      });
    }
  }
}

type BuildMapSort<TRow extends BasicRow> =
  /**
   * Mapped property sorting.
   *
   * @param property - The row property to use for sorting, this should be a property that has
   *    a known list of values that can be mapped.
   * @param map - The map of `property` values to their numeric sort positions in the list. */
  (property: Leaves<TRow>, mapper: Record<any, number>) => Orderer<TRow>;

type BuildSingleProperty<TRow extends BasicRow> =
  /**
   * Single property sorting
   *
   * @param property - The row property to use for sorting. This can be any leaf node on the row
   *    for complex object rows.
   */
  (property: Leaves<TRow>) => Orderer<TRow>;

/** Create an orderer which orders rows based on a mapped sort value. First used for creating
 * an orderer to take a sorted list of service users and grouping it into different groups
 * based on the service user type. It can be used to create any custom ordering on a property
 * with an enumerated list of values. */
export function buildOrderer<TRow extends BasicRow>(
  type: "map_sort"
): BuildMapSort<TRow>;

/** Creates a view which orders data based on a single property. Great care should be taken
 * when updating the sorting comparison functions. Separate functions are required for
 * different property types and it is important to handle cases with null/undefined property
 * values in the sorting array. This method is also used by buildView()'s
 * single_property_ordering factory.
 *
 * TODO: We may need to do ordering with a locale aware ordering. */
export function buildOrderer<TRow extends BasicRow>(
  type: "single_property"
): BuildSingleProperty<TRow>;

/** Factory function to build commonly used views. Useful as oftend we want to order with the same
 * logic but with derivations such as by property.
 *
 * TODO: Add support for two property orderings (sorting by say site and then assigned user). */
export function buildOrderer<TRow extends BasicRow>(
  type: "map_sort" | "single_property"
) {
  switch (type) {
    case "map_sort": {
      return (
        property: Leaves<TRow>,
        mapper: Record<any, number>
      ): Orderer<TRow> => ({
        sort: (input, data, order = "asc") => {
          const compare = (order: Order) => (a, b) => {
            const path = property.split(".");
            const valueA = path.reduce(
              (partial: any, prop) => partial[prop],
              data[a]
            );
            const valueB = path.reduce(
              (partial: any, prop) => partial[prop],
              data[b]
            );

            const propertyA = order === "asc" ? valueA : valueB;
            const propertyB = order === "asc" ? valueB : valueA;

            if (mapper[propertyA] < mapper[propertyB]) {
              return -1;
            }
            if (mapper[propertyA] > mapper[propertyB]) {
              return 1;
            }

            return 0;
          };

          return input.sort(compare(order));
        },
      });
    }

    case "single_property": {
      return (property: Leaves<TRow>): Orderer<TRow> => ({
        sort: (input, data, order = "asc") => {
          /** This method works for now given the Property type remains string | number | boolean.
           * If we were to add any other possible types, we would need to change this code to
           * handle comparing objects who do not implement the greater than (>) and less than (<)
           * operators in a way that is compatible with what .sort() requires.
           */
          const compare = (order: Order) => (a, b) => {
            const path = property.split(".");
            const valueA = path.reduce(
              (partial: any, prop) => partial[prop],
              data[a]
            );
            const valueB = path.reduce(
              (partial: any, prop) => partial[prop],
              data[b]
            );

            const propertyA = order === "asc" ? valueA : valueB;
            const propertyB = order === "asc" ? valueB : valueA;

            // Handle disabled property
            const disabledA = data[a].disabled || false;
            const disabledB = data[b].disabled || false;

            // Handle null cases
            if (propertyA == null && propertyB == null) {
              return 0;
            }
            if (propertyA != null && propertyB == null) {
              return -1;
            }
            if (propertyA == null && propertyB != null) {
              return 1;
            }

            // Check disabled property
            if (disabledA && !disabledB) {
              return 1;
            }
            if (!disabledA && disabledB) {
              return -1;
            }

            // Both properties are valid strings here
            if (propertyA < propertyB) {
              return -1;
            }
            if (propertyA > propertyB) {
              return 1;
            }

            return 0;
          };

          return input.sort(compare(order));
        },
      });
    }
  }
}

/** Factory function to build commonly used views. Useful as oftend we want to order with the same
 * logic but with derivations such as by property.
 *
 * TODO: Add support for two property orderings (sorting by say site and then assigned user).
 */
export const buildView = function <TRow extends BasicRow>(
  type: "filter" | "single_property_ordering" | "single_property_filter"
) {
  switch (type) {
    /** Creates a generic filter view. Pass in a predicate function that filters  */
    case "filter": {
      return (
        predicate: (row: TRow) => boolean
      ): Omit<View<TRow>, "indexAsc" | "indexDesc"> => ({
        rebuild: (view, data) => {
          const index = Object.keys(data).filter((key) => predicate(data[key]));

          view.indexAsc = index;
          view.indexDesc = index;
        },
      });
    }

    /** Creates a view that only shows rows who have a single property matching one of a list (or
     * single) value.
     * @param property - String name or object path of the property to filter on.
     * @param values - Single/Array of value(s) to filter the property on. These values will be
     *   accepted, any rows with other values will be excluded.
     */
    case "single_property_filter": {
      return (property: Leaves<TRow>, values: any | any[]) => {
        const acceptTable: Record<any, true> = (
          Array.isArray(values) ? values : [values]
        ).reduce((table, value) => ({ ...table, [value]: true }), {});

        return buildView("filter")((row) => {
          const path = property.split(".");
          const value = path.reduce((a: any, prop) => a[prop], row);

          return acceptTable[value];
        });
      };
    }

    /** Creates a view which orders data based on a single property. Great care should be taken
     * when updating the sorting comparison functions. Separate functions are required for
     * different property types and it is important to handle cases with null/undefined property
     * values in the sorting array.
     */
    case "single_property_ordering": {
      return (
        property: Leaves<TRow>
      ): Omit<View<TRow>, "indexAsc" | "indexDesc"> => ({
        rebuild: (view, data) => {
          const { sort } = buildOrderer<TRow>("single_property")(property);

          view.indexAsc = sort(Object.keys(data), data, "asc");
          view.indexDesc = sort(Object.keys(data), data, "desc");
        },
      });
    }
  }
};

export type Job = {
  started: number;
  cancelled: boolean;
};

export type DataClosure<TRow extends BasicRow> = {
  /** Return _all_ rows in the data cache. Use with caution if working with large datasets. */
  all(): (TRow & { selected: boolean })[];

  /** Returns the total number of rows in the call chain. */
  count(): number;

  /** Filters the data using a predicate function. Ordering is preserved.
   *
   * @param predicate - Predicate function that accepts one argument: the current row to be
   * checked. If a falsy value is passed in place of the predicate function then .filter will
   * not change the data.
   */
  filter(predicate?: (row: TRow) => boolean): DataClosure<TRow>;

  /** Filters data by a filter UI element. Filters in the UI are select boxes allowing the user
   * to filter data based on a property value. This helper function allows easy filtering of
   * rows who's properties match the property of a given filter query object. */
  filterByFilter(filterQuery, property: Leaves<TRow>): DataClosure<TRow>;

  /** Filters a property by a multiple value filter query object */
  filterByMultiFilter(filterQuery, property: Leaves<TRow>): DataClosure<TRow>;

  /** Filters data by a property having a specified value */
  filterByProperty(property: Leaves<TRow>, value: any): DataClosure<TRow>;

  /** Filters data by only those which have been selected using the selection API. */
  filterBySelected(): DataClosure<TRow>;

  /** Filters data by a query string, returning all rows that have any property that includes
   * the search query. Note that the simple implementation chosen here is to stringify the row
   * using JSON.stringify. This does mean that some search queries will match rows which might
   * not be expected, such as queries including JSON lexical tokens like { } [ ] , ".
   * @param query - String query to search rows for
   */
  filterByText(query?: string): DataClosure<TRow>;

  /**  */
  insight(name: string): any;

  // join: (type: "inner" | "leftOuter", dataCache) => {},

  /** Sorts the data using a predefined orderer registered with the data cache on
   * instantiation. */
  orderBy(name: string, direction: Order): DataClosure<TRow>;

  /**
   * Returns a page of the data given a page number and page size.
   * @param pageNumber - Page number to return, index 0 (so passing 0 will return the first
   *   page).
   * @param pageSize - Maximum number of rows to return in this page. Note that fewer rows
   *   will be returned if the final page does not have enough rows.
   */
  page(pageNumber: number, pageSize: number): (TRow & { selected: boolean })[];

  /** Select a single row from the table */
  select(id: Id): TRow;

  /** Returns the total number of rows in the call chain. */
  tap(property: "count", predicate: (value: number) => void): DataClosure<TRow>;
  /** Returns a function that will select all the rows in the current call chain. */
  tap(
    property: "selectAll",
    predicate: (fn: () => void) => void
  ): DataClosure<TRow>;
  /** Returns the total number of rows that are selected in the call chain. */
  tap(
    property: "selectedCount",
    predicate: (value: number) => void
  ): DataClosure<TRow>;

  /**
   * Apply a view to the selected data. This acts like a .filter().orderBy() for the specified
   * view.
   * @param name - Name of the view to use.
   * @param direction - Ordering direction to use.
   */
  view(name: string, direction?: Order): DataClosure<TRow>;
};

export type ComputedPropertyFn<TRow extends BasicRow> = (
  row: TRow,
  ref: (name: string) => DataClosure<BasicRow>
) => void;

export type CacheStore<TRow extends BasicRow> = {
  data: Record<Id, TRow>;
  selected: Set<Id>;
  size: number;
  jobs: Record<string, Job>;

  computed: Record<string, ComputedPropertyFn<TRow>>;
  foreignRef: Record<string, CacheStore<BasicRow>>;
  insights: Record<string, Insight<TRow>>;
  orderers: Record<string, Orderer<TRow>>;
  views: Record<string, View<TRow>>;

  get(): DataClosure<TRow>;
};

/** Fetch data function that connects a gateway data source to this data cache. Note that
 * pageNumber is index 1 and pageSize is dependent */
export type FetchDataFn<TRow extends BasicRow> = (
  clients: {
    gateway: GatewayClient;
    graphql: GraphqlClient;
    roles: string | string[];
  },
  pageSize: number,
  pageNumber: number
) => Promise<{ rows: TRow[]; hasNextPage: boolean }>;

export type PullStrategy = Record<"first" | "all", number>;

export const DEFAULT_PULL_STRATEGY: PullStrategy = {
  first: 100,
  all: 10000,
};

type SyncState = {
  isSyncing: boolean;
  lastSync: number;
  lastUpdated: number;
  refreshPending: boolean;
  refreshCount: number;
  resetCount: number;
  selectedCount: number;
  size: number;

  /** Sync stats to inform us about new data changes */
  stats: {
    new: number;
  };
};

/**
 * Factory function to build a new data cache.
 *
 * @param name: Data cache name. This is used to namespace the indexdb database.
 */
export function createDataCache<TRow extends BasicRow>(
  name: string,
  fd: FetchDataFn<TRow>,
  strategy: PullStrategy = DEFAULT_PULL_STRATEGY,
  offline = true
) {
  const cache: CacheStore<TRow> = {
    data: {},
    selected: new Set<Id>(),
    size: 0,
    jobs: {},

    get: () => {
      let order: Id[] = Object.keys(cache.data);
      let index = new Set<Id>(order);

      const closure: DataClosure<TRow> = {
        all: () =>
          order.map((id) => ({
            ...cache.data[id],
            selected: cache.selected.has(id),
          })),

        count: () => index.size,
        filter: (predicate?: (row: TRow) => boolean) => {
          if (predicate) {
            order = order.filter((id) => predicate(cache.data[id]));
            index = new Set<Id>(order);
          }

          return closure;
        },
        filterByFilter: (filterQuery, property: Leaves<TRow>) => {
          if (objectPath.get(filterQuery, property)) {
            order = order.filter(
              (id) =>
                objectPath.get(cache.data[id], property) ===
                objectPath.get(filterQuery, property)
            );
            index = new Set<Id>(order);
          }

          return closure;
        },
        filterByMultiFilter: (filterQuery, property: Leaves<TRow>) => {
          const filter = filterQuery[property] ?? {};
          const filterValues = Object.keys(filter).filter((key) => filter[key]);

          if (filterValues.length > 0) {
            const path = property.split(".");

            order = order.filter((id) => {
              const value = path.reduce(
                (a: any, prop) => a[prop],
                cache.data[id]
              );

              return filter[value];
            });
            index = new Set<Id>(order);
          }

          return closure;
        },
        filterByProperty: (property: Leaves<TRow>, value: any) => {
          order = order.filter(
            (id) => objectPath.get(cache.data[id], property) === value
          );
          index = new Set<Id>(order);

          return closure;
        },
        filterBySelected: () => {
          order = order.filter((id) => cache.selected.has(id));
          index = new Set<Id>(order);

          return closure;
        },
        filterByText: (query?: string) => {
          const search = query?.toLowerCase() || "";

          if (search.length > 0) {
            order = order.filter((id) =>
              JSON.stringify(cache.data[id]).toLowerCase().includes(search)
            );
            index = new Set<Id>(order);
          }

          return closure;
        },
        insight: (name) => cache.insights[name].rebuild(cache.data, order),
        // join: (type: "inner" | "leftOuter", dataCache) => {},
        orderBy: (name = "default", direction: Order = "asc") => {
          order = cache.orderers[name].sort(order, cache.data, direction);

          return closure;
        },
        page: (pageNumber: number, pageSize: number) => {
          return order
            .slice(pageSize * pageNumber, pageSize * (pageNumber + 1))
            .map((id) => ({
              ...cache.data[id],
              selected: cache.selected.has(id),
            }));
        },
        select: (id: Id) => cache.data[id],
        tap: (
          property: "count" | "selectAll" | "selectedCount",
          predicate: (value: any) => void
        ) => {
          switch (property) {
            /** Returns the total number of rows at this point in the chain */
            case "count": {
              predicate(order.length);

              return closure;
            }

            /** TODO: this needs access to the actual context to be well functioning. But that
             * requires refactoring all of the uses of useData(). */
            case "selectAll": {
              const method = () => {
                order.forEach((id: Id) => cache.selected.add(id));
              };

              predicate(method);

              return closure;
            }

            /** Returns the total number of rows that are selected from this data chain */
            case "selectedCount": {
              const selectedCount = order.reduce(
                (count: number, id: Id) =>
                  cache.selected.has(id) ? count + 1 : count,
                0
              );

              predicate(selectedCount);

              return closure;
            }
          }
        },
        view: (name = "default", direction: Order = "asc") => {
          const viewIndex =
            cache.views[name][direction === "asc" ? "indexAsc" : "indexDesc"];

          order = viewIndex.filter((id) => index.has(id));
          index = new Set<Id>(order);

          return closure;
        },
      };

      return closure;
    },

    computed: {},
    foreignRef: {},
    insights: {},
    orderers: {},
    views: {
      default: {
        indexAsc: [],
        indexDesc: [],
        ...buildView<TRow>("single_property_ordering")("id"),
      },
    },
  };

  window[`${name}DataCache`] = cache;

  let fetchData: FetchDataFn<TRow> = fd;
  let withOffline: boolean = offline;

  // Namespacing must also be on customer?
  let idbStore: idb.UseStore = null;

  const contextGlobal = createContext({
    isSyncing: false,
    hasSomeData: false,
    initialized: false,
    lastSync: 0,
    lastUpdated: 0,
    refreshPending: false,
    refreshCount: 0,
    resetCount: 0,
    selectedCount: 0,
    size: 0,
    stats: {
      new: 0,
    },

    /** Starts the data cache up. This function is idempotent: once the cache is initialized
     * subsequent calls to init() will noop. */
    init: ({ offline: _1 = undefined, fetchData: _2 = undefined } = {}) => {},
    touch: () => {},
    /** Request that the data cache be refreshed. This will initiate fetching every row from the
     * backend again and may take some time to complete. */
    refresh: () => {},
    /** Cancel sync jobs */
    cancel: () => {},
    /** Recalculates all rows and then rebuilds all insights and views */
    rebuild: () => {},
    /** Resets the data cache optionally with a new fetch function */
    reset: (_idbName, _fd: FetchDataFn<TRow>) => {},
    /**
     * Updates a row with the passed data.
     *
     * @param row - A partial row that must include the Id of the row to be updated. Any additional
     *    properties passed are merged into / replace the original properties.
     */
    updateRow: (_row: Partial<TRow> & { id: Id }) => {},

    /** Select a given row using the inbuilt data cache row selection. */
    selectRow: (_id: Id) => {},
    deselectRow: (_id: Id) => {},
    clearSelected: () => {},
  });

  /** Rebuild all insights and views based on the current cache data. */
  const rebuildInsightsAndViews = () => {
    Object.keys(cache.insights)
      .map((name) => cache.insights[name])
      .forEach((insight) => {
        insight.value = insight.rebuild(cache.data);
      });

    Object.keys(cache.views)
      .map((name) => cache.views[name])
      .forEach((view) => {
        view.rebuild(view, cache.data);
      });
  };

  /** Applies all computed methods to a row and returns a new copy of the row with those
   * transformations applied.
   *
   * @param row - The input row, typically from the cache store to be updated.
   * @returns New row with computed values */
  const calculateRow = (row: TRow) => {
    function ref(fname: string) {
      return cache.foreignRef[fname]?.get();
    }

    return Object.keys(cache.computed)
      .map((name) => cache.computed[name])
      .reduce<TRow>((r, method) => {
        method(r, ref);

        return r;
      }, assignment(row));
  };

  /** Recalculates all rows in the data cache by calling all computed properties methods again on
   * them. */
  const recalculate = () => {
    Object.entries(cache.data).forEach(([id, row]) => {
      cache.data[id] = calculateRow(row);
    });
  };

  /** Load data via offline IndexDB data store. This allows extremely fast startup times while we
   * wait for the data to be synced via the gateway. */
  const loadOffline = async (
    jobId: string,
    setSync: Dispatch<SetStateAction<SyncState>>
  ) => {
    if (!idbStore) {
      throw new Error(
        "DataCache: loadOffline() called before idbStore was initialized"
      );
    }

    try {
      const rows = await idb.values(idbStore);

      if (cache.jobs[jobId].cancelled) {
        return;
      }

      rows.forEach((row) => {
        cache.data[row.id] = calculateRow(row);
      });

      cache.size = rows.length;

      rebuildInsightsAndViews();

      setSync((state) => ({
        ...state,
        lastUpdated: moment().valueOf(),
        size: rows.length,
      }));
    } catch (error) {
      if (error instanceof DOMException) {
        Datadog.warn(
          `dataCache.loadOffline() called from incognito mode: ${error.name}`,
          { error }
        );
      } else {
        Datadog.error(`dataCache.loadOffline() threw ${error.name}`, { error });
      }
    }
  };

  /** Pulls data from the gateway with a given page size and optionally number of pages to use. */
  const pull = async (
    jobId: string,
    client: GatewayClient,
    currentUser: CurrentUser,
    setSync: Dispatch<SetStateAction<SyncState>>,
    pageSize: number,
    pages?: number
  ) => {
    if (!idbStore) {
      throw new Error(
        "DataCache: pull() called before idbStore was initialized"
      );
    }

    const graphqlClient = new GraphqlClient(currentUser?.accessToken);

    // Track stale keys for the purpose of removing offline data that's been removed in the API.
    const staleKeys = new Set<Id>(Object.keys(cache.data));
    let page = 1;

    // eslint-disable-next-line no-unmodified-loop-condition
    while (pages == null || page <= pages) {
      const { hasNextPage, rows } = await fetchData(
        {
          gateway: client,
          graphql: graphqlClient,
          roles: currentUser?.roles ?? [],
        },
        pageSize,
        page++
      );

      if (cache.jobs[jobId].cancelled) {
        return;
      }

      rows.forEach((row) => {
        cache.data[row.id] = calculateRow(row);
        staleKeys.delete(row.id);
      });

      let newCount = 0;

      // For some data cache instances we don't want to persist data to indexdb
      if (withOffline) {
        try {
          newCount = (
            await idb.getMany(
              rows.map((row) => row.id),
              idbStore
            )
          ).filter((row) => row == null).length;

          // Update offline storage of data
          // TODO: Clear on logout?
          idb.setMany(
            rows.map((row) => [row.id, row]),
            idbStore
          );

          // We can clean stale keys if we iterated through everything. Ensure we do this _before_
          // rebuilding the insights and views otherwise dereference errors will be thrown.
          if (!hasNextPage && pages == null) {
            staleKeys.forEach((id) => {
              delete cache.data[id];
              cache.selected.delete(id);
            });

            await idb.delMany(Array.from(staleKeys.values()), idbStore);
          }
        } catch (error) {
          if (error instanceof DOMException) {
            Datadog.warn(
              `dataCache.pull() called from incognito mode: ${error.name}`,
              { error }
            );
          } else {
            Datadog.error(`dataCache.pull() threw ${error.name}`, { error });
          }
        }
      }

      cache.size = Object.keys(cache.data).length;

      rebuildInsightsAndViews();
      setSync((state) => ({
        ...state,
        size: cache.size,
        selectedCount: cache.selected.size,
        stats: {
          ...state.stats,
          new: state.stats.new + newCount,
        },
        lastUpdated: moment().valueOf(),
      }));

      if (!hasNextPage) {
        break;
      }
    }
  };

  /** React Context provider for useInfo(). useInfo() context is primarily used for controlling
   * the data cache including initialization and starting the process of syncing data along with
   * information regarding the cache such as sync status and available views / insights. */
  const provider = ({ children }) => {
    const { currentUser } = useAuth();
    const [sync, setSync] = useState<SyncState>({
      isSyncing: false,
      lastSync: 0,
      lastUpdated: 0,
      refreshPending: false,
      refreshCount: 0,
      resetCount: 0,
      selectedCount: 0,
      size: 0,
      stats: {
        new: 0,
      },
    });
    const [initialized, setInitialized] = useState(false);

    const init = useCallback(({ offline, fetchData: fd } = {}) => {
      if (initialized) {
        return;
      }

      if (offline != null) {
        withOffline = offline;
      }
      if (fd != null) {
        fetchData = fd;
      }

      setInitialized(true);
    }, []);
    const touch = useCallback(
      () => setSync((value) => ({ ...value, lastUpdated: moment().valueOf() })),
      []
    );
    const rebuild = useCallback(() => {
      recalculate();
      rebuildInsightsAndViews();
      touch();
    }, [touch]);
    const refresh = useCallback(() => {
      if (!sync.isSyncing) {
        setSync((state) => ({
          ...state,
          refreshCount: state.refreshCount + 1,
        }));
      } else {
        setSync((state) => ({ ...state, refreshPending: true }));
      }
    }, [sync.isSyncing]);

    const cancel = useCallback(() => {
      Object.keys(cache.jobs).forEach((id) => {
        cache.jobs[id].cancelled = true;
      });
    }, [currentUser?.customer.id]);

    const reset = useCallback(
      (idbName, fd: FetchDataFn<TRow>) => {
        // Cancel all outstanding jobs
        cancel();

        // TODO: add support for changing indexdb stores
        // idbStore = idb.createStore(`${name}-${currentUser.customer.id}-${idbName}`)
        fetchData = fd;

        cache.data = {};

        setSync((state) => ({
          ...state,
          size: 0,
          lastUpdated: moment().valueOf(),
          resetCount: state.resetCount + 1,
        }));

        rebuildInsightsAndViews();
        refresh();
      },
      [currentUser?.customer.id]
    );

    /** Function readme? */
    const updateRow = useCallback((row: Partial<TRow> & { id: Id }) => {
      if (!cache.data[row.id]) {
        return;
      }

      cache.data[row.id] = calculateRow(
        assignment({}, cache.data[row.id], row)
      );

      rebuildInsightsAndViews();

      setSync((state) => ({ ...state, lastUpdated: moment().valueOf() }));
    }, []);

    const selectRow = useCallback(
      (id: Id) => {
        cache.selected.add(id);
        setSync((value) => ({
          ...value,
          selectedCount: cache.selected.size,
          lastUpdated: moment().valueOf(),
        }));
      },
      [cache.selected]
    );

    const deselectRow = useCallback(
      (id: Id) => {
        cache.selected.delete(id);
        setSync((value) => ({
          ...value,
          selectedCount: cache.selected.size,
          lastUpdated: moment().valueOf(),
        }));
      },
      [cache.selected]
    );

    const clearSelected = useCallback(() => {
      cache.selected.clear();
      setSync((value) => ({
        ...value,
        selectedCount: 0,
        lastUpdated: moment().valueOf(),
      }));
    }, [cache.selected]);

    // Create the store used for offline data access. Note that we do include the customer Id in
    // the store name to prevent someone logging in/out across customers and seeing data for
    // numbers populated between the two which would be _bad_. It might be the case that in the
    // future we need to also namespace on loginId/roles/scopes etc.
    useEffect(() => {
      idbStore = idb.createStore(
        `${name}-${currentUser.customer.id}`,
        "data-store"
      );
    }, [currentUser?.customer.id]); // TODO: this can fail

    // Memoize the context value to prevent unnecessary rerenders.
    const value = useMemo(
      () => ({
        isSyncing: sync.isSyncing,
        hasSomeData: false,
        initialized,
        lastSync: sync.lastSync,
        lastUpdated: sync.lastUpdated,
        refreshCount: sync.refreshCount,
        refreshPending: sync.refreshPending,
        resetCount: sync.resetCount,
        selectedCount: sync.selectedCount,
        size: sync.size,
        stats: sync.stats,

        insights: Object.keys(cache.insights),
        orderers: Object.keys(cache.orderers),
        views: Object.keys(cache.views),

        cancel,
        init,
        touch,
        refresh,
        rebuild,
        reset,
        updateRow,

        selectRow,
        deselectRow,
        clearSelected,
      }),
      [
        sync.isSyncing,
        sync.lastSync,
        sync.lastUpdated,
        sync.refreshCount,
        sync.refreshPending,
        sync.resetCount,
        sync.selectedCount,
        sync.size,

        initialized,
        init,
        touch,
        rebuild,
        refresh,
        // reset,
        updateRow,

        selectRow,
        deselectRow,
        clearSelected,
      ]
    );

    // Initial sync job
    useGateway(
      async (client) => {
        // Create and register the job
        const jobId = nanoid();
        cache.jobs[jobId] = { started: moment().valueOf(), cancelled: false };

        if (value.initialized && idbStore) {
          setSync((state) => ({
            ...state,
            isSyncing: true,
            stats: {
              new: 0,
            },
          }));

          // Only on the first load do we need to employ these aggressive data loading techniques.
          // First we load offline data (which is very fast), followed by syncing the first few
          // pages of data which the user is likely to interact with initially. Subsequent syncs
          // do not need to do this as the user has already got all the data loaded even if some
          // of it is stale.
          if (sync.refreshCount === 0) {
            if (withOffline) {
              // Load offline data first
              await loadOffline(jobId, setSync);
            }

            // First page pull
            await pull(jobId, client, currentUser, setSync, strategy.first, 1);

            setSync((state) => ({ ...state, lastSync: moment().valueOf() }));
          }

          // Full pull
          await pull(jobId, client, currentUser, setSync, strategy.all);

          setSync((state) => {
            // If there's a pending refresh (one was queued whilst an existing refresh was
            // running) we want to kick off a new refresh immediately by incrementing the
            // refresh count. Adding nothing will not start a new refresh.
            const addRefresh = state.refreshPending ? 1 : 0;
            const now = moment().valueOf();

            return {
              ...state,
              isSyncing: false,
              lastSync: now,
              lastUpdated: now,
              refreshCount: state.refreshCount + addRefresh,
            };
          });
        }

        delete cache.jobs[jobId];
      },
      [initialized, sync.refreshCount]
    );

    return (
      <contextGlobal.Provider value={value}>{children}</contextGlobal.Provider>
    );
  };

  /** Hook to pull the context info */
  const useInfo = () => {
    const context = useContext(contextGlobal);

    if (context === undefined) {
      throw new Error("useContext was used outside of its provider");
    }

    return { ...context };
  };

  const useUpdateRow = () => {
    const context = useContext(contextGlobal);

    if (context === undefined) {
      throw new Error("useContext was used outside of its provider");
    }

    return context.updateRow;
  };

  /** Register a computed property. Computed properties function exactly like ordinary properties
   * except they are not persisted to local storage and are instead in some way derived from the
   * existing data. Example: the data includes the country code however the translated country
   * name is what is shown.
   *
   * @param name - Name to identify this computed property by.
   * @param method - Function to transform the input row to include the computed property. */
  const registerComputed = (
    name: string,
    method: (row: TRow, ref: (name: string) => DataClosure<BasicRow>) => void
  ) => {
    if (!cache.computed[name]) {
      cache.computed[name] = method;
    }
  };

  const registerForeignRef = (foreignCache) => {
    const fname = foreignCache.name;

    if (!cache.foreignRef[fname]) {
      cache.foreignRef[fname] = foreignCache.cache;
    }
  };

  /** Registers a new insight */
  const registerInsight = (name: string, insight: Insight<TRow>) => {
    if (!cache.insights[name]) {
      cache.insights[name] = insight;
      cache.insights[name].value = cache.insights[name].rebuild(cache.data);
    }
  };

  const registerOrderer = (name: string, orderer: Orderer<TRow>) => {
    if (!cache.orderers[name]) {
      cache.orderers[name] = orderer;
    }
  };

  /** Registers a new view */
  const registerView = (
    name: string,
    newView: Omit<View<TRow>, "indexAsc" | "indexDesc">
  ) => {
    if (!cache.views[name]) {
      cache.views[name] = { ...newView, indexAsc: [], indexDesc: [] };
      cache.views[name].rebuild(cache.views[name], cache.data);
    }
  };

  /** Use the data from the cache. Data is returned as a method chainable object to allow easy
   * manipulation of the data utilizing prebuilt views / orderers / filters. */
  const useData = cache.get;

  /** Returns an insight */
  const useInsight = (name: string) =>
    cache.insights[name].get.bind(cache.insights[name])();

  window[`cache-${name}`] = cache;

  return {
    name,
    cache,
    provider,

    registerComputed,
    registerForeignRef,
    registerInsight,
    registerOrderer,
    registerView,
    useData,
    useInfo,
    useInsight,
    useUpdateRow,
  };
}
