import { createContext, useState, useContext } from "react";

const PageNameContext = createContext();
const PageNameUpdateContext = createContext();

export const usePageNameContext = () => useContext(PageNameContext);
export const usePageNameUpdateContext = () => useContext(PageNameUpdateContext);

export const PageNameProvider = ({ children }) => {
  const [pageName, setPageName] = useState();

  return (
    <PageNameContext.Provider value={pageName}>
      <PageNameUpdateContext.Provider value={setPageName}>
        {children}
      </PageNameUpdateContext.Provider>
    </PageNameContext.Provider>
  );
};
