import teleformat from "teleformat";
import { AllocationStatus, HostType, UsageType } from "lib/web/numbers";
import GatewayClient from "lib/web/gateway";
import { t } from "src/i18n";
import {
  buildInsight,
  buildOrderer,
  buildView,
  createDataCache,
} from "src/utils/contexts/dataCache";

export enum NumberType {
  "Geographical" = 1,
  "Nongeographical" = 3,
}

export type NumberRow = {
  /** Primary key, this is also the unformatted E164 telephone number */
  id: string;
  /** Pretty printed version of Id. */
  displayNumber?: string;
  unformattedNumber?: string;
  username?: string;
  city?: string;
  country?: string; // Computed property
  countryCode?: string;
  site?: string;
  siteId: string;
  status: AllocationStatus;
  status_t?: string /* @computed */;
  type: NumberType;
  hostType: HostType;
  hostType_t?: string /* @computed */;
  usageType: UsageType;
  usageType_t?: string /* @computed */;
  upn?: string;
  drNumber?: string;
  /** Pretty printed version of drNumber. */
  displayDrNumber?: string;
  sipTrunkName?: string;
  destination?: string;
  assignedTo?: string;
  editable?: boolean;
};

const mapAllocationStatus = (rawStatus: number): AllocationStatus => {
  return {
    0: AllocationStatus.NotApplicable, // Don't want to deal with this now

    1: AllocationStatus.Allocated,
    2: AllocationStatus.Unallocated,
    3: AllocationStatus.NotApplicable,
    4: AllocationStatus.Pending,
    5: AllocationStatus.Reserved,
  }[rawStatus];
};

export async function fetchData(
  { gateway }: { gateway: GatewayClient },
  pageSize: number,
  pageNumber: number
): Promise<{ hasNextPage: boolean; rows: NumberRow[] }> {
  // TODO: We need: site id
  const results = await gateway.listCustomerDDIs(
    pageNumber - 1,
    pageSize,
    undefined,
    undefined,
    {}
  );

  return {
    hasNextPage: results.paginationInfo?.hasNextPage,
    rows: results.itemsList.map((ddi) => ({
      id: ddi.e164,
      username: ddi.assignedUserName?.value
        ? ddi.assignedUserName.value
        : ddi.hostType === 7
        ? "SIP Trunk"
        : null,
      city: ddi.city?.value,
      countryCode: ddi.country?.value,
      siteId: ddi.siteId,
      site: ddi.siteName?.value,
      status: mapAllocationStatus(ddi.allocationStatus),
      type: NumberType[ddi.numberType],
      hostType: HostType[ddi.hostType] as any,
      usageType: UsageType[ddi.usageType] as any,
      upn: ddi.userPrincipalName?.value,
      drNumber: ddi.drNumber?.value,
      sipTrunkName: ddi.sipTrunkName?.value,
    })),
  };
}

const numbers = createDataCache<NumberRow>("numbers", fetchData);

numbers.registerComputed("displayNumber", (row) => {
  row.displayNumber = teleformat.decorate(`+${row.id}`).international;
});

numbers.registerComputed("unformattedNumber", (row) => {
  row.unformattedNumber = row.id;
});

numbers.registerComputed("displayDrNumber", (row) => {
  row.displayDrNumber = row.drNumber
    ? teleformat.decorate(`+${row.drNumber}`).international
    : undefined;
});
numbers.registerComputed("country", (row) => {
  row.country = t(`country_${row.countryCode}`);
});
numbers.registerComputed("hostType_t", (row) => {
  row.hostType_t = t(`hostTypeCtap${HostType[row.hostType]}`);
});
numbers.registerComputed("usageType_t", (row) => {
  row.usageType_t = t(`usageType${UsageType[row.usageType]}`);
});

numbers.registerInsight(
  "city_options",
  buildInsight<NumberRow>("property_enumeration")("city")
);
numbers.registerInsight(
  "country_options",
  buildInsight<NumberRow>("property_enumeration")("countryCode")
);
numbers.registerInsight(
  "site_options",
  buildInsight<NumberRow>("property_enumeration")("site")
);
numbers.registerInsight(
  "number_options",
  buildInsight<NumberRow>("property_enumeration")("id")
);
numbers.registerInsight(
  "username_options",
  buildInsight<NumberRow>("property_enumeration")("username")
);
numbers.registerInsight(
  "status_options",
  buildInsight<NumberRow>("property_enumeration")("status")
);
numbers.registerInsight(
  "type_options",
  buildInsight<NumberRow>("property_enumeration")("type")
);
numbers.registerInsight(
  "hostType_options",
  buildInsight<NumberRow>("property_enumeration")("hostType")
);
numbers.registerInsight(
  "usageType_options",
  buildInsight<NumberRow>("property_enumeration")("usageType")
);

/** Precomputed orderings */
numbers.registerView(
  "orderby_username",
  buildView<NumberRow>("single_property_ordering")("username")
);
numbers.registerView(
  "orderby_assignedUser",
  buildView<NumberRow>("single_property_ordering")("username")
);
numbers.registerView(
  "orderby_site",
  buildView<NumberRow>("single_property_ordering")("site")
);
numbers.registerView(
  "orderby_city",
  buildView<NumberRow>("single_property_ordering")("city")
);
numbers.registerView(
  "orderby_country",
  buildView<NumberRow>("single_property_ordering")("country")
);
numbers.registerView(
  "orderby_hostType",
  buildView<NumberRow>("single_property_ordering")("hostType")
);
numbers.registerView(
  "orderby_usageType",
  buildView<NumberRow>("single_property_ordering")("usageType")
);
numbers.registerView(
  "orderby_number",
  buildView<NumberRow>("single_property_ordering")("id")
);
numbers.registerView(
  "orderby_numberType",
  buildView<NumberRow>("single_property_ordering")("type")
);
numbers.registerView(
  "orderby_numberStatus",
  buildView<NumberRow>("single_property_ordering")("status")
);

/** The same orderings available dynamically */
numbers.registerOrderer(
  "city",
  buildOrderer<NumberRow>("single_property")("city")
);
numbers.registerOrderer(
  "site",
  buildOrderer<NumberRow>("single_property")("site")
);
numbers.registerOrderer(
  "username",
  buildOrderer<NumberRow>("single_property")("username")
);
numbers.registerOrderer(
  "hostType",
  buildOrderer<NumberRow>("single_property")("hostType_t")
);
numbers.registerOrderer(
  "usageType",
  buildOrderer<NumberRow>("single_property")("usageType_t")
);

export const __cache__ = numbers;
export const Provider = numbers.provider;

export const registerNumbersInsight = numbers.registerInsight;
export const registerNumbersView = numbers.registerView;
export const useNumbers = numbers.useData;
export const useNumbersInfo = numbers.useInfo;
export const useNumbersInsight = numbers.useInsight;
export const useUpdateNumber = numbers.useUpdateRow;
