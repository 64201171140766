import { useState, useEffect } from "react";
import once from "lodash/fp/once";
import { useAuth } from "@redwoodjs/auth";
import GatewayClient from "lib/web/gateway";
import * as Datadog from "src/utils/datadog";
import * as Location from "src/utils/location";

// Type siganture for an async method that will be passed an instantiated gateway client.
export type UseGatewayFunction = (client: GatewayClient) => Promise<void>;
export type OnGatewayFunction = (
  client: GatewayClient,
  ...args
) => Promise<void>;

//  useGateway()
//
// useGateway() functions very similarly to useEffect(). It accepts two arguments, the first being
// an *async* callback function. This callback will be passed an instantiated gateway client as the
// first argument. The optional second argument is a dependency list that is directly passed to an
// underlying useEffect() that executes the gateway callback.
//
// TODO: A question here is how we would use the cleanup method that comes with useEffect. This
// may actually be a useful thing for us to do in the future so we could for instance cancel long
// running jobs?
export function useGateway(fn: UseGatewayFunction, deps = []) {
  const { currentUser } = useAuth();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const onError = once((err) => {
      // Authentication issue, so we just reload here.
      if (err?.code === 16) {
        Datadog.warn("useGateway() E16 unauthenticated", { error: err });
        Location.reload();
      }

      setError(err);
      Datadog.error("useGateway() error", { error: err });
    });

    const client = new GatewayClient(
      currentUser?.accessToken,
      currentUser?.customer.id,
      {
        fatal: Datadog.fatal,
        error: Datadog.error,
        warn: Datadog.warn,
        info: Datadog.info,
        debug: Datadog.debug,
      }
    );

    client.addEventListener("error", onError);

    const method = async () => {
      await fn(client);
    };

    method()
      .then(() => setLoading(true))
      .catch((err) => onError(err));
  }, deps);

  return [loading, error];
}

//  onGateway()
//
// Construct a method that wraps all the logic used in useGateway but that is suitable to be called
// from callback handlers such as DOM events. First argument is an async method that accepts an
// instantiated gateway client. Returns a function that binds fn and handles common error cases.
export function onGateway(fn: OnGatewayFunction) {
  const { currentUser } = useAuth();

  const client = new GatewayClient(
    currentUser?.accessToken,
    currentUser?.customer.id,
    {
      fatal: Datadog.fatal,
      error: Datadog.error,
      warn: Datadog.warn,
      info: Datadog.info,
      debug: Datadog.debug,
    }
  );

  const onError = once((err) => {
    // Authentication issue, so we just reload here.
    if (err?.code === 16) {
      Datadog.warn("useGatewayHandler() E16 unauthenticated", { error: err });
      Location.reload();
    }

    Datadog.error("useGatewayHandler() error", { error: err });
  });

  client.addEventListener("error", onError);

  const method = async (...args) => {
    try {
      await fn(client, ...args);
    } catch (err) {
      onError(err);
    }
  };

  return method;
}
