import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { getConfig } from "lib/web/config";
import { redirect } from "src/utils/location";

export const ID_TOKEN_COOKIE = "hecate_id_token";
export const ACCESS_TOKEN_COOKIE = "hecate_access_token";
export const REFRESH_TOKEN_COOKIE = "hecate_refresh_token";
export const AUTHORIZED_USER_COOKIE = "authorized_user";

// Validate a users access token. Checks that it exists, checks if it's expired.
export function validateToken() {
  const accessTokenBase64 = Cookies.get(ACCESS_TOKEN_COOKIE);

  if (!accessTokenBase64) {
    return false;
  }

  const accessToken = jwtDecode(accessTokenBase64);

  // R if their access token has not expired.
  return accessToken && accessToken.exp > moment().unix();
}

// Called to retrieve the authentication token. This will determine if they're logged in or
// not.
export async function getToken() {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE);
  const authorizedUser = Cookies.get(AUTHORIZED_USER_COOKIE);

  if (accessToken && authorizedUser) {
    return `${accessToken};${authorizedUser}`;
  }

  return accessToken;
}

// Called to retrieve user metadata. Returns metadata if the user is logged in and null if they
// are not logged in.
// NOTE: This must return null if getToken() returns null!
export async function getUserMetadata() {
  return await getToken();
}

// Called when requested to log in
export async function login() {
  throw new Error("Login not implemented");
}

// Called when requested to log out
export async function logout() {
  const config = getConfig();

  const idToken = Cookies.get(ID_TOKEN_COOKIE);

  Cookies.remove(ID_TOKEN_COOKIE);
  Cookies.remove(ACCESS_TOKEN_COOKIE);
  Cookies.remove(REFRESH_TOKEN_COOKIE);

  redirect(config.hecateUrl + `/connect/endsession?id_token_hint=${idToken}`);
}

export const createClient = (client) => {
  return {
    type: "custom",
    client,
    login,
    logout,
    getToken,
    getUserMetadata,
    restoreAuthState: async () => {},
  };
};
