import { ComponentProps, forwardRef } from "react";
import { Select as MuiSelect } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { MainColors } from "src/colors";

type Props = ComponentProps<typeof MuiSelect> & {
  color?: MainColors;
  fontSize?: string;
  padding?: string;
};

const OutlinedSelect = styled(MuiSelect)`
  &&& {
    .MuiSelect-select {
      font-size: ${(p) => p.$fontSize};
      padding-top: ${(p) => p.$padding};
      padding-bottom: ${(p) => p.$padding};
    }

    :not(.Mui-disabled):not(.Mui-error) {
      &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
        border-color: ${(p) => p.$palette.dark};
      }

      fieldset {
        border-color: ${(p) => p.$palette.main};
      }

      &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${(p) => p.$palette.dark};
      }
    }

    &.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: ${(p) => p.$errorColor};
    }
  }
`;

const Select = forwardRef((props: Props, ref) => {
  const { color, fontSize, padding, ...baseProps } = props;
  const theme: any = useTheme();
  const palette = !color ? theme.palette.primary : theme.palette[color];
  const errorColor = theme.palette.error.main;

  switch (baseProps.variant) {
    case "outlined":
      return (
        <OutlinedSelect
          {...baseProps}
          ref={ref}
          $palette={palette}
          $errorColor={errorColor}
          $fontSize={fontSize}
          $padding={padding}
        />
      );
    default:
      return <MuiSelect {...baseProps} ref={ref} color={color} />;
  }
});

export default Select;
