import { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import { primaryColor } from "src/colors";
import { Typography } from ".";

type Props = {
  children: ReactNode;
  icon?: ReactNode;
  paddingBottom?: string | number;
};

const MuiCardHeaderRoot = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  padding-bottom: ${(p) => p.$paddingBottom};

  .icon {
    width: 1.625rem;
    height: 1.625rem;
    fill: ${primaryColor};
  }
`;

const MuiCardHeaderContent = styled.div`
  flex: 1 1 auto;
`;

const CardHeader = forwardRef((props: Props, ref) => {
  const { paddingBottom, icon, children } = props;

  return (
    <MuiCardHeaderRoot
      ref={ref}
      className="MuiCardHeader-root"
      $paddingBottom={paddingBottom}
    >
      <MuiCardHeaderContent className="MuiCardHeader-content">
        <Typography variant="display3">{children}</Typography>
      </MuiCardHeaderContent>

      {icon}
    </MuiCardHeaderRoot>
  );
});

export default CardHeader;
