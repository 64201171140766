import { createTheme } from "@material-ui/core/styles";
import { useAuth } from "@redwoodjs/auth";
import { routes, navigate } from "@redwoodjs/router";
import { Toaster } from "@redwoodjs/web/toast";
import assignment from "assignment";
import { useEffectOnce } from "usehooks-ts";
import { getConfig } from "lib/web/config";
import { Box, ThemeProvider, Tooltip } from "src/components/material-ui";
import {
  HomeIcon,
  NumbersIcon,
  PlusRoundIcon,
} from "src/components/material-ui/icons";
import SideBar from "src/components/SideBar";
import NavItem from "src/components/SideBar/NavItem";
import TopBar from "src/components/TopBar";
import * as Colors from "src/colors";
import * as CustomersCache from "src/utils/contexts/customersCache";
import * as NumbersCache from "src/utils/contexts/numbers-tool/numbersCache";
import * as SessionState from "src/utils/hooks/sessionState";

const internalTheme = {
  palette: {
    text: {
      secondary: Colors.neutral50,
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        fontSize: "1rem",
        fontWeight: 400,
      },
    },
  },
};

const DataCacheInit = () => {
  const { currentUser } = useAuth();
  const loadAllNumbers = currentUser?.roles?.includes(
    "ctap:feature:numbers-tool:load-all-numbers"
  );

  const customersInfo = CustomersCache.useCustomersInfo();
  const numbersInfo = NumbersCache.useNumbersInfo();

  useEffectOnce(() => {
    customersInfo.init();
    numbersInfo.init({
      offline: loadAllNumbers,
      fetchData: loadAllNumbers ? NumbersCache.fetchData : undefined,
    });
  });

  return null;
};

const NumbersToolLayout = ({ children }) => {
  const config = getConfig();

  useEffectOnce(() => {
    window.document.title = "Customer Numbers Tool";
  });

  return (
    <ThemeProvider
      theme={(theme) => createTheme(assignment(theme, internalTheme))}
    >
      <SessionState.Provider>
        <CustomersCache.Provider>
          <NumbersCache.Provider>
            <DataCacheInit />
            <Toaster
              position="top-right"
              toastOptions={{
                custom: { duration: 5000 },
                error: { duration: 20000 },
                success: { duration: 5000 },
              }}
            />

            <TopBar>
              <Tooltip title="My new number requests" variant="numbers-tool">
                <Box ml={2}>
                  <i
                    className="icon-number-sync"
                    style={{
                      color: Colors.primaryColor,
                      fontSize: "26px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(routes.numbersToolSavedNew())}
                  />
                </Box>
              </Tooltip>
            </TopBar>

            <SideBar>
              <NavItem icon={<HomeIcon />} to={config.ctmpUrl}>
                Back To CTMP
              </NavItem>

              <NavItem icon={<NumbersIcon />} to={routes.numbersToolHome()}>
                View Numbers
              </NavItem>

              <NavItem icon={<PlusRoundIcon />} to={routes.numbersToolNew()}>
                Add Numbers
              </NavItem>
            </SideBar>

            <Box mt={20} ml={16} mr={2}>
              {children}
            </Box>
          </NumbersCache.Provider>
        </CustomersCache.Provider>
      </SessionState.Provider>
    </ThemeProvider>
  );
};

export default NumbersToolLayout;
