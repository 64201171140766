import { createElement, CSSProperties } from "react";
import classNames from "classnames";
import styled from "styled-components";
import { CountryCode } from "src/i18n";
import Bill from "./icons/bill.svg";
import Bookmark from "./icons/bookmark.svg";
import Building from "./icons/building.svg";
import Close from "./icons/close.svg";
import DeviceHandset from "./icons/device-handset.svg";
import FileUpload from "./icons/file-upload.svg";
import Help from "./icons/help.svg";
import Home from "./icons/home.svg";
import MagicWand from "./icons/magic-wand.svg";
import MinusRound from "./icons/minus-round.svg";
import Numbers from "./icons/numbers.svg";
import Options from "./icons/options.svg";
import PlusRound from "./icons/plus-round.svg";
import Report from "./icons/report.svg";
import Save from "./icons/save.svg";
import Settings from "./icons/settings.svg";
import Trash from "./icons/trash.svg";
import Upload from "./icons/upload.svg";
import Warning from "./icons/warning.svg";
import Tools from "./icons/tools.svg";
import EditSipTrunkCircle from "./icons/sip-group-circle.svg";
import EditSipTrunk from "./icons/sip-group.svg";
import Copy from "./icons/copy.svg";
import SipTrunkMenu from "./icons/siptrunk-menu.svg";

// Icons can be exported from the font via this tool.
// https://iconly.io/tools/font-to-icons-converter

export type Props = {
  className?: string;
  color?: string;
  fill?: string;
  height?: string;
  onClick?: () => void;
  style?: CSSProperties;
  width?: string;
};

export const buildIcon = (icon) => (props: Props) => {
  const height = props.height ?? (!props.height && props.width);
  const width = props.width ?? (!props.width && props.height);
  const fill = props.fill ?? props.color;
  const className = classNames("icon", props.className);

  return createElement(icon, { ...props, className, fill, width, height });
};

export type FlagIconProps = {
  country: CountryCode;
};

const FlagImg = styled.img`
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  width: 21px;
  height: 15px;
`;

export const FlagIcon = ({ country }: FlagIconProps) => {
  return <FlagImg src={`/flags/${country}.svg`} />;
};

export const BillIcon = buildIcon(Bill);
export const BookmarkIcon = buildIcon(Bookmark);
export const BuildingIcon = buildIcon(Building);
export const CloseIcon = buildIcon(Close);
export const DeviceHandsetIcon = buildIcon(DeviceHandset);
export const FileUploadIcon = buildIcon(FileUpload);
export const HelpIcon = buildIcon(Help);
export const HomeIcon = buildIcon(Home);
export const MagicWandIcon = buildIcon(MagicWand);
export const MinusRoundIcon = buildIcon(MinusRound);
export const NumbersIcon = buildIcon(Numbers);
export const OptionsIcon = buildIcon(Options);
export const PlusRoundIcon = buildIcon(PlusRound);
export const ReportIcon = buildIcon(Report);
export const SaveIcon = buildIcon(Save);
export const SettingsIcon = buildIcon(Settings);
export const TrashIcon = buildIcon(Trash);
export const UploadIcon = buildIcon(Upload);
export const WarningIcon = buildIcon(Warning);
export const ToolsIcon = buildIcon(Tools);
export const EditSipTrunkCircledIcon = buildIcon(EditSipTrunkCircle);
export const EditSipTrunkIcon = buildIcon(EditSipTrunk);
export const CopyIcon = buildIcon(Copy);
export const SipTrunkMenuIcon = buildIcon(SipTrunkMenu);
