import { ComponentProps, ReactNode, forwardRef } from "react";
import { CircularProgress as MuiCircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { MainColors } from "src/colors";

type Props = Omit<ComponentProps<typeof MuiCircularProgress>, "color"> & {
  color?: MainColors;
  children?: ReactNode;
  radius?: number;
  thickness?: number;
};

const StyledCircularProgress = styled(MuiCircularProgress)`
  &&& {
    color: ${(p) => p.$palette.main};

    & circle {
      ${(p) => (p.$radius ? `r: ${p.$radius}px;` : "")}
      ${(p) => (p.$thickness ? `stroke-width: ${p.$thickness}px;` : "")}
    }
  }
`;

const Label = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CircularProgress = forwardRef((props: Props, ref) => {
  const { color, children, size, radius, thickness, ...baseProps } = props;
  const theme: any = useTheme();
  const palette = !color ? theme.palette.primary : theme.palette[color];

  if (!children) {
    return (
      <StyledCircularProgress
        {...baseProps}
        size={size}
        ref={ref}
        $palette={palette}
        $thickness={thickness}
        $radius={radius}
      />
    );
  }

  return (
    <div style={{ position: "relative", width: size, height: size }}>
      <StyledCircularProgress
        {...baseProps}
        size={size}
        ref={ref}
        $palette={palette}
        $thickness={thickness}
        $radius={radius}
      />
      <Label>{children}</Label>
    </div>
  );
});

export default CircularProgress;
