import { ReactNode } from "react";
import { navigate, useLocation } from "@redwoodjs/router";
import classNames from "classnames";
import styled from "styled-components";
import { Box, Typography } from "src/components/material-ui";
import * as Colors from "src/colors";
import HoverItem from "./HoverItem";
import HighlightBar from "./HighlightBar";
import { SideBarContext } from "./SideBarContext";

const NavItemContainer = styled.div`
  cursor: pointer;
  padding: 0.75rem 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: ${Colors.hoverActivityColor};

    .hover-menu {
      display: flex;
    }
  }

  .expanded-text:hover {
    color: ${Colors.activityColor};
  }

  .expanded-text {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
  }

  &.highlighted {
    color: ${Colors.activityColor};
    background-color: ${Colors.hoverActivityColor};
  }

  .icon {
    fill: ${Colors.primaryColor};
    width: 1.625rem;
    height: 1.625rem;
  }
`;

export type Props = {
  children: ReactNode;
  icon: ReactNode;
  to: string;
};

const NavItem = ({ children, icon, to }: Props) => {
  const { pathname } = useLocation();
  const highlighted = to === pathname;

  const onClick = () => {
    if (/^http(s?):\/\/.*$/.test(to)) {
      // eslint-disable-next-line no-restricted-properties
      window.location.href = to;
    } else {
      navigate(to);
    }
  };

  return (
    <NavItemContainer className={classNames({ highlighted })} onClick={onClick}>
      <SideBarContext.Consumer>
        {(value) => (
          <>
            {highlighted && <HighlightBar />}
            <Box mr={4} lineHeight={1}>
              {icon}
            </Box>
            {!value.expanded && (
              <HoverItem className={classNames("hover-menu", { highlighted })}>
                <Typography variant="body2" fontWeight={600}>
                  <span className="text">{children}</span>
                </Typography>
              </HoverItem>
            )}

            {value.expanded && (
              <Typography
                className="expanded-text"
                variant="body2"
                fontWeight={600}
              >
                {children}
              </Typography>
            )}
          </>
        )}
      </SideBarContext.Consumer>
    </NavItemContainer>
  );
};

export default NavItem;
