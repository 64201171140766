import { ComponentProps } from "react";
import { TablePagination as MuiTablePagination } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

type Props = ComponentProps<typeof MuiTablePagination> & {
  component: "div" | "span";
};

const StyledTablePagination = styled(MuiTablePagination)`
  &&& {
    color: ${(p) => p.$palette.main};
  }
`;

export default function TablePagination(props: Props) {
  const { color, ...baseProps } = props;
  const theme: any = useTheme();
  const palette = !color ? theme.palette.primary : theme.palette[color];

  return <StyledTablePagination {...baseProps} $palette={palette} />;
}
