import GraphqlClient from "lib/web/graphql-client";
import { customerHasEntitlement } from "lib/web/hecate";
import { buildView, createDataCache } from "src/utils/contexts/dataCache";

export type Product = {
  type: string;
  defaultRedirectUrl: string;

  createdAt: string;
  updatedAt: string;
};

export type CustomerRow = {
  id: string;
  name: string;
  entitlements;
};

const QUERY = gql`
  query NumbersToolCustomersCacheQuery(
    $filter: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    customers: getCustomers(
      filter: $filter
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      items {
        id
        name
        entitlements {
          id
          product {
            type
          }
          microsoftTeamsTenancyId
        }
      }
      totalItemCount
      pageCount
      pageNumber
    }
  }
`;

export async function fetchData(
  { graphql }: { graphql: GraphqlClient },
  pageSize: number,
  pageNumber: number
): Promise<{ hasNextPage: boolean; rows: CustomerRow[] }> {
  const response = await graphql.fetch(
    QUERY,
    {
      filter: "",
      pageNumber,
      pageSize,
    },
    "NumbersToolCustomersCacheQuery"
  );

  const {
    data: {
      customers: { items: customers, pageCount: count, pageNumber: number },
    },
  } = response;

  return {
    hasNextPage: number < count,
    rows: customers.filter(customerHasEntitlement).map((customer) => ({
      id: customer.id,
      name: customer.name,
      entitlements: customer.entitlements,
    })),
  };
}

const customers = createDataCache<CustomerRow>("customers", fetchData);

customers.registerView(
  "orderby_name",
  buildView<CustomerRow>("single_property_ordering")("name")
);

export const Provider = customers.provider;
export const registerCustomersInsight = customers.registerInsight;
export const registerCustomersView = customers.registerView;
export const useCustomers = customers.useData;
export const useCustomersInfo = customers.useInfo;
export const useCustomersInsight = customers.useInsight;
