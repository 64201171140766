import { ComponentProps } from "react";
import { InputLabel as MuiInputLabel } from "@material-ui/core";
import styled from "styled-components";
import { MainColors } from "src/colors";
import * as Colors from "src/colors";

type Props = ComponentProps<typeof MuiInputLabel> & {
  color?: MainColors;
  name?: string;
};

const StyledInputLabel = styled(MuiInputLabel)`
  &&& {
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 0.25rem;
    line-height: 1.25rem;
    color: ${Colors.neutral40};

    &.Mui-error {
      color: ${Colors.errorColor};
    }

    &.Mui-disabled {
      color: ${Colors.neutral50};
    }

    &.Mui-focused {
      color: ${Colors.secondaryColor};
    }
  }
`;

export default function InputLabel(props: Props) {
  const { ...baseProps } = props;

  return <StyledInputLabel {...baseProps} />;
}
