import { ComponentProps } from "react";
import classNames from "classnames";
import { Chip as MuiChip, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import * as Colors from "src/colors";

type Props = ComponentProps<typeof MuiChip> & {
  disabled?: boolean;
  title?: any;
};

const StyledChip = styled(MuiChip)`
  &&& {
    color: ${Colors.neutral100};
    background-color: ${Colors.secondaryLightColor};
    border: 0.0625rem solid ${Colors.primaryColor};
    border-radius: 0.125rem;
    font-weight: 600;
    margin: 0.2rem;
    font-size: 0.875rem;

    &:focus {
      background-color: ${Colors.secondaryLightColor};
    }

    &.MuiChip-root {
      height: auto;
    }

    .MuiChip-label {
      padding: 0.3rem 0.6rem 0.3rem 0.3rem;
      white-space: normal;
      word-wrap: break-word;
    }

    .MuiChip-deleteIcon {
      cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
      color: ${(props) =>
        props.isDisabled ? Colors.neutral30 : Colors.neutral100};
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 0;
    }

    i {
      color: ${Colors.neutral100};
      font-size: 1rem;
      position: relative;
      top: 0.1rem;
      background-color: unset;
      padding: unset;
      border-radius: unset;
    }

    &.disabled {
      .MuiChip-deleteIcon {
        display: none;
      }

      opacity: 0.6;
    }
  }
`;

export default function CtapChip(props: Props) {
  const { title, disabled, ...baseProps } = props;

  return title ? (
    <Tooltip title={title} placement="bottom" arrow>
      <StyledChip {...baseProps} className={classNames({ disabled })} />
    </Tooltip>
  ) : (
    <StyledChip {...baseProps} className={classNames({ disabled })} />
  );
}
