import { ComponentProps, forwardRef, ReactNode } from "react";
import { CardContent as MuiCardContent } from "@material-ui/core";
import styled from "styled-components";

type Props = ComponentProps<typeof MuiCardContent> & {
  paddingBottom?: string;
  children: ReactNode;
};

const StyledCard = styled(MuiCardContent)`
  &&& {
    padding-bottom: ${(p) => p.$paddingBottom};
  }
`;

const CardContent = forwardRef((props: Props, ref) => {
  const { paddingBottom, children, ...baseProps } = props;

  return (
    <StyledCard
      {...baseProps}
      ref={ref}
      component="div"
      $paddingBottom={paddingBottom}
    >
      {children}
    </StyledCard>
  );
});

export default CardContent;
