import fetch from "cross-fetch";
import Cookies from "js-cookie";
import {
  ID_TOKEN_COOKIE,
  ACCESS_TOKEN_COOKIE,
  REFRESH_TOKEN_COOKIE,
  validateToken,
} from "src/auth/hecate";
import * as Datadog from "src/utils/datadog";
import { redirect } from "src/utils/location";
import packageInfo from "src/../../package.json";
import { getVersion } from "./versionChecker";

const fetchReauthenticate = async (setReauthenticated) => {
  const serverVersion = await getVersion();
  const version = packageInfo.version;
  if (serverVersion && version && serverVersion !== version) {
    Datadog.warn("Browser version is outdated", { serverVersion, version });
    setReauthenticated(false);
    return redirect("/login?redirectTo=/");
  }

  if (validateToken()) {
    return;
  }

  setReauthenticated(false);

  // This checks for authentication and refreshes the tokens if required
  return fetch("/api/reauthenticate")
    .then((res) => {
      if (res.status === 500) {
        throw res.status;
      }

      if (res.status === 400 || res.status === 401) {
        Cookies.remove(ID_TOKEN_COOKIE);
        Cookies.remove(ACCESS_TOKEN_COOKIE);
        Cookies.remove(REFRESH_TOKEN_COOKIE);
      }

      setReauthenticated(true);
    })
    .catch((error) => {
      Cookies.set("login_error", true);
      Datadog.error("/reauthenticate call error", { error });

      return redirect("/500");
    });
};

export default fetchReauthenticate;
