import { ComponentProps, forwardRef } from "react";
import { Switch as MuiSwitch } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { MainColors, MessageColors } from "src/colors";

type Props = Omit<ComponentProps<typeof MuiSwitch>, "color"> & {
  color?: MainColors | MessageColors;
};

const StyledSwitch = styled(MuiSwitch)`
  &&&:not(.Mui-disabled) {
    .thumb {
      color: ${(p) => p.$palette.main};
    }

    .track {
      background: ${(p) => p.$palette.light};
    }
  }
`;

const Switch = forwardRef((props: Props, ref) => {
  const { color, ...baseProps } = props;
  const theme: any = useTheme();
  let palette = !color ? theme.palette.primary : theme.palette[color];

  if (!props.checked) {
    palette = {
      main: "#fafafa",
      light: "#000",
    };
  }

  return (
    <StyledSwitch
      classes={{ thumb: "thumb", track: "track" }}
      {...baseProps}
      $palette={palette}
      ref={ref}
    />
  );
});

export default Switch;
