import {
  Children,
  ReactNode,
  cloneElement,
  createContext,
  useContext,
  useMemo,
  CSSProperties,
} from "react";
import styled, { css } from "styled-components";
import * as Colors from "src/colors";
import Typography, { TypographyVariants } from "./Typography";

export const tableContext = createContext({
  columns: 0,
  striped: false,
});

export const Container = styled.div`
  /* overflow-x: scroll; */
  /* overflow-y: hidden; */
`;

export const Body = styled.div`
  display: grid;
  grid-auto-rows: minmax(1px, auto);
  grid-template-columns: ${(props) => props.$columns};
  min-width: 1148px;

  padding: 0.5rem 0;
`;

export type TableProps = {
  children: ReactNode;
  columns: string;
  striped?: boolean;
};

export const Table = ({ children, columns, striped }: TableProps) => {
  const numColumns = columns.split(" ").length;

  const value = useMemo(
    () => ({
      columns: numColumns,
      striped,
    }),
    [numColumns, striped]
  );

  return (
    <tableContext.Provider value={value}>
      <Container>
        <Body $columns={columns}>{children}</Body>
      </Container>
    </tableContext.Provider>
  );
};

const Line = styled.div`
  grid-column: 1 / -1;
  height: 1px;
  background: ${Colors.neutralE4};
`;

export type RowProps = {
  children: ReactNode;
  onClick?: () => void;
};

export const Row = ({ children, onClick }: RowProps) => {
  let cells = children;

  if (Array.isArray(children)) {
    cells = Children.map(
      children.filter((c) => c),
      (component) => cloneElement(component, { onClick })
    );
  }

  return (
    <>
      {cells}

      <Line className="line" />
    </>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  padding: 0.5rem;
  word-break: break-word;
  flex-direction: row;
  justify-content: left;
`;

export type HeaderProps = {
  children?: ReactNode;
  shrink?: boolean;
  unstyled?: boolean;
};

export const Header = ({
  children,
  shrink = false,
  unstyled = false,
}: HeaderProps) => {
  if (unstyled) {
    return (
      <HeaderWrapper $padding={!shrink ? "1rem 1rem" : "1rem 0"}>
        {children}
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper $padding={!shrink ? "1rem 1rem" : "1rem 0"}>
      <Typography variant="body2" fontWeight={600}>
        {children}
      </Typography>
    </HeaderWrapper>
  );
};

const CellWrapper = styled.div`
  min-height: 1rem;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* This selector is generated to select even rows in the table. For a table with 4 columns it
   * should generate the following:
   *
   *   &:nth-child(10n+1),
   *   &:nth-child(10n+2),
   *   &:nth-child(10n+3),
   *   &:nth-child(10n+4)
   *
   * The cell multiplier of 10 is calculated as 2*columns + 2, because there are 4 cells + 1 line
   * element in each row (5 elements) and we want every 10th cell offset (2 rows) to be selected. */
  ${(p) =>
    p.$striped &&
    css`
      background: ${Colors.neutral80};

      ${(p) => p.$selector} {
        background: ${Colors.neutral100};
      }
    `}

  padding: ${(props) => (props.padding ? props.padding : "0.5rem")};
`;

export type CellProps = {
  children?: ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
  unwrapped?: boolean;
  variant?: TypographyVariants;
  padding?: string;
};

export const Cell = ({
  children,
  onClick,
  unwrapped,
  variant = "body2",
  style,
  padding,
}: CellProps) => {
  const { columns, striped } = useContext(tableContext);
  const selector = [];

  for (let i = 1; i <= columns; i++) {
    selector.push(`&:nth-child(${2 * columns + 2}n+${i})`);
  }

  const $selector = selector.join(", ");

  return (
    <CellWrapper
      onClick={onClick}
      $selector={$selector}
      $striped={striped}
      style={style}
      padding={padding}
    >
      {!unwrapped && <Typography variant={variant}>{children}</Typography>}

      {unwrapped && children}
    </CellWrapper>
  );
};

const GridTable = () => {};

export default GridTable;
