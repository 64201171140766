import { ComponentProps } from "react";
import { TableSortLabel as MuiTableSortLabel } from "@material-ui/core";
import styled from "styled-components";
import * as Colors from "src/colors";

type Props = ComponentProps<typeof MuiTableSortLabel>;

const StyledTableSortLabel = styled(MuiTableSortLabel)`
  &&&& {
    color: ${Colors.neutral10};
    font-size: 0.875rem;
    font-weight: 600;
    /* width: 100%; */

    &.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
      .MuiTableSortLabel-icon {
      color: ${Colors.neutral10};
    }

    &:hover {
      color: ${Colors.neutral10};
    }
  }
`;

export default function TableSortLabel(props: Props) {
  return <StyledTableSortLabel {...props} />;
}
