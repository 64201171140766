import fetch from "cross-fetch";
class GraphqlClient {
  accessToken;
  constructor(token) {
    this.accessToken = token;
  }
  async fetch(query, variables, name) {
    const response = await fetch("/api/graphql", {
      method: "POST",
      headers: {
        "auth-provider": "custom",
        authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache"
      },
      body: JSON.stringify({
        operationName: name,
        variables,
        query
      })
    });
    return response.json();
  }
}
export {
  GraphqlClient as default
};
