import { AllocationStatus } from "lib/web/numbers";
import * as SitesCache from "./sitesCache";
import * as NumbersCache from "./numbersCache";

SitesCache.__cache__.registerForeignRef(NumbersCache.__cache__);

SitesCache.__cache__.registerComputed("numbers.assigned", (row, ref) => {
  row.numbers.assigned = ref("numbers")
    .filterByProperty("site", row.name)
    .filter(
      (row) =>
        row.status === AllocationStatus.Allocated ||
        row.status === AllocationStatus.Reserved ||
        row.status === AllocationStatus.Pending ||
        row.status === AllocationStatus.NotApplicable ||
        row.status === AllocationStatus.Unknown
    )
    .count();
});
SitesCache.__cache__.registerComputed("numbers.unassigned", (row, ref) => {
  row.numbers.unassigned = ref("numbers")
    .filterByProperty("site", row.name)
    .filterByProperty("status", AllocationStatus.Unallocated)
    .count();
});
SitesCache.__cache__.registerComputed("numbers.total", (row, ref) => {
  row.numbers.total = ref("numbers").filterByProperty("site", row.name).count();
});
