import * as MuiColors from "@material-ui/core/colors";
import * as Polished from "polished";
import { darken, lighten } from "src/components/material-ui";

// Main Palette Colors
export const primaryColor = "#5d7987";
export const secondaryColor = "#847BA0";
export const tertiaryColor = "#76b246";
export const quaternaryColor = "#292e37";

// Light versions of primary palette
export const primaryLightColor = lighten(primaryColor, 0.2);
export const secondaryLightColor = lighten(secondaryColor, 0.1);
export const tertiaryLightColor = "#99ca7f";
export const quaternaryLightColor = lighten(quaternaryColor, 0.1);

// Dark versions of primary palette
export const primaryDarkColor = darken(primaryColor, 0.2);
export const secondaryDarkColor = darken(secondaryColor, 0.2);
export const tertiaryDarkColor = darken(tertiaryColor, 0.2);
export const quaternaryDarkColor = darken(quaternaryColor, 0.2);

// Grays and Neutrals
export const neutral0 = "#000";
export const neutral10 = "#333";
export const neutral20 = "#444";
export const neutral30 = "#5c5c5c";
export const neutral40 = "#666";
export const neutral50 = "#999";
export const neutral60 = "#ccc";
export const neutral70 = "#e6e6e6";
export const neutral80 = "#eee";
export const neutral90 = "#fcfcfc";
export const neutral100 = "#fff";

export const neutralC2 = "#c2c2c2";
export const neutralE4 = "#e4e4e4";
export const neutralF5 = "#f5f5f5";

// Visual Messaging
export const errorColor = "#d6202c";
export const lightenedErrorColor = "#ec9a9f";
export const warningColor = "#ff9600";
export const successColor = "#329600";
export const lightenedSuccessColor = "#c1dcab";
export const activityColor = "#2e99ce";
export const lightenedActivityColor = "#96cce7";
export const darkActivityColor = "#206b90";

// Hover highlights
export const hoverActivityColor = Polished.lighten(0.47, activityColor);

// Shadows
export const dropShadowColor = "rgba(0, 0, 0, 0.1)";

// Typescript types
export type MessageColors = "error" | "warning" | "info" | "success";
export type MainColors = "primary" | "secondary" | "tertiary" | "quaternary";
export type GrayColors = "neutralDark" | "neutralLight";
export type TextMainColors = "textPrimary" | "textSecondary" | "textDisabled";
export type TextMessageColors =
  | "textError"
  | "textWarning"
  | "textInfo"
  | "textSuccess";
export type TextGrayColors = "neutralDark" | "neutralLight";
export type Colors = MainColors | MessageColors | GrayColors;

export const PASTEL_COLORS = [
  MuiColors.blue,
  MuiColors.cyan,
  MuiColors.green,
  MuiColors.indigo,
  MuiColors.lime,
  MuiColors.orange,
  MuiColors.pink,
  MuiColors.purple,
  MuiColors.red,
  MuiColors.teal,
  MuiColors.yellow,
].map((c) => lighten(c[100], 0.3));

export const strHash = (str) => {
  let hash = 0,
    i,
    chr;

  if (str.length === 0) {
    return hash;
  }

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash & 0xffff;
};

export const colorHash = (str, colorMap = []) => {
  return colorMap[strHash(str) % colorMap.length];
};
