import { ComponentProps } from "react";
import { LinearProgress as MuiLinearProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { MainColors, MessageColors } from "src/colors";

type Props = ComponentProps<typeof MuiLinearProgress> & {
  color?: MainColors | MessageColors | "neutral";
};

const StyledLinearProgress = styled(MuiLinearProgress)`
  &&& {
    background-color: ${(p) => p.$palette.light};

    /* .MuiLinearProgress-barColorPrimary { */
    & > div {
      background-color: ${(p) => p.$palette.dark};
    }
  }
`;

export default function LinearProgress(props: Props) {
  const { color, ...baseProps } = props;
  const theme: any = useTheme();
  const palette = !color ? theme.palette.primary : theme.palette[color];

  return <StyledLinearProgress {...baseProps} $palette={palette} />;
}
