import styled from "styled-components";
import * as Colors from "src/colors";

const HamburgerSlice = styled.div`
  background-color: ${Colors.primaryColor};
  border-radius: 2px;
  height: 3px;
  width: 1.25rem;

  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;

const HamburgerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;

  &:hover div {
    background-color: ${Colors.primaryLightColor};
  }
`;

const Hamburger = ({ onClick }) => {
  return (
    <HamburgerContainer onClick={onClick}>
      <HamburgerSlice />
      <HamburgerSlice />
      <HamburgerSlice />
    </HamburgerContainer>
  );
};

export default Hamburger;
