import GatewayClient, {
  ProvisioningStatus,
  ServiceUserType,
} from "lib/web/gateway";
import { t } from "src/i18n";
import {
  buildInsight,
  buildOrderer,
  buildView,
  createDataCache,
} from "src/utils/contexts/dataCache";

export type ServiceUserRow = {
  id: string;
  /**
   * Phone number associated with this user. This number is a primary key for the numbers cache.
   */
  ddi: string;
  /** Disaster recovery number (DRN) for this user. */
  drn?: string;
  name: string;
  site?: string;
  status: ProvisioningStatus;
  type: ServiceUserType;
  type_t: string /* @computed */;
  upn: string;
  siteId?: string;
};

export async function fetchData(
  { gateway }: { gateway: GatewayClient },
  pageSize: number,
  pageNumber: number
): Promise<{ hasNextPage: boolean; rows: ServiceUserRow[] }> {
  const results = await gateway.listServiceUsers(pageNumber - 1, pageSize);
  const rows: ServiceUserRow[] = results.serviceUsersList.map(
    (serviceUser) => ({
      id: serviceUser.id,
      ddi: serviceUser.ddi?.value,
      name: serviceUser.name,
      status: serviceUser.provisioningStatus as number as ProvisioningStatus,
      upn: serviceUser.upn,
      site: serviceUser.siteName?.value,
      drn: serviceUser.disasterRecoveryNumber?.value,
      type: serviceUser.serviceUserType as number as ServiceUserType,
      type_t: t(
        `serviceUserType${ServiceUserType[serviceUser.serviceUserType]}`
      ),
      siteId: serviceUser.siteId && serviceUser.siteId?.value,
    })
  );

  return {
    hasNextPage: results.paginationInfo?.hasNextPage,
    rows,
  };
}

const serviceUsers = createDataCache<ServiceUserRow>(
  "serviceUsers",
  fetchData,
  {
    first: 100,
    all: 999,
  }
);

serviceUsers.registerComputed("type_t", (row) => {
  row.type_t = t(`serviceUserType${ServiceUserType[row.type]}`);
});

serviceUsers.registerView(
  "add_service_tab",
  buildView<ServiceUserRow>("single_property_filter")("status", [
    ProvisioningStatus.Ready,
    ProvisioningStatus.IncorrectLicense,
    ProvisioningStatus.Failed,
    ProvisioningStatus.InProgress,
    ProvisioningStatus.Undetermined,
    ProvisioningStatus.Invalid,
  ])
);
serviceUsers.registerView(
  "manage_service_tab",
  buildView<ServiceUserRow>("single_property_filter")("status", [
    ProvisioningStatus.Provisioned,
    ProvisioningStatus.Pending,
    ProvisioningStatus.UnknownNumber,
    ProvisioningStatus.NoOutbound,
    ProvisioningStatus.DeprovisioningInProgress,
  ])
);

serviceUsers.registerView(
  "orderby_name",
  buildView<ServiceUserRow>("single_property_ordering")("name")
);
serviceUsers.registerView(
  "orderby_ddi",
  buildView<ServiceUserRow>("single_property_ordering")("ddi")
);
serviceUsers.registerView(
  "orderby_upn",
  buildView<ServiceUserRow>("single_property_ordering")("upn")
);
serviceUsers.registerView(
  "orderby_type",
  buildView<ServiceUserRow>("single_property_ordering")("type_t")
);
serviceUsers.registerView(
  "orderby_site",
  buildView<ServiceUserRow>("single_property_ordering")("site")
);
serviceUsers.registerInsight(
  "user_type_options",
  buildInsight<ServiceUserRow>("property_enumeration")("type")
);
serviceUsers.registerInsight(
  "site_options",
  buildInsight<ServiceUserRow>("property_enumeration")("site")
);
serviceUsers.registerOrderer(
  "groupby_status_pretty",
  buildOrderer<ServiceUserRow>("map_sort")("status", {
    // Add service tab
    [ProvisioningStatus.Ready]: 1,
    [ProvisioningStatus.Failed]: 1,
    [ProvisioningStatus.InProgress]: 1,
    [ProvisioningStatus.IncorrectLicense]: 2,
    [ProvisioningStatus.Undetermined]: 2,

    // Manage service tab
    [ProvisioningStatus.Provisioned]: 1,
    [ProvisioningStatus.Pending]: 1,
    [ProvisioningStatus.DeprovisioningInProgress]: 1,
    [ProvisioningStatus.NoOutbound]: 1,
    [ProvisioningStatus.UnknownNumber]: 2,

    // We should never see invalid state users, but if we do sort them at the bottom.
    [ProvisioningStatus.Invalid]: 3,
  })
);

export const Provider = serviceUsers.provider;
export const registerServiceUsersInsight = serviceUsers.registerInsight;
export const registerServiceUsersView = serviceUsers.registerView;
export const useServiceUsers = serviceUsers.useData;
export const useServiceUsersInfo = serviceUsers.useInfo;
export const useServiceUsersInsight = serviceUsers.useInsight;
export const useUpdateServiceUser = serviceUsers.useUpdateRow;
