import fetch from "cross-fetch";
import {
  AssignCloudTelephonyRequest,
  DeleteLoginRequest,
  GetCustomerLoginsDto,
  GetLoginRequest,
  GetProfileRequest,
  LoginStatus,
  NullableString,
  SetRolesDto,
  UpdateLoginDto,
  UpdateProfileDto
} from "ct-gateway-clients/v1";
import {
  ListCustomerDDIsRequest,
  ScopeRoles,
  ListSitesRequest,
  GetSiteFiltersRequest,
  CreateSiteRequest,
  GetDDIFiltersRequest,
  GetSiteRequest,
  UpdateSiteRequest,
  ListServiceUsersRequest,
  ProvisionServiceUserWithRandomDdiRequest,
  ProvisionServiceUserWithSpecificDdiRequest,
  UpdateServiceUserRequest,
  AuthenticationMethodType,
  GetTeamsSyncStatusRequest,
  DeprovisionServiceUserRequest,
  GetLoginRequest as GetLoginRequestV2,
  ListCustomerLoginsRequest,
  GetCustomerRequest
} from "ct-gateway-clients/v2";
import * as v1 from "ct-gateway-clients/v1";
import * as v2 from "ct-gateway-clients/v2";
import * as v3 from "ct-gateway-clients/v3";
import { AuthorizationInterceptor } from "ct-gateway-clients/interceptors";
import * as wrapper_pd from "google-protobuf/google/protobuf/wrappers_pb";
import { getConfig } from "./config";
import { capitalize } from "./utils";
const ROLES = [
  "role:global_admin",
  "role:billing_admin",
  "role:reporting_admin",
  "role:telephony_admin"
];
var ProvisioningStatus;
(function(ProvisioningStatus2) {
  ProvisioningStatus2[ProvisioningStatus2["Invalid"] = v2.ProvisioningStatus.PROVISIONING_STATUS_INVALID] = "Invalid";
  ProvisioningStatus2[ProvisioningStatus2["Ready"] = v2.ProvisioningStatus.PROVISIONING_STATUS_READY_TO_PROVISION] = "Ready";
  ProvisioningStatus2[ProvisioningStatus2["Pending"] = v2.ProvisioningStatus.PROVISIONING_STATUS_PENDING_PROVISIONING] = "Pending";
  ProvisioningStatus2[ProvisioningStatus2["InProgress"] = v2.ProvisioningStatus.PROVISIONING_STATUS_PROVISIONING_IN_PROGRESS] = "InProgress";
  ProvisioningStatus2[ProvisioningStatus2["Provisioned"] = v2.ProvisioningStatus.PROVISIONING_STATUS_PROVISIONED] = "Provisioned";
  ProvisioningStatus2[ProvisioningStatus2["Failed"] = v2.ProvisioningStatus.PROVISIONING_STATUS_FAILED] = "Failed";
  ProvisioningStatus2[ProvisioningStatus2["IncorrectLicense"] = v2.ProvisioningStatus.PROVISIONING_STATUS_BLOCKED_INCORRECT_LICENSE] = "IncorrectLicense";
  ProvisioningStatus2[ProvisioningStatus2["UnknownNumber"] = v2.ProvisioningStatus.PROVISIONING_STATUS_BLOCKED_UNKNOWN_NUMBER] = "UnknownNumber";
  ProvisioningStatus2[ProvisioningStatus2["Undetermined"] = v2.ProvisioningStatus.PROVISIONING_STATUS_UNDETERMINED] = "Undetermined";
  ProvisioningStatus2[ProvisioningStatus2["NoOutbound"] = v2.ProvisioningStatus.PROVISIONING_STATUS_NO_OUTBOUND_CLOUD_TELEPHONY] = "NoOutbound";
  ProvisioningStatus2[ProvisioningStatus2["DeprovisioningInProgress"] = v2.ProvisioningStatus.PROVISIONING_STATUS_DEPROVISIONING_IN_PROGRESS] = "DeprovisioningInProgress";
})(ProvisioningStatus || (ProvisioningStatus = {}));
var ServiceUserType;
(function(ServiceUserType2) {
  ServiceUserType2[ServiceUserType2["Invalid"] = v2.ServiceUserType.SERVICE_USER_TYPE_INVALID] = "Invalid";
  ServiceUserType2[ServiceUserType2["User"] = v2.ServiceUserType.SERVICE_USER_TYPE_USER] = "User";
  ServiceUserType2[ServiceUserType2["AutoAttendant"] = v2.ServiceUserType.SERVICE_USER_TYPE_AUTO_ATTENDANT] = "AutoAttendant";
  ServiceUserType2[ServiceUserType2["CallQueue"] = v2.ServiceUserType.SERVICE_USER_TYPE_CALL_QUEUE] = "CallQueue";
  ServiceUserType2[ServiceUserType2["Room"] = v2.ServiceUserType.SERVICE_USER_TYPE_ROOM] = "Room";
  ServiceUserType2[ServiceUserType2["Unknown"] = v2.ServiceUserType.SERVICE_USER_TYPE_UNKNOWN] = "Unknown";
})(ServiceUserType || (ServiceUserType = {}));
var ProvisioningUpdateType;
(function(ProvisioningUpdateType2) {
  ProvisioningUpdateType2[ProvisioningUpdateType2["Invalid"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_INVALID] = "Invalid";
  ProvisioningUpdateType2[ProvisioningUpdateType2["Started"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_STARTED] = "Started";
  ProvisioningUpdateType2[ProvisioningUpdateType2["Pending"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_PENDING] = "Pending";
  ProvisioningUpdateType2[ProvisioningUpdateType2["ReservedDdi"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_RESERVE_DDI_SUCCEEDED] = "ReservedDdi";
  ProvisioningUpdateType2[ProvisioningUpdateType2["Succeeded"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_SUCCEEDED] = "Succeeded";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedNoToken"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_NO_TOKEN] = "FailedNoToken";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedUserHasDdi"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_USER_HAS_DDI] = "FailedUserHasDdi";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedUserNotCapable"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_USER_NOT_CAPABLE] = "FailedUserNotCapable";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedUserDoesntExist"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_USER_DOESNT_EXIST] = "FailedUserDoesntExist";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedDdiDoesntExist"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_DDI_DOESNT_EXIST] = "FailedDdiDoesntExist";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedDdiNotAvailable"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_DDI_NOT_AVAILABLE] = "FailedDdiNotAvailable";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedNoDdiAvailable"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_NO_DDI_AVAILABLE] = "FailedNoDdiAvailable";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedNoCustomerTenant"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_NO_CUSTOMER_TENANT] = "FailedNoCustomerTenant";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedNoVoicePolicy"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_NO_VOICE_ROUTING_POLICY_FOR_DDI] = "FailedNoVoicePolicy";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedDdiInvalid"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_DDI_INVALID_STATE] = "FailedDdiInvalid";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedFinalVerification"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_FINAL_STATE_VERIFICATION] = "FailedFinalVerification";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedSiteDoesntExist"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_SITE_DOESNT_EXIST] = "FailedSiteDoesntExist";
  ProvisioningUpdateType2[ProvisioningUpdateType2["FailedUnknownError"] = v2.ProvisioningUpdateType.PROVISIONING_UPDATE_TYPE_FAILED_UNKNOWN_ERROR] = "FailedUnknownError";
})(ProvisioningUpdateType || (ProvisioningUpdateType = {}));
var DeprovisioningUpdateType;
(function(DeprovisioningUpdateType2) {
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["Invalid"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_INVALID] = "Invalid";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["Started"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_STARTED] = "Started";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["FailedDdiDoesntExist"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_FAILED_DDI_DOESNT_EXIST] = "FailedDdiDoesntExist";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["FailedDdiNotAllocated"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_FAILED_DDI_NOT_ALLOCATED] = "FailedDdiNotAllocated";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["FailedUserMismatch"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_FAILED_SERVICE_USER_MISMATCH] = "FailedUserMismatch";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["FailedUserDoesntExist"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_FAILED_SERVICE_USER_DOESNT_EXIST] = "FailedUserDoesntExist";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["FailedUserHasNoDdi"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_FAILED_SERVICE_USER_DOESNT_HAVE_DDI] = "FailedUserHasNoDdi";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["FailedNoToken"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_FAILED_NO_TOKEN] = "FailedNoToken";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["Succeeded"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_SUCCEEDED] = "Succeeded";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["Pending"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_PENDING] = "Pending";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["RemoveDdi"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_REMOVE_DDI_SUCCEEDED] = "RemoveDdi";
  DeprovisioningUpdateType2[DeprovisioningUpdateType2["FailedUnknownError"] = v2.DeprovisioningUpdateType.DEPROVISIONING_UPDATE_TYPE_FAILED_UNKNOWN_ERROR] = "FailedUnknownError";
})(DeprovisioningUpdateType || (DeprovisioningUpdateType = {}));
var TeamsUserTokenStatus;
(function(TeamsUserTokenStatus2) {
  TeamsUserTokenStatus2[TeamsUserTokenStatus2["TEAMS_USER_TOKEN_STATUS_INVALID"] = 0] = "TEAMS_USER_TOKEN_STATUS_INVALID";
  TeamsUserTokenStatus2[TeamsUserTokenStatus2["TEAMS_USER_TOKEN_STATUS_BROKEN"] = 1] = "TEAMS_USER_TOKEN_STATUS_BROKEN";
  TeamsUserTokenStatus2[TeamsUserTokenStatus2["TEAMS_USER_TOKEN_STATUS_OK"] = 2] = "TEAMS_USER_TOKEN_STATUS_OK";
  TeamsUserTokenStatus2[TeamsUserTokenStatus2["TEAMS_USER_TOKEN_STATUS_NO_TOKEN"] = 3] = "TEAMS_USER_TOKEN_STATUS_NO_TOKEN";
})(TeamsUserTokenStatus || (TeamsUserTokenStatus = {}));
var AuthenticationMethod;
(function(AuthenticationMethod2) {
  AuthenticationMethod2[AuthenticationMethod2["Invalid"] = AuthenticationMethodType.AUTHENTICATION_METHOD_TYPE_INVALID] = "Invalid";
  AuthenticationMethod2[AuthenticationMethod2["None"] = AuthenticationMethodType.AUTHENTICATION_METHOD_TYPE_NONE] = "None";
  AuthenticationMethod2[AuthenticationMethod2["Password"] = AuthenticationMethodType.AUTHENTICATION_METHOD_TYPE_PASSWORD] = "Password";
  AuthenticationMethod2[AuthenticationMethod2["MicrosoftAzureAd"] = AuthenticationMethodType.AUTHENTICATION_METHOD_TYPE_MICROSOFT] = "MicrosoftAzureAd";
})(AuthenticationMethod || (AuthenticationMethod = {}));
var ServiceType;
(function(ServiceType2) {
  ServiceType2[ServiceType2["Invalid"] = v2.ServiceType.SERVICE_TYPE_INVALID] = "Invalid";
  ServiceType2[ServiceType2["DirectRouting"] = v2.ServiceType.SERVICE_TYPE_DIRECT_ROUTING] = "DirectRouting";
  ServiceType2[ServiceType2["OperatorConnect"] = v2.ServiceType.SERVICE_TYPE_OPERATOR_CONNECT] = "OperatorConnect";
  ServiceType2[ServiceType2["SipTrunks"] = v2.ServiceType.SERVICE_TYPE_SIP_TRUNKS] = "SipTrunks";
})(ServiceType || (ServiceType = {}));
const wrapperStr = (str) => {
  const value = new wrapper_pd.StringValue();
  value.setValue(str);
  return value;
};
const wrapperInt = (num) => {
  const value = new wrapper_pd.Int32Value();
  value.setValue(num);
  return value;
};
const request = (name, params) => {
  const req = new v2[`${name}Request`]();
  Object.keys(params).forEach((key) => {
    let value;
    switch (typeof params[key]) {
      case "string":
        value = new wrapper_pd.StringValue();
        value.setValue(params[key]);
        break;
      default:
        value = params[key];
        break;
    }
    req[`set${capitalize(key)}`](value);
  });
  return req;
};
var ResponseKind;
(function(ResponseKind2) {
  ResponseKind2[ResponseKind2["NotSet"] = 0] = "NotSet";
  ResponseKind2[ResponseKind2["Heartbeat"] = 1] = "Heartbeat";
  ResponseKind2[ResponseKind2["Response"] = 2] = "Response";
})(ResponseKind || (ResponseKind = {}));
function createSipEndpointsObject(primaryEndpoint, additionalEndpoints) {
  const sipEndpoints = [];
  sipEndpoints.push(new v3.SipEndPoint().setEndpoint(primaryEndpoint).setPriority(1));
  let priorityCounter = 2;
  if (additionalEndpoints) {
    const extraEndpoints = additionalEndpoints.replace(/\s/g, "").split(",").filter((e) => e);
    if (extraEndpoints.length > 0) {
      extraEndpoints.forEach((endpoint) => {
        sipEndpoints.push(new v3.SipEndPoint().setEndpoint(endpoint).setPriority(priorityCounter));
        priorityCounter++;
      });
    }
  }
  return sipEndpoints;
}
function updateSipEndpointsObject(primaryEndpoint, additionalEndpoints, oldSipEndpoints) {
  const sipEndpoints = [];
  const oldPrimary = oldSipEndpoints.find((oldEndPoint) => oldEndPoint.priority === 1);
  sipEndpoints.push(new v3.SipEndPoint().setEndpoint(primaryEndpoint).setPriority(1).setSipEndpointId(wrapperStr(oldPrimary.sipEndpointId.value)));
  let priorityCounter = 2;
  if (additionalEndpoints) {
    const endPointArray = additionalEndpoints ? additionalEndpoints.replace(/\s/g, "").split(",") : [];
    if (endPointArray.length > 0) {
      endPointArray.forEach((endpoint) => {
        const oldAdditonalEndpoint = oldSipEndpoints.find((endpoint2) => endpoint2.priority === priorityCounter);
        const updatedEp = new v3.SipEndPoint().setEndpoint(endpoint).setPriority(priorityCounter);
        if (oldAdditonalEndpoint) {
          updatedEp.setSipEndpointId(wrapperStr(oldAdditonalEndpoint.sipEndpointId.value));
        }
        sipEndpoints.push(updatedEp);
        priorityCounter++;
      });
    }
  }
  return sipEndpoints;
}
function stringToProtocol(input) {
  switch (input) {
    case "TLS":
      return v3.SipTrunkProtocol.SIP_TRUNK_PROTOCOL_TLS;
    case "TCP":
      return v3.SipTrunkProtocol.SIP_TRUNK_PROTOCOL_TCP;
    case "UDP":
      return v3.SipTrunkProtocol.SIP_TRUNK_PROTOCOL_UDP;
    default:
      return v3.SipTrunkProtocol.SIP_TRUNK_PROTOCOL_INVALID;
  }
}
function stringToTrafficDistributionMethod(input) {
  switch (input) {
    case "Round Robin":
      return v3.SipTrunkTrafficDistributionMethod.SIP_TRUNK_TRAFFIC_DISTRIBUTION_METHOD_ROUNDROBIN;
    case "Failover":
      return v3.SipTrunkTrafficDistributionMethod.SIP_TRUNK_TRAFFIC_DISTRIBUTION_METHOD_FAILOVER;
    default:
      return v3.SipTrunkTrafficDistributionMethod.SIP_TRUNK_TRAFFIC_DISTRIBUTION_METHOD_INVALID;
  }
}
function stringToNumberStatus(input) {
  switch (input) {
    case 1:
      return v2.NumberStatus.NUMBER_STATUS_ACTIVE;
    case 2:
      return v2.NumberStatus.NUMBER_STATUS_DELETED;
    case 3:
      return v2.NumberStatus.NUMBER_STATUS_INACTIVE;
    case 4:
      return v2.NumberStatus.NUMBER_STATUS_CEASED;
    case 5:
      return v2.NumberStatus.NUMBER_STATUS_PENDING_PORTING_AWAY;
    case 6:
      return v2.NumberStatus.NUMBER_STATUS_PENDING_PORTING_IN;
    case 7:
      return v2.NumberStatus.NUMBER_STATUS_UNKNOWN;
    default:
      return void 0;
  }
}
function stringToCTAllocationStatus(input) {
  switch (input) {
    case 1:
      return v2.CTAllocationStatus.CT_ALLOCATION_STATUS_ALLOCATED;
    case 2:
      return v2.CTAllocationStatus.CT_ALLOCATION_STATUS_NOT_APPLICABLE;
    case 3:
      return v2.CTAllocationStatus.CT_ALLOCATION_STATUS_UNALLOCATED;
    case 4:
      return v2.CTAllocationStatus.CT_ALLOCATION_STATUS_UNKNOWN;
    case 5:
      return v2.CTAllocationStatus.CT_ALLOCATION_STATUS_RESERVED;
    case 6:
      return v2.CTAllocationStatus.CT_ALLOCATION_STATUS_PENDING;
    default:
      return void 0;
  }
}
function stringToCTnumberType(input) {
  switch (input) {
    case 1:
      return v2.CTNumberType.CT_NUMBER_TYPE_GEOGRAPHICAL;
    case 3:
      return v2.CTNumberType.CT_NUMBER_TYPE_NON_GEOGRAPHICAL;
    default:
      return void 0;
  }
}
function stringToHostType(input) {
  switch (input) {
    case 1:
      return v2.HostType.HOST_TYPE_HOSTED;
    case 2:
      return v2.HostType.HOST_TYPE_ON_PREM;
    case 3:
      return v2.HostType.HOST_TYPE_UNKNOWN;
    case 5:
      return v2.HostType.HOST_TYPE_TEAMS_OC;
    case 6:
      return v2.HostType.HOST_TYPE_LMZ;
    case 7:
      return v2.HostType.HOST_TYPE_SIP;
    case 8:
      return v2.HostType.HOST_TYPE_FORWARD_ONLY;
    case 9:
      return v2.HostType.HOST_TYPE_NONE;
    default:
      return void 0;
  }
}
function stringToUsageType(input) {
  switch (input) {
    case 1:
      return v2.UsageType.USAGE_TYPE_FIRST_PARTY_APP_ASSIGNMENT;
    case 2:
      return v2.UsageType.USAGE_TYPE_CALLING_USER_ASSIGNMENT;
    case 3:
      return v2.UsageType.USAGE_TYPE_FIRST_PARTY_APP_ASSIGNMENT_AND_CALLING_USER_ASSIGNMENT;
    default:
      return void 0;
  }
}
class GatewayClient {
  constructor(accessToken, customerId = "", logger) {
    this.user = {
      accessToken,
      customerId
    };
    const authInterceptor = new AuthorizationInterceptor(accessToken);
    this.config = getConfig();
    this.logger = logger;
    this.handlerIds = 1;
    this.eventHandlers = {
      error: {}
    };
    const args = [
      this.config.gateway.url,
      {},
      {
        unaryInterceptors: [authInterceptor],
        streamInterceptors: [authInterceptor]
      }
    ];
    this.accessControlClient = new v1.AccessControlClient(...args);
    this.customerClient = new v1.CustomerClient(...args);
    this.loginClientV1 = new v1.LoginClient(...args);
    this.profileClient = new v1.ProfileClient(...args);
    this.customerV2Client = new v2.CustomerAPIClient(...args);
    this.loginClientV2 = new v2.LoginAPIClient(...args);
    this.numbersClient = new v2.NumberManagementAPIClient(...args);
    this.carrierClient = new v2.CarrierManagementAPIClient(...args);
    this.provisioningClient = new v2.ProvisioningAPIClient(...args);
    this.serviceUserClient = new v2.ServiceUserAPIClient(...args);
    this.siteClient = new v2.SiteAPIClient(...args);
    this.teamsClient = new v2.TeamsAPIClient(...args);
    this.sipTrunkClient = new v3.SipTrunkAPIClient(...args);
    this.billingClient = new v3.BillingAPIClient(...args);
    this.billingEntityClient = new v3.BillingEntityAPIClient(...args);
  }
  addEventListener(event, fn) {
    const id = this.handlerIds++;
    this.eventHandlers[event][id] = fn;
    return id;
  }
  callEventListeners(event, ...args) {
    Object.values(this.eventHandlers[event]).forEach((fn) => fn(...args));
  }
  onResponse(resolve, reject) {
    const self = this;
    return (err, res) => {
      if (err) {
        self.callEventListeners("error", err);
      }
      return !err ? resolve(res.toObject()) : reject(err);
    };
  }
  async checkConnectionFailure() {
    return fetch(`${this.config.gateway.url}/V1.Profile/Get`, {
      method: "OPTIONS",
      headers: {
        "Access-Control-Request-Method": "POST",
        "Access-Control-Request-Headers": "authorization,content-type,x-grpc-web,x-user-agent"
      }
    }).then((res) => {
      switch (res.status) {
        case 204:
          return false;
        case 401:
          return { msg: "CORS configuration error", res };
        default:
          return { msg: "Something went wrong", res };
      }
    });
  }
  async getProfile() {
    return new Promise((resolve, reject) => {
      this.profileClient.get(new GetProfileRequest(), null, this.onResponse(resolve, reject));
    });
  }
  async updateProfile(first_name, last_name, email) {
    return new Promise((resolve, reject) => {
      const updateProfileDto = new UpdateProfileDto();
      const [firstNameDto, lastNameDto, emailDto] = [
        new NullableString(),
        new NullableString(),
        new NullableString()
      ];
      firstNameDto.setData(first_name);
      lastNameDto.setData(last_name);
      emailDto.setData(email);
      updateProfileDto.setFirstName(firstNameDto);
      updateProfileDto.setLastName(lastNameDto);
      updateProfileDto.setEmail(emailDto);
      this.profileClient.update(updateProfileDto, null, this.onResponse(resolve, reject));
    });
  }
  async getLogin(id) {
    return new Promise((resolve, reject) => {
      const getLoginRequest = new GetLoginRequest();
      getLoginRequest.setId(id);
      this.loginClientV1.get(getLoginRequest, null, this.onResponse(resolve, reject));
    });
  }
  async listCustomerLogins(id, page, rowsPerPage) {
    return new Promise((resolve, reject) => {
      const request2 = new ListCustomerLoginsRequest();
      request2.setPageNumber(page + 1);
      request2.setPageSize(rowsPerPage);
      const customer = new wrapper_pd.StringValue();
      customer.setValue(id);
      request2.setCustomerId(customer);
      this.customerV2Client.listCustomerLogins(request2, null, this.onResponse(resolve, reject));
    });
  }
  async getV2Login(id) {
    return new Promise((resolve, reject) => {
      const getLoginRequest = new GetLoginRequestV2();
      if (id) {
        const loginIdWrapper = new wrapper_pd.StringValue();
        loginIdWrapper.setValue(id);
        getLoginRequest.setLoginId(loginIdWrapper);
      }
      this.loginClientV2.getLogin(getLoginRequest, null, this.onResponse(resolve, reject));
    });
  }
  async createLogin(customerId, firstName, lastName, email, roles, authenticationMethod) {
    const request2 = new v2.CreateLoginRequest();
    const customerIdWrapper = new wrapper_pd.StringValue();
    customerIdWrapper.setValue(customerId);
    request2.setCustomerId(customerIdWrapper);
    request2.setEmail(email);
    request2.setFirstName(firstName);
    request2.setLastName(lastName);
    request2.setRolesList(roles);
    request2.setAuthenticationMethodType(authenticationMethod);
    return (await this.loginClientV2.createLogin(request2, null)).toObject().login;
  }
  async deleteLogin(id) {
    return new Promise((resolve, reject) => {
      const deleteLoginRequest = new DeleteLoginRequest();
      deleteLoginRequest.setId(id);
      this.loginClientV1.delete(deleteLoginRequest, null, this.onResponse(resolve, reject));
    });
  }
  async updateLogin(id, firstName, lastName) {
    return new Promise((resolve, reject) => {
      const updateLoginDto = new UpdateLoginDto();
      updateLoginDto.setLoginId(id);
      const updateProfileDto = new UpdateProfileDto();
      const [firstNameDto, lastNameDto] = [
        new NullableString(),
        new NullableString()
      ];
      firstNameDto.setData(firstName);
      lastNameDto.setData(lastName);
      updateProfileDto.setFirstName(firstNameDto);
      updateProfileDto.setLastName(lastNameDto);
      updateLoginDto.setProfileUpdate(updateProfileDto);
      this.loginClientV1.update(updateLoginDto, null, this.onResponse(resolve, reject));
    });
  }
  async updateLoginAuthMethod(id, unassignAuthId, authMethod) {
    const r1 = new v2.UnassignLoginAuthenticationMethodRequest();
    r1.setLoginId(id);
    r1.setAuthenticationMethodId(unassignAuthId);
    await this.loginClientV2.unassignLoginAuthenticationMethod(r1, null);
    const r2 = new v2.SendInviteRequest();
    r2.setLoginId(id);
    r2.setReturnUrl(wrapperStr(this.config.adminUrl));
    r2.setAuthenticationMethodsList([authMethod]);
    await this.loginClientV2.sendInvite(r2, null);
  }
  async setScopedRoles(id, scopedRolesList) {
    const setRolesRequest = new v2.SetRolesRequest();
    setRolesRequest.setLoginId(id);
    const rolesList = [];
    scopedRolesList.forEach((scopedRole) => {
      const newScopedRole = new ScopeRoles();
      newScopedRole.setScope(scopedRole.scope);
      scopedRole.rolesList.forEach((role) => newScopedRole.addRoles(role));
      rolesList.push(newScopedRole);
    });
    setRolesRequest.setScopeRolesList(rolesList);
    await this.loginClientV2.setRoles(setRolesRequest, null);
  }
  async setRoles(id, roles) {
    const setRolesDto = new SetRolesDto();
    setRolesDto.setLoginid(id);
    setRolesDto.setRolesList(roles);
    return new Promise((resolve, reject) => {
      this.loginClientV1.setRoles(setRolesDto, null, this.onResponse(resolve, reject));
    });
  }
  batchSendInvite(ids, authMethod = AuthenticationMethod.Password) {
    const batchReq = new v2.BatchSendInviteRequest();
    ids.forEach((id) => {
      const req = new v2.SendInviteRequest();
      req.setLoginId(id);
      req.setReturnUrl(wrapperStr(this.config.adminUrl));
      req.setAuthenticationMethodsList([
        authMethod
      ]);
      batchReq.addSendInviteRequests(req);
    });
    return this.loginClientV2.batchSendInvite(batchReq, null);
  }
  async sendInvite(id, authMethod = AuthenticationMethod.Password) {
    const req = new v2.SendInviteRequest();
    req.setLoginId(id);
    req.setReturnUrl(wrapperStr(this.config.adminUrl));
    req.setAuthenticationMethodsList([
      authMethod
    ]);
    const logreq = req;
    this.logger.info("lib/gateway.sendInvite()", {
      loginId: logreq.array[0],
      returnUrl: logreq.array[1],
      authMethod: logreq.array[2]
    });
    await this.loginClientV2.sendInvite(req, null);
  }
  async assignCloudTelephony(id) {
    return new Promise((resolve, reject) => {
      const request2 = new AssignCloudTelephonyRequest();
      request2.setId(id);
      this.loginClientV1.assignCloudTelephony(request2, null, this.onResponse(resolve, reject));
    });
  }
  async getCustomer(customerId) {
    const getCustomerRequest = new GetCustomerRequest();
    const customer = new wrapper_pd.StringValue();
    customer.setValue(customerId);
    getCustomerRequest.setCustomerId(customer);
    return (await this.customerV2Client.getCustomer(getCustomerRequest, null)).toObject().customer;
  }
  async getCustomerLogins(customerId, page, rowsPerPage) {
    return new Promise((resolve, reject) => {
      const getCustomerLoginsDto = new GetCustomerLoginsDto();
      getCustomerLoginsDto.setId(customerId);
      getCustomerLoginsDto.setPagenumber(page + 1);
      getCustomerLoginsDto.setPagesize(rowsPerPage);
      getCustomerLoginsDto.setStatus(LoginStatus.ACTIVE);
      this.customerClient.getCustomerLogins(getCustomerLoginsDto, null, this.onResponse(resolve, reject));
    });
  }
  async listCustomerDDIs(page, rowsPerPage, sortBy, sortDirection, queries) {
    return new Promise((resolve, reject) => {
      const listCustomerDDIsRequest = new ListCustomerDDIsRequest();
      listCustomerDDIsRequest.setPageNumber(page + 1);
      listCustomerDDIsRequest.setPageSize(rowsPerPage);
      if (this.user.customerId) {
        const customer = new wrapper_pd.StringValue();
        customer.setValue(this.user.customerId);
        listCustomerDDIsRequest.setCustomerId(customer);
      }
      if (sortBy) {
        listCustomerDDIsRequest.setSortBy(sortBy);
      }
      if (sortDirection) {
        listCustomerDDIsRequest.setSortDirection(sortDirection);
      }
      for (const query in queries) {
        const isArray = Array.isArray(queries[query]);
        let value = isArray ? queries[query][0] : queries[query];
        const method = isArray ? `add${capitalize(query)}` : `set${capitalize(query)}`;
        if (typeof value === "string") {
          value = new wrapper_pd.StringValue();
          value.setValue(isArray ? queries[query][0] : queries[query]);
        } else if (typeof value === "object") {
          value = new wrapper_pd.StringValue();
          value.setValue(isArray ? queries[query][0].id : queries[query].id);
        }
        listCustomerDDIsRequest[method](value);
      }
      this.numbersClient.listCustomerDDIs(listCustomerDDIsRequest, null, this.onResponse(resolve, reject));
    });
  }
  async getDdiFilters(customerId) {
    return new Promise((resolve, reject) => {
      const getDdiFiltersRequest = new GetDDIFiltersRequest();
      const customer = new wrapper_pd.StringValue();
      customer.setValue(customerId);
      getDdiFiltersRequest.setCustomerId(customer);
      this.numbersClient.getDDIFilters(getDdiFiltersRequest, null, this.onResponse(resolve, reject));
    });
  }
  async listCustomerGroups() {
    return new Promise((resolve, reject) => {
      const listCustomerGroupsRequest = new v2.ListCustomerGroupsRequest();
      if (this.user.customerId) {
        const customer = new wrapper_pd.StringValue();
        customer.setValue(this.user.customerId);
        listCustomerGroupsRequest.setCustomerId(customer);
      }
      this.customerV2Client.listCustomerGroups(listCustomerGroupsRequest, null, this.onResponse(resolve, reject));
    });
  }
  async getNumbers(customerId, countryCode, rangeStart, rangeEnd, pageNumber, pageSize, numberList) {
    return new Promise((resolve, reject) => {
      const request2 = new v2.GetNumbersRequest();
      if (customerId) {
        const customer = new wrapper_pd.StringValue();
        customer.setValue(customerId);
        request2.setCustomerId(customer);
      }
      if (countryCode) {
        const code = new wrapper_pd.StringValue();
        code.setValue(countryCode);
        request2.setCountryCode(code);
      }
      if (rangeStart) {
        const start = new wrapper_pd.StringValue();
        start.setValue(rangeStart);
        request2.setRangeStart(start);
      }
      if (rangeEnd) {
        const end = new wrapper_pd.StringValue();
        end.setValue(rangeEnd);
        request2.setRangeEnd(end);
      }
      if (pageNumber) {
        const page = new wrapper_pd.Int32Value();
        page.setValue(pageNumber);
        request2.setPageNumber(page);
      }
      if (pageSize) {
        const size = new wrapper_pd.Int32Value();
        size.setValue(pageSize);
        request2.setPageSize(size);
      }
      const wrappedNumberList = [];
      if (numberList && numberList.length > 0) {
        numberList.forEach((number) => {
          const wrappedNumber = new wrapper_pd.StringValue();
          wrappedNumber.setValue(number);
          wrappedNumberList.push(wrappedNumber);
        });
        request2.setNumberListList(wrappedNumberList);
      }
      this.numbersClient.getNumbers(request2, null, this.onResponse(resolve, reject));
    });
  }
  async getNumber(ddi) {
    return new Promise((resolve, reject) => {
      const request2 = new v2.GetNumberRequest();
      request2.setDdi(ddi);
      this.numbersClient.getNumber(request2, null, this.onResponse(resolve, reject));
    });
  }
  async createNumber(ddi, numberStatus, customerId, siteId, allocationStatus, countryCode, geographyType, carrierId, hostType, mainBillingNumber, numberRange, numberOwnedByLoopup, usageType) {
    return new Promise((resolve, reject) => {
      const request2 = new v2.CreateNumberRequest();
      request2.setDdi(ddi);
      request2.setNumberStatus(stringToNumberStatus(numberStatus));
      request2.setCustomerId(customerId);
      request2.setSiteId(siteId);
      request2.setAllocationStatus(stringToCTAllocationStatus(allocationStatus));
      request2.setCountryCode(countryCode);
      request2.setGeographyType(stringToCTnumberType(geographyType));
      request2.setCarrierId(carrierId);
      request2.setHostType(stringToHostType(hostType));
      request2.setMainBillingNumber(mainBillingNumber);
      request2.setNumberRange(numberRange);
      request2.setNumberOwnedByLoopup(numberOwnedByLoopup);
      request2.setUsageType(stringToUsageType(usageType));
      this.numbersClient.createNumber(request2, null, this.onResponse(resolve, reject));
    });
  }
  async updateNumber(ddi, numberStatus, groupId, carrierId, hostTypeId, geographyTypeId, usageTypeId, numberOwnedByLoopup, customerId, forwardingNumber, deleteForwardingNumber) {
    return new Promise((resolve, reject) => {
      const request2 = new v2.UpdateNumberRequest();
      request2.setDdi(ddi);
      if (numberStatus) {
        const status = new wrapper_pd.Int32Value();
        status.setValue(numberStatus);
        request2.setNumberStatusId(status);
      }
      if (groupId) {
        const group = new wrapper_pd.StringValue();
        group.setValue(groupId);
        request2.setGroupId(group);
      }
      if (carrierId) {
        const id = new wrapper_pd.Int32Value();
        id.setValue(carrierId);
        request2.setCarrierId(id);
      }
      if (hostTypeId) {
        const host = new wrapper_pd.Int32Value();
        host.setValue(hostTypeId);
        request2.setHostType(host);
      }
      if (geographyTypeId) {
        const geo = new wrapper_pd.Int32Value();
        geo.setValue(geographyTypeId);
        request2.setGeographyTypeId(geo);
      }
      if (usageTypeId !== void 0 || usageTypeId !== null) {
        const usage = new wrapper_pd.Int32Value();
        usage.setValue(usageTypeId);
        request2.setUsageTypeId(usage);
      }
      if (numberOwnedByLoopup !== void 0 && numberOwnedByLoopup !== null) {
        const ownByLoopup = new wrapper_pd.BoolValue();
        ownByLoopup.setValue(numberOwnedByLoopup);
        request2.setNumberOwnedByLoopup(ownByLoopup);
      }
      if (customerId) {
        const customer = new wrapper_pd.StringValue();
        customer.setValue(customerId);
        request2.setCustomerId(customer);
      }
      if (forwardingNumber) {
        const drNumber = new wrapper_pd.StringValue();
        drNumber.setValue(forwardingNumber);
        request2.setDrNumber(drNumber);
      }
      if (deleteForwardingNumber && !forwardingNumber) {
        const drNumber = new wrapper_pd.StringValue();
        drNumber.setValue("");
        request2.setDrNumber(drNumber);
      }
      this.numbersClient.updateNumber(request2, null, this.onResponse(resolve, reject));
    });
  }
  async updateSiteNumber(ddi, groupId, customerId) {
    return new Promise((resolve, reject) => {
      const request2 = new v2.UpdateNumberRequest();
      request2.setDdi(ddi);
      const group = new wrapper_pd.StringValue();
      group.setValue(groupId);
      request2.setGroupId(group);
      const customer = new wrapper_pd.StringValue();
      customer.setValue(customerId);
      request2.setCustomerId(customer);
      this.numbersClient.updateNumber(request2, null, this.onResponse(resolve, reject));
    });
  }
  async getInprogressNumbers(loginId, numberList, abortController, onUpdate) {
    const request2 = new v2.GetInProgressNumberChangesRequest();
    request2.setLoginId(loginId);
    request2.setNumberListList(numberList);
    return new Promise((resolve, reject) => {
      const stream = this.numbersClient.getInProgressNumberChanges(request2, null);
      abortController.signal.addEventListener("abort", () => {
        stream.cancel();
        reject(new Error("Operation cancelled"));
      });
      stream.on("data", (response) => {
        const result = response.toObject();
        onUpdate(result);
      });
      stream.on("end", () => {
        onUpdate(null);
        resolve();
      });
      stream.on("error", (error) => {
        reject(error);
      });
    });
  }
  async updateHostTypeNumber(ddi, hostTypeId, customerId) {
    return new Promise((resolve, reject) => {
      const request2 = new v2.UpdateNumberRequest();
      request2.setDdi(ddi);
      const host = new wrapper_pd.Int32Value();
      host.setValue(hostTypeId);
      request2.setHostType(host);
      const customer = new wrapper_pd.StringValue();
      customer.setValue(customerId);
      request2.setCustomerId(customer);
      this.numbersClient.updateNumber(request2, null, this.onResponse(resolve, reject));
    });
  }
  async listSites(page, rowsPerPage, sortBy, sortDirection, queries) {
    return this.listCustomerSites(this.user.customerId, page, rowsPerPage, sortBy, sortDirection, queries);
  }
  async listCustomerSites(customerId, page, rowsPerPage, sortBy, sortDirection, queries) {
    return new Promise((resolve, reject) => {
      const listCustomerSitesRequest = new ListSitesRequest();
      listCustomerSitesRequest.setPageNumber(page + 1);
      listCustomerSitesRequest.setPageSize(rowsPerPage);
      listCustomerSitesRequest.setSortBy(sortBy);
      listCustomerSitesRequest.setSortDirection(sortDirection);
      if (customerId) {
        const customer = new wrapper_pd.StringValue();
        customer.setValue(customerId);
        listCustomerSitesRequest.setCustomerId(customer);
      }
      for (const query in queries) {
        let value = queries[query];
        if (typeof value === "string") {
          value = new wrapper_pd.StringValue();
          value.setValue(queries[query]);
        }
        const method = `set${capitalize(query)}`;
        listCustomerSitesRequest[method](value);
      }
      this.siteClient.listSites(listCustomerSitesRequest, null, this.onResponse(resolve, reject));
    });
  }
  async getSiteFilters() {
    return new Promise((resolve, reject) => {
      const req = new GetSiteFiltersRequest();
      const customer = new wrapper_pd.StringValue();
      customer.setValue(this.user.customerId);
      req.setCustomerId(customer);
      this.siteClient.getSiteFilters(req, null, this.onResponse(resolve, reject));
    });
  }
  async createSite(customerId, name, fields) {
    return new Promise((resolve, reject) => {
      const createSiteRequest = new CreateSiteRequest();
      createSiteRequest.setCustomerId(customerId);
      createSiteRequest.setName(name);
      for (const field in fields) {
        let value = fields[field];
        if (field !== "countryCode" && field !== "emergencyCountryCode") {
          value = new wrapper_pd.StringValue();
          value.setValue(fields[field]);
        }
        const method = `set${capitalize(field)}`;
        createSiteRequest[method](value);
      }
      this.siteClient.createSite(createSiteRequest, null, this.onResponse(resolve, reject));
    });
  }
  async getSite(id) {
    return new Promise((resolve, reject) => {
      const getSiteRequest = new GetSiteRequest();
      getSiteRequest.setId(id);
      this.siteClient.getSite(getSiteRequest, null, this.onResponse(resolve, reject));
    });
  }
  async updateSite(id, fields) {
    return new Promise((resolve, reject) => {
      const updateSiteRequest = new UpdateSiteRequest();
      updateSiteRequest.setId(id);
      for (const field in fields) {
        const value = new NullableString();
        value.setData(fields[field]);
        const method = `set${capitalize(field)}`;
        updateSiteRequest[method](value);
      }
      this.siteClient.updateSite(updateSiteRequest, null, this.onResponse(resolve, reject));
    });
  }
  async listServiceUsers(pageNumber, pageSize) {
    return new Promise((resolve, reject) => {
      const req = new ListServiceUsersRequest();
      req.setPageNumber(pageNumber + 1);
      req.setPageSize(pageSize);
      if (this.user.customerId) {
        const customerId = new wrapper_pd.StringValue();
        customerId.setValue(this.user.customerId);
        req.setCustomerId(customerId);
      }
      this.serviceUserClient.listServiceUsers(req, null, this.onResponse(resolve, reject));
    });
  }
  async getServiceUser(id) {
    const req = new v2.GetServiceUserRequest();
    req.setServiceUserId(id);
    const res = await this.serviceUserClient.getServiceUser(req, null);
    return res.toObject().serviceUser;
  }
  addService(serviceUser, customerId, { site, selectedDdi }) {
    if (selectedDdi && selectedDdi !== "auto") {
      const request2 = new ProvisionServiceUserWithSpecificDdiRequest();
      request2.setServiceUserId(serviceUser.id);
      request2.setCustomerId(customerId);
      request2.setDdi(selectedDdi);
      return this.provisioningClient.provisionServiceUserWithSpecificDdi(request2);
    } else if (site) {
      const request2 = new ProvisionServiceUserWithRandomDdiRequest();
      request2.setServiceUserId(serviceUser.id);
      request2.setCustomerId(customerId);
      request2.setSiteId(site.id);
      return this.provisioningClient.provisionServiceUserWithRandomDdi(request2);
    }
    return null;
  }
  editService(serviceUser, customerId, disasterRecoveryNumber) {
    return new Promise((resolve, reject) => {
      const request2 = new UpdateServiceUserRequest();
      request2.setServiceUserId(serviceUser.id);
      request2.setDisasterRecoveryNumber(disasterRecoveryNumber);
      const value = new wrapper_pd.StringValue();
      value.setValue(customerId);
      request2.setCustomerId(value);
      return this.serviceUserClient.updateServiceUser(request2, null, this.onResponse(resolve, reject));
    });
  }
  removeService(serviceUser, customerId, selectedDdi) {
    const request2 = new DeprovisionServiceUserRequest();
    request2.setServiceUserId(serviceUser.id);
    request2.setCustomerId(customerId);
    request2.setDdi(selectedDdi);
    return this.provisioningClient.deprovisionServiceUser(request2);
  }
  async getTeamsSyncStatus(skipTeamsApiCheck) {
    return new Promise((resolve, reject) => {
      const req = new GetTeamsSyncStatusRequest();
      if (this.user.customerId) {
        const customerId = new wrapper_pd.StringValue();
        customerId.setValue(this.user.customerId);
        req.setCustomerId(customerId);
      }
      if (skipTeamsApiCheck) {
        const skipApi = new wrapper_pd.BoolValue();
        skipApi.setValue(skipTeamsApiCheck);
        req.setSkipTeamsApiCheck(skipApi);
      }
      this.teamsClient.getTeamsSyncStatus(req, null, (err, response) => {
        if (err) {
          this.callEventListeners("error", err);
          if (err.code === 5) {
            resolve({
              teamsConnection: {
                tenantId: "",
                syncStatus: v2.TeamsSyncStatus.TEAMS_SYNC_STATUS_NOT_YET_SYNCED,
                userTokenCache: {
                  tokenStatus: v2.TeamsUserTokenStatus.TEAMS_USER_TOKEN_STATUS_NO_TOKEN
                },
                syncWithTenantAccessToken: false,
                apiAccessStatus: v2.TeamsApiAccessStatus.TEAMS_API_ACCESS_STATUS_INVALID
              }
            });
            return;
          }
          reject(err);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
  async getTenantAccessStatus(tenantId) {
    return new Promise((resolve, reject) => {
      const req = new v2.GetTenantAccessStatusRequest();
      req.setTenantId(tenantId);
      return this.teamsClient.getTenantAccessStatus(req, null, (err, response) => {
        if (err) {
          this.callEventListeners("error", err);
          reject(err);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
  async getTenantGraphApiStatus(tenantId) {
    return new Promise((resolve, reject) => {
      const req = new v2.GetTenantGraphApiStatusRequest();
      req.setTenantId(tenantId);
      return this.teamsClient.getTenantGraphApiStatus(req, null, (err, response) => {
        if (err) {
          this.callEventListeners("error", err);
          reject(err);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
  async createSipTrunk(customerId, name, description, region, protocol, traffic_distribution_method, primaryEndpoint, additionalEndpoints, channelLimit = null) {
    return new Promise((resolve, reject) => {
      const req = new v3.CreateSipTrunkWithEndpointsAsyncRequest();
      req.setCustomerId(customerId);
      req.setName(name);
      req.setDescription(wrapperStr(description));
      req.setRegion(region);
      req.setProtocol(stringToProtocol(protocol));
      req.setTrafficDistributionMethod(stringToTrafficDistributionMethod(traffic_distribution_method));
      req.setSipEndPointsList(createSipEndpointsObject(primaryEndpoint, additionalEndpoints));
      if (channelLimit !== void 0 && channelLimit !== null && channelLimit !== "") {
        req.setChannelLimit(wrapperInt(channelLimit));
      }
      this.sipTrunkClient.createSipTrunkWithEndpointsAsync(req, null, this.onResponse(resolve, reject));
    });
  }
  async getSipTrunkByCustomer(customerId, page, rowsPerPage) {
    return new Promise((resolve, reject) => {
      const req = new v3.GetSipTrunkByCustomerRequest();
      req.setCustomerId(customerId);
      req.setPageNumber(page + 1);
      req.setPageSize(rowsPerPage);
      this.sipTrunkClient.getSipTrunkByCustomer(req, null, this.onResponse(resolve, reject));
    });
  }
  async addNumberToSipTrunk(customerId, sipTrunkId, ddi) {
    return new Promise((resolve, reject) => {
      const req = new v3.AddNumberToSipTrunkAsyncRequest();
      req.setCustomerId(customerId);
      req.setSipTrunkId(sipTrunkId);
      req.setDdi(ddi);
      this.sipTrunkClient.addNumberToSipTrunkAsync(req, null, this.onResponse(resolve, reject));
    });
  }
  async deleteNumberFromSipTrunk(sipTrunkId, customerId, ddi) {
    return new Promise((resolve, reject) => {
      const req = new v3.DeleteNumberFromSipTrunkAsyncRequest();
      req.setCustomerId(customerId);
      req.setSipTrunkId(sipTrunkId);
      req.setDdi(ddi);
      this.sipTrunkClient.deleteNumberFromSipTrunkAsync(req, null, this.onResponse(resolve, reject));
    });
  }
  async updateSipTrunk(sipTrunkId, customerId, name, description, region, protocol, traffic_distribution_method, primaryEndpoint, additionalEndpoints, channelLimit, oldSipEndpoints) {
    return new Promise((resolve, reject) => {
      const req = new v3.UpdateSipTrunkWithEndpointsAsyncRequest();
      req.setSipTrunkId(sipTrunkId);
      req.setCustomerId(customerId);
      req.setName(name);
      req.setDescription(wrapperStr(description));
      req.setRegion(region);
      req.setProtocol(stringToProtocol(protocol));
      req.setSipEndPointsList(updateSipEndpointsObject(primaryEndpoint, additionalEndpoints, oldSipEndpoints));
      if (channelLimit !== void 0 && channelLimit !== null && channelLimit !== "") {
        req.setChannelLimit(wrapperInt(channelLimit));
      }
      req.setTrafficDistributionMethod(stringToTrafficDistributionMethod(traffic_distribution_method));
      this.sipTrunkClient.updateSipTrunkWithEndpointsAsync(req, null, this.onResponse(resolve, reject));
    });
  }
  async setTrafficDistributionMethod(sipTrunkId, customerId, trafficDistributionMethod) {
    return new Promise((resolve, reject) => {
      const req = new v3.SetTrafficDistributionMethodAsyncRequest();
      req.setSipTrunkId(sipTrunkId);
      req.setCustomerId(customerId);
      req.setTrafficDistributionMethod(stringToTrafficDistributionMethod(trafficDistributionMethod));
      this.sipTrunkClient.setTrafficDistributionMethodAsync(req, null, this.onResponse(resolve, reject));
    });
  }
  async deleteSipTrunk(sipTrunkId, customerId) {
    return new Promise((resolve, reject) => {
      const req = new v3.DeleteSipTrunkAsyncRequest();
      req.setSipTrunkId(sipTrunkId);
      req.setCustomerId(customerId);
      this.sipTrunkClient.deleteSipTrunkAsync(req, null, this.onResponse(resolve, reject));
    });
  }
  async listCustomerApiKeys(customerId, page, rowsPerPage) {
    return new Promise((resolve, reject) => {
      const listCustomerApiKeysRequest = new v2.ListCustomerApiKeysRequest();
      listCustomerApiKeysRequest.setPageNumber(page + 1);
      listCustomerApiKeysRequest.setPageSize(rowsPerPage);
      const customer = new wrapper_pd.StringValue();
      customer.setValue(customerId);
      listCustomerApiKeysRequest.setCustomerId(customer);
      this.customerV2Client.listCustomerApiKeys(listCustomerApiKeysRequest, null, this.onResponse(resolve, reject));
    });
  }
  async createApiKey(customerId, tokenName, scope) {
    return new Promise((resolve, reject) => {
      const createCustomerApiKeysRequest = new v2.CreateCustomerDdiActivationApiKeyRequest();
      createCustomerApiKeysRequest.setCustomerId(customerId);
      createCustomerApiKeysRequest.setDescription(tokenName);
      createCustomerApiKeysRequest.addScopes(scope);
      this.customerV2Client.createCustomerDdiActivationApiKey(createCustomerApiKeysRequest, null, this.onResponse(resolve, reject));
    });
  }
  async regenerateCustomerApiKeySecret(customerId, clientId) {
    return new Promise((resolve, reject) => {
      const request2 = new v2.RegenerateCustomerApiKeySecretRequest();
      request2.setCustomerId(customerId);
      request2.setClientId(clientId);
      this.customerV2Client.regenerateCustomerApiKeySecret(request2, null, this.onResponse(resolve, reject));
    });
  }
  async deleteCustomerApiKey(customerId, clientId) {
    return new Promise((resolve, reject) => {
      const request2 = new v2.DeleteCustomerApiKeyRequest();
      request2.setCustomerId(customerId);
      request2.setClientId(clientId);
      this.customerV2Client.deleteCustomerApiKey(request2, null, this.onResponse(resolve, reject));
    });
  }
  async listInvoices(billingEntityId, pageNumber, pageSize) {
    return new Promise((resolve, reject) => {
      const req = new v3.ListInvoicesRequest();
      req.setBillingEntityId(billingEntityId);
      req.setPageNumber(pageNumber);
      req.setPageSize(pageSize);
      this.billingClient.listInvoices(req, null, this.onResponse(resolve, reject));
    });
  }
  async listBillingEntities(customerId, pageNumber, pageSize) {
    return new Promise((resolve, reject) => {
      const req = new v3.ListBillingEntitiesRequest();
      req.setCustomerId(customerId);
      req.setPageNumber(pageNumber);
      req.setPageSize(pageSize);
      this.billingEntityClient.listBillingEntities(req, null, this.onResponse(resolve, reject));
    });
  }
  async downloadIssuedInvoicePdf(invoiceId) {
    return new Promise((resolve, reject) => {
      const req = new v3.GetIssuedInvoicePdfRequest();
      req.setInvoiceId(invoiceId);
      this.billingClient.getIssuedInvoicePdf(req, null, this.onResponse(resolve, reject));
    });
  }
  async listCarrier() {
    return new Promise((resolve, reject) => {
      const req = new v2.ListCarrierRequest();
      req.setSortBy(v2.CarrierSortProperty.CARRIER_SORT_PROPERTY_NAME);
      req.setSortDirection(v2.SortDirection.SORT_DIRECTION_ASCENDING);
      this.carrierClient.listCarrier(req, null, this.onResponse(resolve, reject));
    });
  }
}
export {
  AuthenticationMethod,
  DeprovisioningUpdateType,
  ProvisioningStatus,
  ProvisioningUpdateType,
  ROLES,
  ResponseKind,
  ServiceType,
  ServiceUserType,
  TeamsUserTokenStatus,
  GatewayClient as default,
  request,
  stringToProtocol,
  wrapperInt,
  wrapperStr
};
