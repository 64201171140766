const dialingCodeToCountryCode: { [key: string]: string } = {
  1: "NANP",
  1264: "AI",
  1268: "AG",
  1242: "BS",
  1246: "BB",
  1441: "BM",
  1284: "VG",
  1204: "CA",
  1226: "CA",
  1236: "CA",
  1249: "CA",
  1250: "CA",
  1257: "CA",
  1263: "CA",
  1273: "CA",
  1289: "CA",
  1306: "CA",
  1343: "CA",
  1354: "CA",
  1365: "CA",
  1367: "CA",
  1368: "CA",
  1382: "CA",
  1387: "CA",
  1403: "CA",
  1416: "CA",
  1418: "CA",
  1428: "CA",
  1431: "CA",
  1437: "CA",
  1438: "CA",
  1450: "CA",
  1460: "CA",
  1468: "CA",
  1474: "CA",
  1487: "CA",
  1506: "CA",
  1514: "CA",
  1519: "CA",
  1537: "CA",
  1548: "CA",
  1568: "CA",
  1579: "CA",
  1581: "CA",
  1584: "CA",
  1587: "CA",
  1600: "CA",
  1604: "CA",
  1613: "CA",
  1622: "CA",
  1639: "CA",
  1647: "CA",
  1672: "CA",
  1683: "CA",
  1705: "CA",
  1709: "CA",
  1742: "CA",
  1753: "CA",
  1778: "CA",
  1780: "CA",
  1782: "CA",
  1807: "CA",
  1819: "CA",
  1825: "CA",
  1851: "CA",
  1867: "CA",
  1871: "CA",
  1873: "CA",
  1879: "CA",
  1902: "CA",
  1905: "CA",
  1942: "CA",
  1345: "KY",
  1767: "DM",
  1809: "DO",
  1829: "DO",
  1849: "DO",
  1473: "GD",
  1658: "JM",
  1876: "JM",
  1664: "MS",
  1721: "SX",
  1869: "KN",
  1758: "LC",
  1784: "VC",
  1868: "TT",
  1649: "TC",
  1201: "US",
  1202: "US",
  1203: "US",
  1205: "US",
  1206: "US",
  1207: "US",
  1208: "US",
  1209: "US",
  1210: "US",
  1212: "US",
  1213: "US",
  1214: "US",
  1215: "US",
  1216: "US",
  1217: "US",
  1218: "US",
  1219: "US",
  1220: "US",
  1223: "US",
  1224: "US",
  1225: "US",
  1227: "US",
  1228: "US",
  1229: "US",
  1231: "US",
  1234: "US",
  1239: "US",
  1240: "US",
  1248: "US",
  1251: "US",
  1252: "US",
  1253: "US",
  1254: "US",
  1256: "US",
  1260: "US",
  1262: "US",
  1267: "US",
  1269: "US",
  1270: "US",
  1272: "US",
  1274: "US",
  1276: "US",
  1279: "US",
  1281: "US",
  1283: "US",
  1301: "US",
  1302: "US",
  1303: "US",
  1304: "US",
  1305: "US",
  1307: "US",
  1308: "US",
  1309: "US",
  1310: "US",
  1312: "US",
  1313: "US",
  1314: "US",
  1315: "US",
  1316: "US",
  1317: "US",
  1318: "US",
  1319: "US",
  1320: "US",
  1321: "US",
  1323: "US",
  1325: "US",
  1326: "US",
  1327: "US",
  1330: "US",
  1331: "US",
  1332: "US",
  1334: "US",
  1336: "US",
  1337: "US",
  1339: "US",
  1340: "US",
  1341: "US",
  1346: "US",
  1347: "US",
  1351: "US",
  1352: "US",
  1353: "US",
  1360: "US",
  1361: "US",
  1364: "US",
  1369: "US",
  1380: "US",
  1385: "US",
  1386: "US",
  1401: "US",
  1402: "US",
  1404: "US",
  1405: "US",
  1406: "US",
  1407: "US",
  1408: "US",
  1409: "US",
  1410: "US",
  1412: "US",
  1413: "US",
  1414: "US",
  1415: "US",
  1417: "US",
  1419: "US",
  1423: "US",
  1424: "US",
  1425: "US",
  1430: "US",
  1432: "US",
  1434: "US",
  1435: "US",
  1440: "US",
  1442: "US",
  1443: "US",
  1445: "US",
  1447: "US",
  1458: "US",
  1463: "US",
  1464: "US",
  1469: "US",
  1470: "US",
  1475: "US",
  1478: "US",
  1479: "US",
  1480: "US",
  1484: "US",
  1501: "US",
  1502: "US",
  1503: "US",
  1504: "US",
  1505: "US",
  1507: "US",
  1508: "US",
  1509: "US",
  1510: "US",
  1512: "US",
  1513: "US",
  1515: "US",
  1516: "US",
  1517: "US",
  1518: "US",
  1520: "US",
  1530: "US",
  1531: "US",
  1534: "US",
  1539: "US",
  1540: "US",
  1541: "US",
  1551: "US",
  1557: "US",
  1559: "US",
  1561: "US",
  1562: "US",
  1563: "US",
  1564: "US",
  1567: "US",
  1570: "US",
  1571: "US",
  1573: "US",
  1574: "US",
  1575: "US",
  1580: "US",
  1582: "US",
  1585: "US",
  1586: "US",
  1601: "US",
  1602: "US",
  1603: "US",
  1605: "US",
  1606: "US",
  1607: "US",
  1608: "US",
  1609: "US",
  1610: "US",
  1612: "US",
  1614: "US",
  1615: "US",
  1616: "US",
  1617: "US",
  1618: "US",
  1619: "US",
  1620: "US",
  1623: "US",
  1626: "US",
  1627: "US",
  1628: "US",
  1629: "US",
  1630: "US",
  1631: "US",
  1636: "US",
  1640: "US",
  1641: "US",
  1646: "US",
  1650: "US",
  1651: "US",
  1657: "US",
  1659: "US",
  1660: "US",
  1661: "US",
  1662: "US",
  1667: "US",
  1669: "US",
  1670: "US",
  1671: "US",
  1678: "US",
  1679: "US",
  1680: "US",
  1681: "US",
  1682: "US",
  1684: "US",
  1689: "US",
  1701: "US",
  1702: "US",
  1703: "US",
  1704: "US",
  1706: "US",
  1707: "US",
  1708: "US",
  1710: "US",
  1712: "US",
  1713: "US",
  1714: "US",
  1715: "US",
  1716: "US",
  1717: "US",
  1718: "US",
  1719: "US",
  1720: "US",
  1724: "US",
  1725: "US",
  1726: "US",
  1727: "US",
  1730: "US",
  1731: "US",
  1732: "US",
  1734: "US",
  1737: "US",
  1740: "US",
  1743: "US",
  1747: "US",
  1754: "US",
  1757: "US",
  1760: "US",
  1762: "US",
  1763: "US",
  1764: "US",
  1765: "US",
  1769: "US",
  1770: "US",
  1772: "US",
  1773: "US",
  1774: "US",
  1775: "US",
  1779: "US",
  1781: "US",
  1785: "US",
  1786: "US",
  1787: "US",
  1801: "US",
  1802: "US",
  1803: "US",
  1804: "US",
  1805: "US",
  1806: "US",
  1808: "US",
  1810: "US",
  1812: "US",
  1813: "US",
  1814: "US",
  1815: "US",
  1816: "US",
  1817: "US",
  1818: "US",
  1820: "US",
  1828: "US",
  1830: "US",
  1831: "US",
  1832: "US",
  1835: "US",
  1838: "US",
  1843: "US",
  1845: "US",
  1847: "US",
  1848: "US",
  1850: "US",
  1854: "US",
  1856: "US",
  1857: "US",
  1858: "US",
  1859: "US",
  1860: "US",
  1862: "US",
  1863: "US",
  1864: "US",
  1865: "US",
  1870: "US",
  1872: "US",
  1878: "US",
  1901: "US",
  1903: "US",
  1904: "US",
  1906: "US",
  1907: "US",
  1908: "US",
  1909: "US",
  1910: "US",
  1912: "US",
  1913: "US",
  1914: "US",
  1915: "US",
  1916: "US",
  1917: "US",
  1918: "US",
  1919: "US",
  1920: "US",
  1925: "US",
  1928: "US",
  1929: "US",
  1930: "US",
  1931: "US",
  1934: "US",
  1935: "US",
  1936: "US",
  1937: "US",
  1938: "US",
  1939: "US",
  1940: "US",
  1941: "US",
  1947: "US",
  1949: "US",
  1951: "US",
  1952: "US",
  1954: "US",
  1956: "US",
  1959: "US",
  1970: "US",
  1971: "US",
  1972: "US",
  1973: "US",
  1975: "US",
  1978: "US",
  1979: "US",
  1980: "US",
  1984: "US",
  1985: "US",
  1986: "US",
  1989: "US",
  7: "RU",
  20: "EG",
  27: "ZA",
  30: "GR",
  31: "NL",
  32: "BE",
  33: "FR",
  34: "ES",
  36: "HU",
  39: "IT",
  40: "RO",
  41: "CH",
  43: "AT",
  44: "GB",
  45: "DK",
  46: "SE",
  47: "NO",
  48: "PL",
  49: "DE",
  51: "PE",
  52: "MX",
  53: "CU",
  54: "AR",
  55: "BR",
  56: "CL",
  57: "CO",
  58: "VE",
  60: "MY",
  61: "AU",
  62: "ID",
  63: "PH",
  64: "NZ",
  65: "SG",
  66: "TH",
  81: "JP",
  82: "KR",
  84: "VN",
  86: "CN",
  90: "TR",
  91: "IN",
  92: "PK",
  93: "AF",
  94: "LK",
  95: "MM",
  98: "IR",
  211: "SS",
  212: "MA",
  213: "DZ",
  216: "TN",
  218: "LY",
  220: "GM",
  221: "SN",
  222: "MR",
  223: "ML",
  224: "GN",
  225: "CI",
  226: "BF",
  227: "NE",
  228: "TG",
  229: "BJ",
  230: "MU",
  231: "LR",
  232: "SL",
  233: "GH",
  234: "NG",
  235: "TD",
  236: "CF",
  237: "CM",
  238: "CV",
  239: "ST",
  240: "GQ",
  241: "GA",
  242: "CG",
  243: "CD",
  244: "AO",
  245: "GW",
  246: "IO",
  248: "SC",
  249: "SD",
  250: "RW",
  251: "ET",
  252: "SO",
  253: "DJ",
  254: "KE",
  255: "TZ",
  256: "UG",
  257: "BI",
  258: "MZ",
  260: "ZM",
  261: "MG",
  262: "RE",
  263: "ZW",
  264: "NA",
  265: "MW",
  266: "LS",
  267: "BW",
  268: "SZ",
  269: "KM",
  290: "SH",
  291: "ER",
  297: "AW",
  298: "FO",
  299: "GL",
  350: "GI",
  351: "PT",
  352: "LU",
  353: "IE",
  354: "IS",
  355: "AL",
  356: "MT",
  357: "CY",
  358: "FI",
  359: "BG",
  370: "LT",
  371: "LV",
  372: "EE",
  373: "MD",
  374: "AM",
  375: "BY",
  376: "AD",
  377: "MC",
  378: "SM",
  379: "VA",
  380: "UA",
  381: "RS",
  382: "ME",
  385: "HR",
  386: "SI",
  387: "BA",
  389: "MK",
  420: "CZ",
  421: "SK",
  423: "LI",
  500: "FK",
  501: "BZ",
  502: "GT",
  503: "SV",
  504: "HN",
  505: "NI",
  506: "CR",
  507: "PA",
  508: "PM",
  509: "HT",
  590: "GP",
  591: "BO",
  592: "GY",
  593: "EC",
  594: "GF",
  595: "PY",
  596: "MQ",
  597: "SR",
  598: "UY",
  599: "CW",
  670: "TL",
  672: "NF",
  673: "BN",
  674: "NR",
  675: "PG",
  676: "TO",
  677: "SB",
  678: "VU",
  679: "FJ",
  680: "PW",
  681: "WF",
  682: "CK",
  683: "NU",
  685: "WS",
  686: "KI",
  687: "NC",
  688: "TV",
  689: "PF",
  690: "TK",
  691: "FM",
  692: "MH",
  850: "KP",
  852: "HK",
  853: "MO",
  855: "KH",
  856: "LA",
  870: "PN",
  880: "BD",
  886: "TW",
  960: "MV",
  961: "LB",
  962: "JO",
  963: "SY",
  964: "IQ",
  965: "KW",
  966: "SA",
  967: "YE",
  968: "OM",
  970: "PS",
  971: "AE",
  972: "IL",
  973: "BH",
  974: "QA",
  975: "BT",
  976: "MN",
  977: "NP",
  992: "TJ",
  993: "TM",
  994: "AZ",
  995: "GE",
  996: "KG",
  998: "UZ",
  441481: "GG",
  441534: "JE",
  441624: "IM",
  6189162: "CC",
  6189164: "CX",
};

export default dialingCodeToCountryCode;
