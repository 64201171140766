import styled from "styled-components";
import * as Colors from "src/colors";

const HighlightBar = styled.div`
  background: ${Colors.activityColor};
  width: 0.25rem;
  border-radius: 0.125rem;
  position: absolute;
  left: 1px;
  top: 0;
  bottom: 0;
`;

export default HighlightBar;
