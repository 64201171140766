import fetch from "cross-fetch";

export const getVersion = async () => {
  try {
    const data = await fetch("/api/version");
    const body = await data.json();
    return body.version;
  } catch {
    return "";
  }
};
