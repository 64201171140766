import { Component, ReactNode } from "react";
import * as Datadog from "src/utils/datadog";

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // TODO: Switch below to use enum for errors once Typescript is implemented
    if (error.message === "Unauthenticated") {
      location.reload();
    }

    return { hasError: true };
  }

  componentDidCatch(error) {
    Datadog.error(error);
  }

  render() {
    return this.props.children;
  }
}
