import once from "lodash/fp/once";

// Only allow window.location to be set once per instance. This is to prevent race conditions where
// other areas of code could subsequently call window.location and cause inexpected behavior.
// Fixes part of LUP-8179
export const redirect = once((url) => {
  // eslint-disable-next-line no-restricted-properties
  window.location = url;

  return url;
});

export const reload = once(() => {
  // eslint-disable-next-line no-restricted-properties
  window.location.reload();
});

// eslint-disable-next-line no-restricted-properties
export const pathname = () => `${window.location.pathname}`;

// eslint-disable-next-line no-restricted-properties
export const hash = () => `${window.location.pathname}`;
