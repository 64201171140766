import { ComponentProps, createElement } from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import memoize from "lodash/memoize";
import clientStringsEn from "./client-strings.en.json";
import clientStringsDe from "./client-strings.de.json";
import clientStringsEs from "./client-strings.es.json";
import clientStringsFr from "./client-strings.fr.json";
import countryStringsEn from "./country-strings.en.json";
import countryStringsDe from "./country-strings.de.json";
import countryStringsEs from "./country-strings.es.json";
import countryStringsFr from "./country-strings.fr.json";
import countrySettings from "./countrySettings.json";

/** There doesn't seem to be a way to use this from the supported.json file. Though it seems we
 * may need to keep these separate anyway for the purposes of supporting fallback options. */
export type SupportedLanguage = "de" | "en" | "es" | "fr";

/** All supported 3 letter country codes. */
export type CountryCode = keyof typeof countryStringsEn;

/** Country that unused, or inhabitant country */
export const excludedCountry: string[] = Object.keys(countrySettings.exclude);

/** Valid country codes after exclusion. */
export const validCountries: CountryCode[] = (
  Object.keys(countryStringsEn) as CountryCode[]
).filter((code) => !excludedCountry.includes(code));

/** All valid text Ids that can be used. If the Id you're looking for is not in this type then you
 * likely haven't added it to the correct English language strings file. */
export type TextId = keyof typeof clientStringsEn | `country_${CountryCode}`;

export const clientStrings: Record<
  SupportedLanguage,
  Record<keyof typeof clientStringsEn, string>
> = {
  en: clientStringsEn,
  de: clientStringsDe,
  es: clientStringsEs,
  fr: clientStringsFr,
};
export const countryStrings: Record<
  SupportedLanguage,
  Record<CountryCode, string>
> = {
  en: countryStringsEn,
  de: countryStringsDe,
  es: countryStringsEs,
  fr: countryStringsFr,
};

/** Parses the browsers language / languages */
export function parseLanguage(
  lang = navigator.language
): SupportedLanguage | null {
  // Exact match
  if (lang in clientStrings) {
    return lang as SupportedLanguage;
  }

  // Match just the language ignoring the country
  const langWithoutCountry = lang.split("-")[0];

  if (langWithoutCountry in clientStrings) {
    return langWithoutCountry as SupportedLanguage;
  }

  // No match found
  return null;
}

export const allCountries: CountryCode[] = Object.keys(countryStringsEn) as any;
/**
 * Return all the strings that best match the language preferences expressed in the priority queue
 * of languages in navigator.languages.
 *
 * @param languages - Priority queue of languages, defaults to navigator.languages
 */
export function allStrings(
  languages = navigator.languages
): Record<TextId, string> {
  let lang: SupportedLanguage = "en";

  for (const culture of languages) {
    const l = parseLanguage(culture);

    if (l) {
      lang = l;
      break;
    }
  }

  const str = { ...clientStrings[lang] };

  for (const key in countryStrings[lang]) {
    str[`country_${key}`] = countryStrings[lang][key];
  }

  return str as Record<TextId, string>;
}

/**
 * Return all the strings that best match the language preferences expressed in the priority queue
 * of languages in navigator.languages. Memoized version of allStrings().
 *
 * @param languages - Priority queue of languages, defaults to navigator.languages
 */
export const strings: (
  languages?: readonly string[]
) => Record<TextId, string> = memoize(allStrings);

function getText(id: TextId): string {
  return strings()[id];
}

/** Get the text translation for a given TextId. */
export const text = memoize(getText);

/** Get the text translation for a given TextId. */
export const t = text;

export type MessageProps = Omit<
  ComponentProps<typeof FormattedMessage>,
  "id"
> & {
  id: TextId;
};

/**
 * Returns a translated string for a given message Id. Fully compatible with FormattedMessage
 * which is used for translating, this component type checks the message Ids to be valid Id's that
 * are in the various *-strings.*.json files.
 * @param props React props
 * @returns Translated message
 */
export const Message = (props: MessageProps) =>
  createElement(FormattedMessage, props);

/** React Provider component that allows messages to be used inside formatted messages and such. */
export const Provider = ({ children }) => {
  const messages = strings();

  return (
    <IntlProvider locale={navigator.language} messages={messages}>
      {children}
    </IntlProvider>
  );
};
