import { ComponentProps, forwardRef } from "react";
import { Card as MuiCard } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import styled from "styled-components";
import { MainColors } from "src/colors";

type Props = ComponentProps<typeof MuiCard> & {
  color?: MainColors | "default";
  highlighted?: boolean | "horizontal" | "vertical";
};

const HighlightedCard = styled(MuiCard)`
  && {
    border-radius: ${(p) => p.$borderRadius};

    &.highlight-horizontal {
      border-top: ${(p) => p.$highlightWidth} solid ${(p) => p.$palette.main};
    }

    &.highlight-vertical {
      border-left: ${(p) => p.$highlightWidth} solid ${(p) => p.$palette.main};
    }
  }
`;

const StyledCard = styled(MuiCard)`
  && {
    border-radius: ${(p) => p.$borderRadius};
  }
`;

const Card = forwardRef((props: Props, ref) => {
  const { color, highlighted, ...baseProps } = props;

  const theme: any = useTheme();
  const borderRadius = theme.spacing(2);

  if (highlighted) {
    const palette =
      color === "default" || !color
        ? theme.palette.primary
        : theme.palette[color];
    const highlightWidth = theme.spacing(2);
    const classes = classNames({
      "highlight-vertical": highlighted === "vertical" || highlighted === true,
      "highlight-horizontal": highlighted === "horizontal",
    });

    return (
      <HighlightedCard
        {...baseProps}
        className={classes}
        $palette={palette}
        $highlightWidth={highlightWidth}
        $borderRadius={borderRadius}
      />
    );
  }

  return <StyledCard {...baseProps} ref={ref} $borderRadius={borderRadius} />;
});

export default Card;
