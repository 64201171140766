import { CSSProperties, ReactNode } from "react";
import { Checkbox as MuiCheckbox } from "@material-ui/core";
import classNames from "classnames";
import styled from "styled-components";
import * as Colors from "src/colors";

type Props = {
  checked?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  disableRipple?: boolean;
  name?: string;
  style?: CSSProperties;
  variant?: "outlined" | "default";
  onChange?: (event: any) => void;
};

const StyledCheckbox = styled(MuiCheckbox)`
  &&& {
    &.disabled {
      border-radius: 0px;
      cursor: default;

      .MuiIconButton-label {
        border: 1px solid ${Colors.neutral60};
      }
    }
  }
`;

export default function Checkbox(props: Props) {
  const { children, disabled, ...baseProps } = props;

  const classes = classNames({
    disabled,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      // Only call the onChange function if the checkbox is not disabled
      if (props.onChange) {
        props.onChange(event);
      }
    }
  };

  return (
    <StyledCheckbox
      {...baseProps}
      className={classes}
      label={children}
      onChange={handleChange}
    />
  );
}
