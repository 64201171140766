import { CSSProperties, ReactNode } from "react";
import { Chip as MuiChip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { setLightness } from "polished";
import classNames from "classnames";
import styled from "styled-components";
import * as Colors from "src/colors";

type Props = {
  avatar?: ReactNode;
  children: ReactNode;
  color?: Colors.MainColors | Colors.MessageColors | Colors.GrayColors;
  disabled?: boolean;
  size?: "medium" | "small";
  style?: CSSProperties;
  variant?: "outlined" | "default";
};

const StyledChip = styled(MuiChip)`
  color: ${(p) => p.$palette.dark};
  background: ${(p) => setLightness(0.85, p.$palette.main)};

  &.outlined {
    color: ${(p) => p.$palette.main};
    border-color: ${(p) => p.$palette.main};

    .MuiChip-avatar {
      color: ${(p) => p.$palette.main};
    }
  }

  &.disabled {
    background: #e0e0e0;
    color: #999;
  }
`;

export default function Chip(props: Props) {
  const { children, color, disabled, ...baseProps } = props;
  const theme: any = useTheme();
  const palette = theme.palette[color] || theme.palette.neutral;

  const classes = classNames({
    disabled,
    outlined: props.variant === "outlined",
  });

  return (
    <StyledChip
      {...baseProps}
      className={classes}
      label={children}
      $palette={palette}
    />
  );
}
