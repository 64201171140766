import { keyframes } from "styled-components";

export const hoverMenuFadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1.0;
  }
`;

export const hoverBackgroundWipe = keyframes`
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
`;
