export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="355px"
    height="100px"
    viewBox="0 0 355 100"
  >
    <path
      fill="#76B145"
      d="M48.8,66.7c-4.8,0-9.3-1.9-12.7-5.3c-3.4-3.4-5.3-7.9-5.3-12.7s1.9-9.3,5.3-12.7l0,0c7-7,18.4-7,25.4,0
      c3.4,3.4,5.3,7.9,5.3,12.7s-1.9,9.3-5.3,12.7C58.1,64.8,53.6,66.7,48.8,66.7z M39.6,39.6c-2.4,2.4-3.8,5.7-3.8,9.2
      c0,3.5,1.3,6.7,3.8,9.2s5.7,3.8,9.2,3.8c3.5,0,6.7-1.3,9.2-3.8c2.4-2.4,3.8-5.7,3.8-9.2c0-3.5-1.3-6.7-3.8-9.2
      C52.9,34.6,44.7,34.6,39.6,39.6L39.6,39.6z"
    />
    <path
      fill="#76B145"
      d="M48.8,82.1c-8.5,0-17.1-3.3-23.6-9.8c-6.3-6.3-9.8-14.7-9.8-23.6s3.5-17.3,9.8-23.6
      c8.6-8.6,21.3-11.9,33-8.4c1.3,0.4,2.1,1.8,1.7,3.1s-1.8,2.1-3.1,1.7c-10-2.9-20.7-0.2-28.1,7.2c-5.4,5.4-8.3,12.5-8.3,20.1
      c0,7.6,3,14.7,8.3,20.1c11.1,11.1,29.1,11.1,40.2,0c7.2-7.2,10-17.9,7.3-27.7c-0.4-1.3,0.4-2.7,1.7-3.1c1.3-0.4,2.7,0.4,3.1,1.7
      c3.2,11.6,0,24.1-8.5,32.6C65.9,78.9,57.3,82.1,48.8,82.1z"
    />
    <path
      fill="#76B145"
      d="M48.9,97.6c-2,0-4-0.1-6-0.4c-1.4-0.2-2.3-1.4-2.2-2.8c0.2-1.4,1.4-2.3,2.8-2.2
      c13.5,1.6,26.7-2.9,36.3-12.5c8.3-8.3,12.8-19.3,12.8-31s-4.6-22.7-12.8-31c-17.1-17.1-44.9-17.1-62,0C8.5,27.1,3.9,40.1,5.2,53.2
      c0.1,1.4-0.9,2.6-2.2,2.7c-1.4,0.1-2.6-0.9-2.7-2.2c-1.5-14.7,3.6-29,14-39.4c19-19,50-19,69,0c9.2,9.2,14.3,21.5,14.3,34.5
      s-5.1,25.3-14.3,34.5C74.1,92.5,61.8,97.6,48.9,97.6z"
    />
    <path fill="#292E37" d="M116.5,68.5V15.3h9.6v53.2H116.5z" />
    <path
      fill="#292E37"
      d="M151.8,69.8c-11.6,0-21.3-9.6-21.3-21.2c0-11.7,9.5-21.3,21.3-21.3c11.8,0,21.1,9.5,21.1,21.2
      C172.9,60.5,163.8,69.8,151.8,69.8z M151.8,36C145,36,140,42.1,140,48.6c0,6.7,4.8,12.4,11.7,12.4c6.8,0,11.6-5.8,11.6-12.4
      C163.3,42.3,158.5,36,151.8,36z"
    />
    <path
      fill="#292E37"
      d="M196.1,69.8c-11.6,0-21.3-9.6-21.3-21.2c0-11.7,9.5-21.3,21.3-21.3c11.8,0,21.1,9.5,21.1,21.2
      C217.2,60.5,208.2,69.8,196.1,69.8z M196.1,36c-6.8,0-11.7,6.1-11.7,12.6c0,6.7,4.8,12.4,11.7,12.4c6.8,0,11.6-5.8,11.6-12.4
      C207.7,42.3,202.9,36,196.1,36z"
    />
    <path
      fill="#292E37"
      d="M242.3,69.8c-5.3,0-8.6-1.5-12.3-5.1v17.1h-9.6V28.6h8.8v4.7h0.1c3.3-4.2,7.7-6,12.9-6
      c11.7,0,20,9.8,20,21.1C262.3,59.7,254,69.8,242.3,69.8z M241.2,36c-6.9,0-11.8,5.8-11.8,12.5c0,5,2.4,9.3,7,11.4
      c1.4,0.7,3,1.1,4.7,1.1c6.8,0,11.6-6,11.6-12.5C252.7,42.1,248,36,241.2,36z"
    />
    <path
      fill="#292E37"
      d="M296.3,63.1h-0.1c-2.3,4.5-7.5,6.3-12.2,6.3c-4.7,0-9.5-1.5-12.6-5.1c-3.4-3.6-4.2-8.9-4.2-13.7V29h6v21.6
      c0,7.7,2.6,13.4,11.1,13.4c7.9,0,11.6-5.9,11.6-14.1V29h6v39.5h-5.5V63.1z"
    />
    <path
      fill="#292E37"
      d="M328.7,69.4c-5.8,0-11.6-2.4-14.8-7.4h-0.1v20h-6V29h5.7v6.6h0.1c3.5-5,8.9-7.6,14.9-7.6
      c11.9,0,20.9,9.1,20.9,20.9C349.4,60.5,340.4,69.4,328.7,69.4z M328.3,33.4c-8.4,0-14.7,7-14.7,15.3c0,8.3,6.3,15.3,14.8,15.3
      c8.7,0,15-6.6,15-15.2C343.4,40.3,337,33.4,328.3,33.4z"
    />
  </svg>
);
