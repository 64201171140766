import { forwardRef, ReactNode } from "react";
import styled from "styled-components";

type Props = {
  children?: ReactNode;
  direction?: "row" | "row-reverse";
  end?: "end" | "start";
};

const MuiCardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;

  & > :not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const CardActions = forwardRef((props: Props, ref) => {
  const { children, direction, end } = props;
  const style = {};

  if (direction) {
    style["flexDirection"] = direction;
  }

  if (end) {
    style["justifyContent"] = `flex-${end}`;
  }

  return (
    <MuiCardActions
      className="MuiCardActions-root MuiCardActions-spacing"
      style={((direction || end) && style) || undefined}
      ref={ref}
    >
      {children}
    </MuiCardActions>
  );
});

export default CardActions;
