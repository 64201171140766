import { useEffect } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { AuthProvider, useAuth } from "@redwoodjs/auth";
import { RedwoodApolloProvider } from "@redwoodjs/web/apollo";
import { RedwoodProvider } from "@redwoodjs/web";
import { Helmet } from "react-helmet";
import { getConfig } from "lib/web/config";
import { ThemeProvider } from "src/components/material-ui";
import { createClient } from "src/auth/hecate";
import Routes from "src/Routes";
import Theme from "src/theme";
import ErrorBoundary from "src/ErrorBoundary";
import { Provider as LocaleProvider } from "src/i18n";
import {
  init as datadogInit,
  identify as datadogIdentify,
} from "src/utils/datadog";
import ReauthenticateLayout from "src/layouts/ReauthenticateLayout";
import { PageNameProvider } from "src/components/PageNameContext";
import "./index.scss";
import packageInfo from "../../package.json";
import useMixpanelSetup from "./utils/useMixpanelSetup";

export const ListenToIdentity = ({ children }) => {
  const { currentUser } = useAuth();

  useEffect(() => {
    datadogIdentify(currentUser);
  }, [currentUser?.id]);

  return children;
};

const App = () => {
  const config = getConfig();
  useMixpanelSetup();

  useEffect(() => {
    // Datadog initialization
    datadogInit(config);

    // Mixpanel initialization
  }, []);

  return (
    <>
      <Helmet>
        <meta name="version" content={packageInfo.version} />
        <meta name="commit" content={process.env.COMMIT_ID} />
      </Helmet>
      <ThemeProvider theme={Theme}>
        <LocaleProvider>
          <ErrorBoundary>
            <RedwoodProvider>
              <PageNameProvider>
                <ReauthenticateLayout>
                  <AuthProvider client={createClient()} type="custom">
                    <ListenToIdentity>
                      <RedwoodApolloProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <Routes />
                        </MuiPickersUtilsProvider>
                      </RedwoodApolloProvider>
                    </ListenToIdentity>
                  </AuthProvider>
                </ReauthenticateLayout>
              </PageNameProvider>
            </RedwoodProvider>
          </ErrorBoundary>
        </LocaleProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
