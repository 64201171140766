var AllocationStatus;
(function(AllocationStatus2) {
  AllocationStatus2[AllocationStatus2["Allocated"] = 1] = "Allocated";
  AllocationStatus2[AllocationStatus2["NotApplicable"] = 2] = "NotApplicable";
  AllocationStatus2[AllocationStatus2["Unallocated"] = 3] = "Unallocated";
  AllocationStatus2[AllocationStatus2["Unknown"] = 4] = "Unknown";
  AllocationStatus2[AllocationStatus2["Reserved"] = 5] = "Reserved";
  AllocationStatus2[AllocationStatus2["Pending"] = 6] = "Pending";
})(AllocationStatus || (AllocationStatus = {}));
var GatewayAllocationStatus;
(function(GatewayAllocationStatus2) {
  GatewayAllocationStatus2[GatewayAllocationStatus2["Invalid"] = 0] = "Invalid";
  GatewayAllocationStatus2[GatewayAllocationStatus2["Assigned"] = 1] = "Assigned";
  GatewayAllocationStatus2[GatewayAllocationStatus2["Unassigned"] = 2] = "Unassigned";
  GatewayAllocationStatus2[GatewayAllocationStatus2["NotApplicable"] = 3] = "NotApplicable";
  GatewayAllocationStatus2[GatewayAllocationStatus2["Pending"] = 4] = "Pending";
  GatewayAllocationStatus2[GatewayAllocationStatus2["Reserved"] = 5] = "Reserved";
})(GatewayAllocationStatus || (GatewayAllocationStatus = {}));
var Carrier;
(function(Carrier2) {
  Carrier2[Carrier2["Colt"] = 1] = "Colt";
  Carrier2[Carrier2["Daisy"] = 2] = "Daisy";
  Carrier2[Carrier2["Gamma"] = 3] = "Gamma";
  Carrier2[Carrier2["Pccw"] = 5] = "Pccw";
  Carrier2[Carrier2["PureIp"] = 6] = "PureIp";
  Carrier2[Carrier2["Symbio"] = 7] = "Symbio";
  Carrier2[Carrier2["Unknown"] = 8] = "Unknown";
  Carrier2[Carrier2["VoipUnlimited"] = 9] = "VoipUnlimited";
  Carrier2[Carrier2["Bandwidth"] = 10] = "Bandwidth";
  Carrier2[Carrier2["Bics"] = 11] = "Bics";
  Carrier2[Carrier2["ChinaMobile"] = 12] = "ChinaMobile";
  Carrier2[Carrier2["Orange"] = 13] = "Orange";
  Carrier2[Carrier2["Peerless"] = 14] = "Peerless";
  Carrier2[Carrier2["Tata"] = 15] = "Tata";
  Carrier2[Carrier2["Telephonica"] = 16] = "Telephonica";
  Carrier2[Carrier2["Voxbone"] = 17] = "Voxbone";
  Carrier2[Carrier2["Vts"] = 18] = "Vts";
  Carrier2[Carrier2["GammaInbound"] = 19] = "GammaInbound";
  Carrier2[Carrier2["Lumen"] = 20] = "Lumen";
  Carrier2[Carrier2["M800"] = 21] = "M800";
  Carrier2[Carrier2["IctGlobe"] = 22] = "IctGlobe";
  Carrier2[Carrier2["Roitel"] = 23] = "Roitel";
  Carrier2[Carrier2["NotOurCarrier"] = 24] = "NotOurCarrier";
  Carrier2[Carrier2["Digicel"] = 25] = "Digicel";
  Carrier2[Carrier2["Avoxi"] = 26] = "Avoxi";
  Carrier2[Carrier2["InfoBip"] = 27] = "InfoBip";
  Carrier2[Carrier2["Cirion"] = 28] = "Cirion";
})(Carrier || (Carrier = {}));
var HostType;
(function(HostType2) {
  HostType2[HostType2["Hosted"] = 1] = "Hosted";
  HostType2[HostType2["OnPrem"] = 2] = "OnPrem";
  HostType2[HostType2["Unknown"] = 3] = "Unknown";
  HostType2[HostType2["SipPgi"] = 4] = "SipPgi";
  HostType2[HostType2["TeamsOC"] = 5] = "TeamsOC";
  HostType2[HostType2["LMZ"] = 6] = "LMZ";
  HostType2[HostType2["SIP"] = 7] = "SIP";
  HostType2[HostType2["ForwardOnly"] = 8] = "ForwardOnly";
  HostType2[HostType2["None"] = 9] = "None";
})(HostType || (HostType = {}));
var NumberStatus;
(function(NumberStatus2) {
  NumberStatus2[NumberStatus2["Active"] = 1] = "Active";
  NumberStatus2[NumberStatus2["Deleted"] = 2] = "Deleted";
  NumberStatus2[NumberStatus2["Inactive"] = 3] = "Inactive";
  NumberStatus2[NumberStatus2["Ceased"] = 4] = "Ceased";
  NumberStatus2[NumberStatus2["PendingPortingAway"] = 5] = "PendingPortingAway";
  NumberStatus2[NumberStatus2["PendingPortingIn"] = 6] = "PendingPortingIn";
  NumberStatus2[NumberStatus2["Unknown"] = 7] = "Unknown";
})(NumberStatus || (NumberStatus = {}));
var NumberType;
(function(NumberType2) {
  NumberType2[NumberType2["Geographic"] = 1] = "Geographic";
  NumberType2[NumberType2["NonGeographic"] = 3] = "NonGeographic";
})(NumberType || (NumberType = {}));
var OculeusSyncStatus;
(function(OculeusSyncStatus2) {
  OculeusSyncStatus2[OculeusSyncStatus2["Added"] = 1] = "Added";
  OculeusSyncStatus2[OculeusSyncStatus2["Deleted"] = 2] = "Deleted";
  OculeusSyncStatus2[OculeusSyncStatus2["FailedtoAdd"] = 3] = "FailedtoAdd";
  OculeusSyncStatus2[OculeusSyncStatus2["FailedtoDelete"] = 4] = "FailedtoDelete";
  OculeusSyncStatus2[OculeusSyncStatus2["FailedtoUpdate"] = 5] = "FailedtoUpdate";
  OculeusSyncStatus2[OculeusSyncStatus2["NotApplicable"] = 6] = "NotApplicable";
  OculeusSyncStatus2[OculeusSyncStatus2["Pending"] = 7] = "Pending";
  OculeusSyncStatus2[OculeusSyncStatus2["Updated"] = 8] = "Updated";
  OculeusSyncStatus2[OculeusSyncStatus2["Invalid"] = 9] = "Invalid";
})(OculeusSyncStatus || (OculeusSyncStatus = {}));
var RibbonSyncStatus;
(function(RibbonSyncStatus2) {
  RibbonSyncStatus2[RibbonSyncStatus2["NotSynced"] = 1] = "NotSynced";
  RibbonSyncStatus2[RibbonSyncStatus2["Failed"] = 2] = "Failed";
  RibbonSyncStatus2[RibbonSyncStatus2["Partial"] = 3] = "Partial";
  RibbonSyncStatus2[RibbonSyncStatus2["SyncCompleted"] = 4] = "SyncCompleted";
  RibbonSyncStatus2[RibbonSyncStatus2["Invalid"] = 5] = "Invalid";
})(RibbonSyncStatus || (RibbonSyncStatus = {}));
var UserType;
(function(UserType2) {
  UserType2[UserType2["AutoAttendant"] = 1] = "AutoAttendant";
  UserType2[UserType2["CallQueues"] = 2] = "CallQueues";
  UserType2[UserType2["CommonAreaPhone"] = 3] = "CommonAreaPhone";
  UserType2[UserType2["MeetingRoom"] = 4] = "MeetingRoom";
  UserType2[UserType2["NotApplicable"] = 5] = "NotApplicable";
  UserType2[UserType2["Unknown"] = 6] = "Unknown";
  UserType2[UserType2["User"] = 7] = "User";
  UserType2[UserType2["Invalid"] = 8] = "Invalid";
})(UserType || (UserType = {}));
var UsageType;
(function(UsageType2) {
  UsageType2[UsageType2["FirstPartyAppAssignment"] = 1] = "FirstPartyAppAssignment";
  UsageType2[UsageType2["CallingUserAssignment"] = 2] = "CallingUserAssignment";
  UsageType2[UsageType2["FirstPartyAppAssignmentAndCallingUserAssignment"] = 3] = "FirstPartyAppAssignmentAndCallingUserAssignment";
  UsageType2[UsageType2["Invalid"] = 4] = "Invalid";
})(UsageType || (UsageType = {}));
var RoutingEngineSyncStatus;
(function(RoutingEngineSyncStatus2) {
  RoutingEngineSyncStatus2[RoutingEngineSyncStatus2["Pending"] = 1] = "Pending";
  RoutingEngineSyncStatus2[RoutingEngineSyncStatus2["SyncCompleted"] = 2] = "SyncCompleted";
})(RoutingEngineSyncStatus || (RoutingEngineSyncStatus = {}));
var CountryDialingCode;
(function(CountryDialingCode2) {
  CountryDialingCode2["NANP"] = "1";
  CountryDialingCode2["ABW"] = "297";
  CountryDialingCode2["AFG"] = "93";
  CountryDialingCode2["AGO"] = "244";
  CountryDialingCode2["AIA"] = "1264";
  CountryDialingCode2["ALA"] = "35818";
  CountryDialingCode2["ALB"] = "355";
  CountryDialingCode2["AND"] = "376";
  CountryDialingCode2["ARE"] = "971";
  CountryDialingCode2["ARG"] = "54";
  CountryDialingCode2["ARM"] = "374";
  CountryDialingCode2["ASM"] = "1684";
  CountryDialingCode2["ATG"] = "1268";
  CountryDialingCode2["AUS"] = "61";
  CountryDialingCode2["AUT"] = "43";
  CountryDialingCode2["AZE"] = "994";
  CountryDialingCode2["BDI"] = "257";
  CountryDialingCode2["BEL"] = "32";
  CountryDialingCode2["BEN"] = "229";
  CountryDialingCode2["BES"] = "599";
  CountryDialingCode2["BFA"] = "226";
  CountryDialingCode2["BGD"] = "880";
  CountryDialingCode2["BGR"] = "359";
  CountryDialingCode2["BHR"] = "973";
  CountryDialingCode2["BHS"] = "1242";
  CountryDialingCode2["BIH"] = "387";
  CountryDialingCode2["BLM"] = "590";
  CountryDialingCode2["BLR"] = "375";
  CountryDialingCode2["BLZ"] = "501";
  CountryDialingCode2["BMU"] = "1441";
  CountryDialingCode2["BOL"] = "591";
  CountryDialingCode2["BRA"] = "55";
  CountryDialingCode2["BRB"] = "1246";
  CountryDialingCode2["BRN"] = "673";
  CountryDialingCode2["BTN"] = "975";
  CountryDialingCode2["BWA"] = "267";
  CountryDialingCode2["CAF"] = "236";
  CountryDialingCode2["CAN"] = "1";
  CountryDialingCode2["CCK"] = "61";
  CountryDialingCode2["CHE"] = "41";
  CountryDialingCode2["CHL"] = "56";
  CountryDialingCode2["CHN"] = "86";
  CountryDialingCode2["CIV"] = "225";
  CountryDialingCode2["CMR"] = "237";
  CountryDialingCode2["COD"] = "243";
  CountryDialingCode2["COG"] = "242";
  CountryDialingCode2["COK"] = "682";
  CountryDialingCode2["COL"] = "57";
  CountryDialingCode2["COM"] = "269";
  CountryDialingCode2["CPV"] = "238";
  CountryDialingCode2["CRI"] = "506";
  CountryDialingCode2["CUB"] = "53";
  CountryDialingCode2["CUW"] = "599";
  CountryDialingCode2["CXR"] = "61";
  CountryDialingCode2["CYM"] = "1345";
  CountryDialingCode2["CYP"] = "357";
  CountryDialingCode2["CZE"] = "420";
  CountryDialingCode2["DEU"] = "49";
  CountryDialingCode2["DJI"] = "253";
  CountryDialingCode2["DMA"] = "1767";
  CountryDialingCode2["DNK"] = "45";
  CountryDialingCode2["DOM"] = "1";
  CountryDialingCode2["DZA"] = "213";
  CountryDialingCode2["ECU"] = "593";
  CountryDialingCode2["EGY"] = "20";
  CountryDialingCode2["ERI"] = "291";
  CountryDialingCode2["ESH"] = "212";
  CountryDialingCode2["ESP"] = "34";
  CountryDialingCode2["EST"] = "372";
  CountryDialingCode2["ETH"] = "251";
  CountryDialingCode2["FIN"] = "358";
  CountryDialingCode2["FJI"] = "679";
  CountryDialingCode2["FLK"] = "500";
  CountryDialingCode2["FRA"] = "33";
  CountryDialingCode2["FRO"] = "298";
  CountryDialingCode2["FSM"] = "691";
  CountryDialingCode2["GAB"] = "241";
  CountryDialingCode2["GBR"] = "44";
  CountryDialingCode2["GEO"] = "995";
  CountryDialingCode2["GGY"] = "441481";
  CountryDialingCode2["GHA"] = "233";
  CountryDialingCode2["GIB"] = "350";
  CountryDialingCode2["GIN"] = "224";
  CountryDialingCode2["GLP"] = "590";
  CountryDialingCode2["GMB"] = "220";
  CountryDialingCode2["GNB"] = "245";
  CountryDialingCode2["GNQ"] = "240";
  CountryDialingCode2["GRC"] = "30";
  CountryDialingCode2["GRD"] = "1473";
  CountryDialingCode2["GRL"] = "299";
  CountryDialingCode2["GTM"] = "502";
  CountryDialingCode2["GUF"] = "594";
  CountryDialingCode2["GUM"] = "1671";
  CountryDialingCode2["GUY"] = "592";
  CountryDialingCode2["HKG"] = "852";
  CountryDialingCode2["HND"] = "504";
  CountryDialingCode2["HRV"] = "385";
  CountryDialingCode2["HTI"] = "509";
  CountryDialingCode2["HUN"] = "36";
  CountryDialingCode2["IDN"] = "62";
  CountryDialingCode2["IMN"] = "441624";
  CountryDialingCode2["IND"] = "91";
  CountryDialingCode2["IOT"] = "246";
  CountryDialingCode2["IRL"] = "353";
  CountryDialingCode2["IRN"] = "98";
  CountryDialingCode2["IRQ"] = "964";
  CountryDialingCode2["ISL"] = "354";
  CountryDialingCode2["ISR"] = "972";
  CountryDialingCode2["ITA"] = "39";
  CountryDialingCode2["JAM"] = "1876";
  CountryDialingCode2["JEY"] = "441534";
  CountryDialingCode2["JOR"] = "962";
  CountryDialingCode2["JPN"] = "81";
  CountryDialingCode2["KAZ"] = "7";
  CountryDialingCode2["KEN"] = "254";
  CountryDialingCode2["KGZ"] = "996";
  CountryDialingCode2["KHM"] = "855";
  CountryDialingCode2["KIR"] = "686";
  CountryDialingCode2["KNA"] = "1869";
  CountryDialingCode2["KOR"] = "82";
  CountryDialingCode2["KWT"] = "965";
  CountryDialingCode2["LAO"] = "856";
  CountryDialingCode2["LBN"] = "961";
  CountryDialingCode2["LBR"] = "231";
  CountryDialingCode2["LBY"] = "218";
  CountryDialingCode2["LCA"] = "1758";
  CountryDialingCode2["LIE"] = "423";
  CountryDialingCode2["LKA"] = "94";
  CountryDialingCode2["LSO"] = "266";
  CountryDialingCode2["LTU"] = "370";
  CountryDialingCode2["LUX"] = "352";
  CountryDialingCode2["LVA"] = "371";
  CountryDialingCode2["MAC"] = "853";
  CountryDialingCode2["MAF"] = "590";
  CountryDialingCode2["MAR"] = "212";
  CountryDialingCode2["MCO"] = "377";
  CountryDialingCode2["MDA"] = "373";
  CountryDialingCode2["MDG"] = "261";
  CountryDialingCode2["MDV"] = "960";
  CountryDialingCode2["MEX"] = "52";
  CountryDialingCode2["MHL"] = "692";
  CountryDialingCode2["MKD"] = "389";
  CountryDialingCode2["MLI"] = "223";
  CountryDialingCode2["MLT"] = "356";
  CountryDialingCode2["MMR"] = "95";
  CountryDialingCode2["MNE"] = "382";
  CountryDialingCode2["MNG"] = "976";
  CountryDialingCode2["MNP"] = "1670";
  CountryDialingCode2["MOZ"] = "258";
  CountryDialingCode2["MRT"] = "222";
  CountryDialingCode2["MSR"] = "1664";
  CountryDialingCode2["MTQ"] = "596";
  CountryDialingCode2["MUS"] = "230";
  CountryDialingCode2["MWI"] = "265";
  CountryDialingCode2["MYS"] = "60";
  CountryDialingCode2["MYT"] = "262";
  CountryDialingCode2["NAM"] = "264";
  CountryDialingCode2["NCL"] = "687";
  CountryDialingCode2["NER"] = "227";
  CountryDialingCode2["NFK"] = "672";
  CountryDialingCode2["NGA"] = "234";
  CountryDialingCode2["NIC"] = "505";
  CountryDialingCode2["NIU"] = "683";
  CountryDialingCode2["NLD"] = "31";
  CountryDialingCode2["NOR"] = "47";
  CountryDialingCode2["NPL"] = "977";
  CountryDialingCode2["NRU"] = "674";
  CountryDialingCode2["NZL"] = "64";
  CountryDialingCode2["OMN"] = "968";
  CountryDialingCode2["PAK"] = "92";
  CountryDialingCode2["PAN"] = "507";
  CountryDialingCode2["PCN"] = "870";
  CountryDialingCode2["PER"] = "51";
  CountryDialingCode2["PHL"] = "63";
  CountryDialingCode2["PLW"] = "680";
  CountryDialingCode2["PNG"] = "675";
  CountryDialingCode2["POL"] = "48";
  CountryDialingCode2["PRI"] = "1";
  CountryDialingCode2["PRK"] = "850";
  CountryDialingCode2["PRT"] = "351";
  CountryDialingCode2["PRY"] = "595";
  CountryDialingCode2["PSE"] = "970";
  CountryDialingCode2["PYF"] = "689";
  CountryDialingCode2["QAT"] = "974";
  CountryDialingCode2["REU"] = "262";
  CountryDialingCode2["ROU"] = "40";
  CountryDialingCode2["RUS"] = "7";
  CountryDialingCode2["RWA"] = "250";
  CountryDialingCode2["SAU"] = "966";
  CountryDialingCode2["SDN"] = "249";
  CountryDialingCode2["SEN"] = "221";
  CountryDialingCode2["SGP"] = "65";
  CountryDialingCode2["SHN"] = "290";
  CountryDialingCode2["SJM"] = "47";
  CountryDialingCode2["SLB"] = "677";
  CountryDialingCode2["SLE"] = "232";
  CountryDialingCode2["SLV"] = "503";
  CountryDialingCode2["SMR"] = "378";
  CountryDialingCode2["SOM"] = "252";
  CountryDialingCode2["SPM"] = "508";
  CountryDialingCode2["SRB"] = "381";
  CountryDialingCode2["SSD"] = "211";
  CountryDialingCode2["STP"] = "239";
  CountryDialingCode2["SUR"] = "597";
  CountryDialingCode2["SVK"] = "421";
  CountryDialingCode2["SVN"] = "386";
  CountryDialingCode2["SWE"] = "46";
  CountryDialingCode2["SWZ"] = "268";
  CountryDialingCode2["SXM"] = "721";
  CountryDialingCode2["SYC"] = "248";
  CountryDialingCode2["SYR"] = "963";
  CountryDialingCode2["TCA"] = "1649";
  CountryDialingCode2["TCD"] = "235";
  CountryDialingCode2["TGO"] = "228";
  CountryDialingCode2["THA"] = "66";
  CountryDialingCode2["TJK"] = "992";
  CountryDialingCode2["TKL"] = "690";
  CountryDialingCode2["TKM"] = "993";
  CountryDialingCode2["TLS"] = "670";
  CountryDialingCode2["TON"] = "676";
  CountryDialingCode2["TTO"] = "1868";
  CountryDialingCode2["TUN"] = "216";
  CountryDialingCode2["TUR"] = "90";
  CountryDialingCode2["TUV"] = "688";
  CountryDialingCode2["TWN"] = "886";
  CountryDialingCode2["TZA"] = "255";
  CountryDialingCode2["UGA"] = "256";
  CountryDialingCode2["UKR"] = "380";
  CountryDialingCode2["UMI"] = "1";
  CountryDialingCode2["URY"] = "598";
  CountryDialingCode2["USA"] = "1";
  CountryDialingCode2["UZB"] = "998";
  CountryDialingCode2["VAT"] = "379";
  CountryDialingCode2["VCT"] = "1784";
  CountryDialingCode2["VEN"] = "58";
  CountryDialingCode2["VGB"] = "1284";
  CountryDialingCode2["VIR"] = "1340";
  CountryDialingCode2["VNM"] = "84";
  CountryDialingCode2["VUT"] = "678";
  CountryDialingCode2["WLF"] = "681";
  CountryDialingCode2["WSM"] = "685";
  CountryDialingCode2["XKX"] = "383";
  CountryDialingCode2["YEM"] = "967";
  CountryDialingCode2["ZAF"] = "27";
  CountryDialingCode2["ZMB"] = "260";
  CountryDialingCode2["ZWE"] = "263";
})(CountryDialingCode || (CountryDialingCode = {}));
export {
  AllocationStatus,
  Carrier,
  CountryDialingCode,
  GatewayAllocationStatus,
  HostType,
  NumberStatus,
  NumberType,
  OculeusSyncStatus,
  RibbonSyncStatus,
  RoutingEngineSyncStatus,
  UsageType,
  UserType
};
