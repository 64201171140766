// src/hooks/useMixpanelSetup.js
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { useAuth } from "@redwoodjs/auth";
import { getConfig } from "lib/web/config";

const extractUserProperties = (currentUser) => {
  return {
    "First Name": currentUser.firstName,
    "Last Name": currentUser.lastName,
    "User Email": currentUser.email,
    "Account Name": currentUser.customer.name,
    "User Role": currentUser.roles.join(", "),
    "User Scope": currentUser.scopedRoles,
  };
};

const useMixpanelSetup = () => {
  const { currentUser } = useAuth();
  const config = getConfig();

  useEffect(() => {
    mixpanel.init(config.mixpanelApi.appToken, {
      debug: true,
      ignore_dnt: true,
    });

    if (currentUser && currentUser.id) {
      mixpanel.identify(currentUser.id);
      const userProperties = extractUserProperties(currentUser);
      mixpanel.register(userProperties);
      mixpanel.people.set(userProperties);
    }
  }, [currentUser, config]);
};

export default useMixpanelSetup;
