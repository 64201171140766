const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);
function queryablePromise(promise) {
  if (promise.isFulfilled) {
    return promise;
  }
  let isPending = true;
  let isRejected = false;
  let isFulfilled = false;
  const result = promise.then(function(v) {
    isFulfilled = true;
    isPending = false;
    return v;
  }, function(e) {
    isRejected = true;
    isPending = false;
    throw e;
  });
  result.isFulfilled = function() {
    return isFulfilled;
  };
  result.isPending = function() {
    return isPending;
  };
  result.isRejected = function() {
    return isRejected;
  };
  return result;
}
function sleep(timeout) {
  return new Promise((res) => setTimeout(res, timeout));
}
const GUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export {
  GUID_REGEX,
  capitalize,
  queryablePromise,
  sleep
};
