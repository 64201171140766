import { ComponentProps } from "react";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import * as Colors from "src/colors";

type Props = ComponentProps<typeof MuiTooltip> & {
  light?: boolean;
  width?: string;
  variant?: "default" | "internal" | "numbers-tool";
  maxWidth?: string;
};

const StyledTooltip = styled((props) => (
  <MuiTooltip classes={{ tooltip: props.className }} {...props} />
))`
  &&& {
    background-color: ${(props) => props.$palette.main};
    border-radius: 0.4rem;
    opacity: 1;
    padding: 0.5rem;
    width: ${(props) => props.$width && `${props.$width}rem`};
    height: 100%;
    &.MuiTooltip-tooltipPlacementTop {
      top: 10px;
    }
  }

  .MuiTooltip-arrow {
    color: ${(props) => props.$palette.main};
  }
`;

// It doesn't seem to be possible to override the tooltips using styled components due to how the
// tooltip element is created as a separate element. If you try to style with styled-components
// it just ends up also applying to the main element.
const InternalTooltip = withStyles({
  tooltip: {
    background: Colors.neutral10,
    fontSize: "0.875rem",
  },
  arrow: {
    color: Colors.neutral10,
  },
})(MuiTooltip);

const NumbersToolTooltip = withStyles({
  tooltip: {
    background: Colors.secondaryColor,
    maxWidth: (props) => props.$maxwidth && `${props.$maxwidth}`,
  },
  arrow: {
    color: Colors.secondaryColor,
  },
})(MuiTooltip);

export default function Tooltip(props: Props) {
  const { light, width, variant, maxWidth, ...baseProps } = props;
  const theme: any = useTheme();
  const palette = theme.palette.primary;

  switch (variant) {
    case "internal":
      return <InternalTooltip placement="top" arrow {...baseProps} />;
    case "numbers-tool":
      return (
        <NumbersToolTooltip
          placement="top"
          arrow
          {...baseProps}
          $maxwidth={maxWidth}
        />
      );
    default:
      return (
        <StyledTooltip
          {...baseProps}
          $light={light}
          $palette={palette}
          $width={width}
        />
      );
  }
}
