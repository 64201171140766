import { useEffectOnce } from "usehooks-ts";
import { useAuth } from "@redwoodjs/auth";
import * as NumbersCache from "src/utils/contexts/numbersCache";
import * as SitesCache from "src/utils/contexts/sitesCache";
import * as ServiceUserCache from "src/utils/contexts/serviceUserCache";
import * as GroupsCache from "src/utils/contexts/groupsCache";
import "src/utils/contexts/relations";
import { Roles } from "src/utils/roles";

const StartNumbers = () => {
  const { init } = NumbersCache.useNumbersInfo();

  useEffectOnce(() => init());

  return null;
};

const StartSites = () => {
  const { init } = SitesCache.useSitesInfo();

  useEffectOnce(() => init());

  return null;
};

const StartServiceUsers = () => {
  const { init } = ServiceUserCache.useServiceUsersInfo();

  useEffectOnce(() => init());

  return null;
};

const StartGroups = () => {
  const { init } = GroupsCache.useGroupsInfo();

  useEffectOnce(() => init());

  return null;
};

const ThemisContextLayout = ({ children }) => {
  const { hasRole } = useAuth();

  return (
    <GroupsCache.Provider>
      <SitesCache.Provider>
        <ServiceUserCache.Provider>
          <NumbersCache.Provider>
            {hasRole([
              Roles.GlobalAdmin,
              Roles.TelephonyAdmin,
              Roles.TelephonyAdminRead,
            ]) && (
              <>
                <StartNumbers />
                <StartSites />
                <StartServiceUsers />
                <StartGroups />
              </>
            )}

            {children}
          </NumbersCache.Provider>
        </ServiceUserCache.Provider>
      </SitesCache.Provider>
    </GroupsCache.Provider>
  );
};

export default ThemisContextLayout;
