import fetch from "cross-fetch";
import qs from "qs";

export type HttpVerb = "GET" | "POST" | "PATCH" | "DELETE";

/** Makes a HTTP request */
export async function numbersFetch(
  method: HttpVerb,
  path: `/${string}`,
  queryParams = {},
  bodyJson = null
) {
  let query = qs.stringify({ ...queryParams, path });
  query = query && `?${query}`;

  const res = await fetch(`/api/numbersProxy${query}`, {
    method,
    headers: {
      Accept: "*/*",
      "Content-Type": bodyJson ? "application/json" : undefined,
    },
    body: bodyJson ? JSON.stringify(bodyJson) : undefined,
  });

  let isJson = false;

  res.headers.forEach((value, key) => {
    if (key === "content-type" && value.includes("application/json")) {
      isJson = true;
    }
  });

  return {
    status: res.status,
    body: isJson ? await res.json() : await res.text(),
  };
}
