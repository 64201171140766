import { CSSProperties, forwardRef, ReactNode } from "react";
import classNames from "classnames";
import { useTheme } from "@material-ui/core/styles";
import {
  MainColors,
  MessageColors,
  TextGrayColors,
  TextMainColors,
  TextMessageColors,
} from "src/colors";

export type TypographyColors =
  | MainColors
  | MessageColors
  | TextMainColors
  | TextMessageColors
  | TextGrayColors
  | "default"
  | "inherit";

export type TypographyVariants =
  | "display1"
  | "display2"
  | "display3"
  | "body1"
  | "body2"
  | "body3"
  | "button";

export type TypographyComponents =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "div"
  | "p"
  | "span";

export type Props = {
  align?: "start" | "left" | "center" | "right" | "end" | "justify";
  color?: TypographyColors;
  component?: TypographyComponents;
  children: ReactNode;
  className?: string;
  display?: string;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: 100 | 300 | 400 | 600;
  lineHeight?: number;
  style?: CSSProperties;
  textTransform?:
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "none"
    | "full-width";
  variant?: TypographyVariants;
};

export const VARIANT_MAPPING: Record<TypographyVariants, TypographyComponents> =
  {
    display1: "h1",
    display2: "h2",
    display3: "h3",
    body1: "div",
    body2: "div",
    body3: "div",
    button: "span",
  };

// We basically expose everything you could want on the typography element.
const Typography = forwardRef((props: Props, ref) => {
  const theme: any = useTheme();
  const { children, variant } = props;

  const component: TypographyComponents =
    props.component || (variant && VARIANT_MAPPING[variant]) || "span";

  const color =
    (props.color &&
      {
        textPrimary: theme.palette.text.primary,
        textSecondary: theme.palette.text.secondary,
        textDisabled: theme.palette.text.disabled,

        primary: theme.palette.primary.main,
        secondary: theme.palette.secondary.main,
        tertiary: theme.palette.tertiary.main,
        quaternary: theme.palette.quaternary.main,

        error: theme.palette.error.main,
        warning: theme.palette.warning.main,
        info: theme.palette.info.main,
        success: theme.palette.success.main,

        textError: theme.palette.error.contrastText,
        textWarning: theme.palette.warning.contrastText,
        textInfo: theme.palette.info.contrastText,
        textSuccess: theme.palette.success.contrastText,

        neutralDark: "#666",
        neutralLight: "#999",

        inherit: "inherit",
      }[props.color]) ||
    null;

  const themeStyles = (variant && theme.typography[variant]) || {};

  // Add styles as inline
  const style = Object.assign({}, props.style);

  // Color
  if (color) {
    style["color"] = color;
  }

  // Font Family
  if (props.fontFamily) {
    style["fontFamily"] = props.fontFamily;
  }

  // Add font size if it exists
  if (props.fontSize) {
    style["fontSize"] = `${props.fontSize}rem`;
  } else if (variant) {
    style["fontSize"] = themeStyles.fontSize;
  }

  // Font weight
  if (props.fontWeight) {
    style["fontWeight"] = props.fontWeight;
  } else if (variant) {
    style["fontWeight"] = themeStyles.fontWeight;
  }

  // Line height
  if (props.lineHeight) {
    style["lineHeight"] = props.lineHeight;
  } else if (variant) {
    style["lineHeight"] = themeStyles.lineHeight;
  }

  // Text align
  if (props.align) {
    style["textAlign"] = props.align;
  }

  // Text transform
  if (props.textTransform) {
    style["textTransform"] = props.textTransform;
  }

  if (props.display) {
    style["display"] = props.display;
  }

  const classes = classNames(props.className, {
    "display-1": variant === "display1",
    "display-2": variant === "display2",
    "display-3": variant === "display3",
    "body-1": variant === "body1",
    "body-2": variant === "body2",
    "body-3": variant === "body3",
  });

  return React.createElement(
    component,
    {
      className: classes,
      ref,
      style,
    },
    children
  );
});

export default Typography;
