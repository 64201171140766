import React, { useEffect, useState } from "react";
import { useAuth } from "@redwoodjs/auth";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { getConfig } from "lib/web/config";
import * as Location from "src/utils/location";
import {
  Button,
  CardContent,
  Modal,
  Divider,
  Box,
  Typography,
} from "src/components/material-ui";
import * as Colors from "src/colors";

const StyledModal = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${Colors.neutral100};
  outline: 0;
  border-radius: 0.4rem;
  text-size-adjust: 100%;

  @media (min-width: 820px) {
    width: 33rem;
  }

  button {
    text-transform: uppercase;
    margin: 0.375rem;

    @media (min-width: 1150px) {
      margin: 0.5rem;
    }
  }
`;

const StyledBox = styled(Box)`
  margin: 1.8125rem;

  @media (min-width: 1150px) {
    margin: 0.25rem;
  }
`;

const StyledTitleDiv = styled.div`
  text-align: left;
  margin: 0.18rem;
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 1.8125rem 0 0 1.375rem;
    font-size: 1.75rem;

    @media (min-width: 1150px) {
      font-size: 1.5rem;
    }
  }

  i {
    font-size: 1.375rem;
    color: ${Colors.warningColor};
    margin-top: 1.875rem;
    margin-right: 1.865625rem;
  }

  @media (min-width: 1150px) {
    margin: 0.25rem;
  }
`;

const StyledCardContent = styled(CardContent)`
  &&& {
    padding: 1.8125rem;
    padding-top: 0.75rem;
    font-size: 1.125rem;

    span {
      font-weight: 600;
      word-wrap: break-word;
    }
  }
`;

const SessionTimeoutModal = () => {
  const config = getConfig();

  const [openDialog, setOpenDialog] = useState(false);
  const { logOut } = useAuth();

  const timeOutHandler = () => {
    setOpenDialog(true);
  };

  const timeoutInMs = parseInt(config.sessionTimeout) * 1000;
  useEffect(() => {
    setTimeout(timeOutHandler, timeoutInMs);
    // props.open = true;
  }, []);

  const handleClose = (_event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
  };

  const handleContinue = () => {
    Location.reload();
  };

  return (
    <Modal open={openDialog || false} onClose={handleClose}>
      <StyledModal>
        <StyledBox>
          <StyledTitleDiv>
            <Typography variant="display1">
              <FormattedMessage id="sessionTimeoutTitle" />
            </Typography>
            <i className="icon-warning" />
          </StyledTitleDiv>
          <StyledCardContent>
            <Typography variant="body1">
              <FormattedMessage id="sessionTimeoutText" />
            </Typography>
          </StyledCardContent>
        </StyledBox>
        <Divider />
        <Box textAlign="right" pr={4}>
          <Button onClick={handleContinue}>
            <FormattedMessage id="continue" />
          </Button>
          <Button onClick={logOut}>
            <FormattedMessage id="signOut" />
          </Button>
        </Box>
      </StyledModal>
    </Modal>
  );
};

export default SessionTimeoutModal;
