import { ReactNode } from "react";
import { TextId, Message } from "src/i18n";
import Typography from "./Typography";
import type { Props as TypographyProps } from "./Typography";

type Props = Omit<TypographyProps, "children"> & {
  text: TextId;
  values?: Record<string, ReactNode>;
};

const Text = ({ text, values, ...typographyProps }: Props) => {
  return (
    <Typography {...typographyProps}>
      <Message id={text} values={values} />
    </Typography>
  );
};

export default Text;
