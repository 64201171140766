import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { LinearProgress } from "src/components/material-ui";
import { validateToken } from "src/auth/hecate";
import fetchReauthenticate from "src/utils/fetchReauthenticate";
import { usePageNameContext } from "src/components/PageNameContext";

const ReauthenticateLayout = ({ children }) => {
  const [reauthenticated, setReauthenticated] = useState(validateToken());
  const pageName = usePageNameContext();

  const loginError = React.useRef(Cookies.get("login_error")).current;

  if (loginError) {
    return children;
  }

  useEffect(() => {
    fetchReauthenticate(setReauthenticated);
  }, [pageName]);

  return reauthenticated ? children : <LinearProgress color="secondary" />;
};

export default ReauthenticateLayout;
