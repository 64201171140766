import GatewayClient from "lib/web/gateway";
import { createDataCache } from "src/utils/contexts/dataCache";
import { hasSiteWriteAccess } from "../hooks/scope";

export type Group = {
  id: string;
  name: string;
  parentGroupId: {
    value: string;
    disabled: boolean;
  };
  groupType: any;
  disabled: boolean;
};

const createHierarchy = (items) => {
  const itemMap = new Map();
  const hierarchy = [];

  for (const item of items) {
    item.children = [];
    itemMap.set(item.id, item);
  }

  for (const item of items) {
    const parentGroupId = item.parentGroupId && item.parentGroupId.value;

    if (parentGroupId && parentGroupId !== item.id) {
      const parent = itemMap.get(parentGroupId);
      if (parent) {
        parent.children.push(item);
      }
    } else {
      hierarchy.push(item);
    }
  }

  return hierarchy;
};

export async function fetchData({
  gateway,
}: {
  gateway: GatewayClient;
}): Promise<{ hasNextPage: boolean; rows: Group[] }> {
  const results = await gateway.listCustomerGroups();

  const login = await gateway.getV2Login();
  const scopedRoles = login.login?.accessControlMap;

  return {
    hasNextPage: results.paginationInfo?.hasNextPage,
    rows: createHierarchy(
      results.itemsList.map((group) => ({
        id: group.id,
        name: group.name,
        parentGroupId: {
          value: group.parentGroupId?.value,
          disabled:
            group.parentGroupId &&
            !hasSiteWriteAccess(scopedRoles, group.parentGroupId?.value),
        },
        groupType: group.groupType,
        disabled: !hasSiteWriteAccess(scopedRoles, group.id),
      }))
    ),
  };
}

const groups = createDataCache<Group>("groups", fetchData, {
  first: 15,
  all: 25,
});

export const __cache__ = groups;
export const Provider = groups.provider;

export const registerGroupsInsight = groups.registerInsight;
export const registerGroupsView = groups.registerView;
export const useGroups = groups.useData;
export const useGroupsInfo = groups.useInfo;
export const useGroupsInsight = groups.useInsight;
